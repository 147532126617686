import {create} from 'zustand'
import { usePersonal } from '../../usePersonal';
import shortid from 'shortid';



export let useReviewModal = create((set,get)=>({

display:undefined,
mode:'undefined',


showAddReviewModal:()=>{set({display:true,mode:'add'})},

showEditReviewModal:(reviewId)=>{
    let {page}=usePersonal.getState()
    let review = page.reviews.find(review=>(reviewId==review.id))
    console.log(review);
    set({review,display:true,mode:'edit'})
},

closeReviewModal:()=>{set({display:false,});get().clear()},



review:{
    photo:'',
    name:'',
    position:'',
    company:'',
    message:'',
},

inputHandler:(v,e)=>{

    set({review:{...get().review,[e.target.id]:v}})

},

onPhotoChange:(url)=>{
    set({review:{...get().review,['photo']:url}})
},




addReview:()=>{

    let {page}=usePersonal.getState()
    let {review}=get()
    review.id=shortid.generate()
    page.reviews = [...page.reviews,review]
    usePersonal.setState({page})
    get().closeReviewModal()

},

updateReview:()=>{

    let {review} = get()
    let reviewId=review.id
    let {page}=usePersonal.getState()
    let index = page.reviews.findIndex(review=>(reviewId==review.id))
    page.reviews[index]=get().review
    usePersonal.setState({page})
    get().closeReviewModal()
},

deleteReview:(reviewId)=>{

    let {page}=usePersonal.getState()
    let reviews = page.reviews.filter(review=>(reviewId!=review.id))
    page.reviews=reviews
    usePersonal.setState({page})

},


clear:()=>{
    set({
        mode:undefined,
        review:{
            photo:'',
            name:'',
            position:'',
            company:'',
            message:'',
        }
    })
}
    
}))