import { useEffect } from "react";
import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./OrdersModal.module.css";
import { useOrdersModal } from "./useOrdersModal";
import { Button } from "rsuite";
import { calculateOrderTotal, calculateTotalByClientOrders } from "../../../../../../helperFunctions";

function OrdersModal({pageId,tableNumber}) {



  let { 
    display,
    closeModal, 
    inputHandler,
    getOrders,
    clientOrders,
    createRequest,
  } =useOrdersModal();



  const ordersTotal = calculateTotalByClientOrders(clientOrders)

  const PayButton =<>
            <Button
            onClick={''}
            color="blue"
            appearance="primary"
          >
            Оплатити карткою
          </Button>

  </>

  const RequestsButton =<>
  <Button
            onClick={()=>{createRequest(pageId,`Підійдіть  до ${tableNumber} столика`)}}
            color="blue"
            appearance="primary"
          >
            Визвати офіціанта
          </Button>

          <Button
            onClick={()=>{createRequest(pageId,`Принесіть рахунок до ${tableNumber} столика`)}}
            color="blue"
            appearance="primary"
          >
            Запросити рахунок
          </Button>
  
  
  </>

  if (display) return (
      <>

        <ModalWindow title={'Мої замовлення'}>



        {clientOrders.length && clientOrders.map(order=>(<>
        <MyOrder
        orderId={order.id}
        status={order.status}
        basket={order.basket}
        />
        
        
        </>))}


          {/* <strong>Чайові: 0 грн</strong> */}
          <strong>До сплати: {ordersTotal} грн</strong>


          {tableNumber && RequestsButton}


          {/* {PayButton} */}

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
      </>
    );
}

export default OrdersModal;




const MyOrder =({orderId,status,basket})=>{


return <>

<div className={styles.myorder_container}>
    <div>Номер замовлення: {orderId}</div>
    <div>Статус:<strong>{status}</strong> </div>
{basket && basket.map(item=>(<>
<Item 
name={item.name}
qty={item.quantity}
price={item.price}
/>
</>))}

</div>

</>

}


const Item =({name,qty,price})=>{
  return <>
  <div className={styles.item_container}>
    <div>{name} x{qty} </div>
    <div>{price} грн </div>

  </div>
  
  </>
}