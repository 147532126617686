import { useEffect } from "react";
import styles from "./WebP.module.css";
import { useWebP } from "./useWebP";

function WebP() {
  
let {getPage}=useWebP()



useEffect(()=>{
  getPage()
},[])

  return <>
  </>;
}

export default WebP;


