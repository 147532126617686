import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../Components/Header/Header";
import styles from "./Business.module.css";
import { useBusiness } from "./useBusiness";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import Switcher from "../../../Components/Switcher/Switcher";
import { Input, Checkbox,Toggle, InputPicker,Divider } from "rsuite";
import PhotoUploader from "../../../Components/PhotoUploader/PhotoUploader";
import WarningRoundIcon from "@rsuite/icons/WarningRound";
import { useGoodsCategory } from "./Components/GoodsCategory/useGoodsCategory";
import { frontDB } from "../../../helpers/frontDB";
import Select from "react-select";
import GoogleMaps from "../../../Components/GoogleMaps/GoogleMaps";
import moment from "moment";
import { Feedback, UserButton } from "../Personal/Personal";
import { feedbackOptions } from "../../../helperFunctions";
import SocialNetworks from "../../../Components/SocialNetworks/SocialNetworks";
import Messangers from "../../../Components/Messangers/Messangers";
import { useButtonModal } from "./Components/ButtonModal/useButtonModal";
import ButtonModal from "./Components/ButtonModal/ButtonModal";
import OrderDetailsModal from "./Components/OrderDetailsModal/OrderDetailsModal";
import { useOrderDetailsModal } from "./Components/OrderDetailsModal/useOrderDetailsModal";
import Promocodes from "../../../Components/Promocodes/Promocodes";
import StatsBlock from "../../../Components/StatsBlock/StatsBlock";
import { useEffect } from "react";
import { orderStatuses } from "../../PublicPages/helper";
import AccessManager from "../../AccountSettings/Components/AccessManager/AccessManager";
import { useRef } from "react";
import PaymentsSection from "../../../Components/PaymentsSection/PaymentsSection";

function Business() {
  let navigate = useNavigate();

  let { mode } = useParams();
  let { user } = frontDB();

  let {
    page,
    inputHandler,
    section,
    setSection,
    addPage,
    updatePage,
    closeWindow,
    schedule,
    scheduleInputHandler,
    catalog,
    deleteCategory,
    folderInputHandler,
    changePhoto,
    locationHandler,

    appointmentBlockHandler,
    feedbackTypeHandler,
    analyticsTrackingHandler,
    onSocialNetworksChange,
    onMessangersChange,
    whatsappHandler,
    ordersHandler,
    orderStatus,
    orderStatusHandler,
    deliveryOptionHandler,
    orderPaymentHandler,

    
  } = useBusiness();

  let { showAddCategoryWindow, showCategoryEditWindow,handleFileUpload } = useGoodsCategory();

  let {
    showAddButtonModal,
    deleteButton,
    showEditButtonModal,
  }=useButtonModal()


let {showOrderDetails}=useOrderDetailsModal()

let filteredOrders = page?.orders.filter(order=>{
  if(orderStatus=='all') return order
  return order.status==orderStatus
})


useEffect(()=>{
  
},[orderStatus])

const fileInputRef = useRef(null);
  //Розділи

  let aboutSection = (
    <>
      <Input
        id="name"
        onChange={inputHandler}
        value={page?.name}
        placeholder="Назва бізнесу"
      />

      <Input
        id="phone"
        onChange={inputHandler}
        value={page?.phone}
        placeholder="Телефон"
      />

      <Input
        id="email"
        onChange={inputHandler}
        value={page?.email}
        placeholder="Email"
      />

      <Input
        id="website"
        onChange={inputHandler}
        value={page?.website}
        placeholder="Веб сайт"
      />
      <Input
        id="city"
        onChange={inputHandler}
        value={page?.city}
        placeholder="Місто"
      />

      <Input
        id="street"
        onChange={inputHandler}
        value={page?.street}
        placeholder="Адреса"
      />

      <Input
        as="textarea"
        rows={3}
        id="about"
        onChange={inputHandler}
        value={page?.about}
        placeholder="Опис"
      />
    </>
  );

  let photoSection = (
    <>
      <PhotoUploader
        type={"photo"}
        onUploaded={(url) => {
          changePhoto({ type: "photo", url });
        }}
        url={page?.photo}
      />

      <PhotoUploader
        type={"backgroundPhoto"}
        onUploaded={(url) => {
          changePhoto({ type: "backgroundPhoto", url });
        }}
        url={page?.backgroundPhoto}
      />
    </>
  );

  let socialNetworksSection =<>

  <SocialNetworks
    dafaultValue={page?.socialNetworks} 
    onChange={onSocialNetworksChange}
  />
  
  </>
  
  let messengersSection=<>
  
  <Messangers
    dafaultValue={page?.messengers} 
    onChange={onMessangersChange}
  />
  
  </>
    

  let scheduleSection = (
    <>
      {Object.keys(schedule).map((day) => {
        let { from, to } = schedule[day];

        return (
          <Day
            onChange={(value, event) => {
              scheduleInputHandler(value, day, event);
            }}
            day={day}
            from={from}
            to={to}
          />
        );
      })}
    </>
  );

  let catalogSection = (
    <>
      <div>Категорії товарів</div>

      {catalog.map((category) => (
        <CatalogCategory
          key={category?.id}
          name={category?.name}
          onEdit={() => {
            showCategoryEditWindow(navigate, category?.id);
          }}
          onDelete={() => {
            deleteCategory(category?.id);
          }}
        />
      ))}

      <div
        onClick={() => {
          showAddCategoryWindow(navigate);
        }}
        className={styles.link}
      >
        {" "}
        + Додати категорію
      </div>
      <input  ref={fileInputRef}  type="file" accept=".csv" onChange={handleFileUpload} />
      <button onClick={()=>{fileInputRef.current.value = ''}}>Clear File Input</button>
    </>
  );

  let othersSection = (
    <>
      <select
        value={page?.folderId}
        id="folderId"
        placeholder={"Оберіть папку"}
        onChange={folderInputHandler}
      >
        {[
          <option value={1}>Без папки</option>,
          ...user?.folders?.map((folder) => (
            <option value={folder.id}>{folder.name}</option>
          )),
        ]}
      </select>

      <div>WiFi Доступ:</div>

      <Input
        id="ssid"
        onChange={inputHandler}
        value={page?.ssid}
        placeholder="SSID"
      />
      <Input
        id="ssidPassword"
        onChange={inputHandler}
        value={page?.ssidPassword}
        placeholder="Password"
      />
    </>
  );

  let locationSection = (
    <>
      <div>Широта:</div>
      <Input
        id="lat"
        onChange={locationHandler}
        value={page?.location?.lat}
        placeholder="Широта"
      />
      Довгота:
      <Input
        id="lng"
        onChange={locationHandler}
        value={page?.location?.lng}
        placeholder="Довгота"
      />
      <GoogleMaps lat={+page?.location?.lat} lng={+page?.location?.lng} />
    </>
  );

  let appointmentsSection = (
    <>
      <Checkbox
        onChange={appointmentBlockHandler}
        checked={page?.appointmentBlock}
      >
        Відображати блок бронювання
      </Checkbox>
      Записи:
      {page?.appointments.map((appointment) => (
        <Feedback
          key={appointment?.id}
          name={appointment?.name}
          phone={appointment?.phone}
          email={appointment?.email}
          timestamp={moment(appointment?.timestamp).format("DD.MM.YYYY")}
          message={appointment?.message}
          date={appointment?.date}
          time={appointment?.time}
        />
      ))}
    </>
  );

  let feedbackSection = (
    <>
      <div>Тип зворотнього звязку:</div>

      <Select
        name="feedbackType"
        placeholder="Оберіть тип зворотнього звязку"
        value={feedbackOptions.find(
          (item) => item.value === page?.feedbackType
        )}
        onChange={feedbackTypeHandler}
        options={feedbackOptions}
      />

      {page?.feedbackType == "Whatsapp" && (
        <>
          <div>Номер whatsapp</div>
          <Input
            id="whatsapp"
            onChange={whatsappHandler}
            value={page?.messengers?.whatsapp || '+380'}
            placeholder="WhatsApp"
          />
        </>
      )}

      <div>Повідомлення</div>

      {page?.feedbacks?.map((feedback) => (
        <Feedback
          key={feedback?.id}
          name={feedback?.name}
          phone={feedback?.phone}
          email={feedback?.email}
          timestamp={moment(feedback?.timestamp).format("DD.MM.YYYY")}
          message={feedback?.message}
        />
      ))}
    </>
  );

  let analyticsTrackingSection = <>
  
    <Input
        id="googleAnalytics"
        onChange={analyticsTrackingHandler}
        value={page?.analyticsTracking?.googleAnalytics}
        placeholder="Google Analytics"
      />

      <Input
        id="facebookPixelId"
        onChange={analyticsTrackingHandler}
        value={page?.analyticsTracking?.facebookPixelId}
        placeholder="Facebook Pixel ID"
      />

      <Input
        id="googleTagManagerId"
        onChange={analyticsTrackingHandler}
        value={page?.analyticsTracking?.googleTagManagerId}
        placeholder="Google Tag Manager ID"
      />
  
  </>

  let buttonsSection = <>

  <div
  onClick={showAddButtonModal} 
  className={'link'}
  >+ Додати кнопку</div>

  {page?.buttons.map(button=>(

  <UserButton
    type={'edit'}
    photo={button?.photo}
    url={button?.url} 
    name={button?.name}
    onClick={()=>{showEditButtonModal(button.id)}}
    onDelete={()=>{deleteButton(button.id)}}
  />


  ))}





  </>

  let ordersSection = <>

<span>Приймати замовлення: &nbsp; 
    <Toggle onChange={ordersHandler} checked={page?.allowOrders} size={'sm'}/> 
  </span>



<Divider/>
<span>На адресу: &nbsp; 
    <Toggle 
    onChange={(v)=>{deliveryOptionHandler('toTheAddress',v)}}
    checked={page?.orderSettings?.deliveryType?.toTheAddress} 
    size={'sm'}
    /> 
  </span>

  <span>На виніс :&nbsp; 
    <Toggle 
    onChange={(v)=>{deliveryOptionHandler('takeaway',v)}}
    checked={page?.orderSettings?.deliveryType?.takeaway} 
    size={'sm'}/> 
  </span>


  <span>В ресторані: &nbsp; 
    <Toggle 
    onChange={(v)=>{deliveryOptionHandler('inRestaurant',v)}}
    checked={page?.orderSettings?.deliveryType?.inRestaurant} 
    size={'sm'}/> 
  </span>


<Divider/>

<span>Оплата на сайті: &nbsp; 
  <Toggle 
    onChange={(v)=>{orderPaymentHandler('online',v)}}
    checked={page?.orderSettings?.paymentType?.online} 
    size={'sm'}
    /> 
  </span>

  <span>Оплата на місці: &nbsp; 
  <Toggle 
    onChange={(v)=>{orderPaymentHandler('offline',v)}}
    checked={page?.orderSettings?.paymentType?.offline} 
    size={'sm'}
    /> 
  </span>





  <StatsBlock
  pageType='business'
  orders={page?.orders}
  />





  <div>Замовлення:</div>
  <InputPicker
      value={orderStatus}
      onChange={orderStatusHandler}
      cleanable={false}
      placeholder="Оберіть статус"
      data={[{value:'all',label:'all'},...orderStatuses]}
      style={{ width: 364 }}
  />

  {filteredOrders.map(order=>(
    <Order
    clientName={order?.info.delivery}
    createdAt={moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}
    onClick={()=>{showOrderDetails(order.id)}}
    status={order?.status}
    responsible={order?.responsible}
    paid={order?.paid ? 'Так' : 'Ні'}
  />
  ))}

  </>

  let promocodesSection = <>

  <Promocodes 
  pageType='business' 
  promocodes={page?.promocodes}
  />

  </>

   let accessSection =<>
<AccessManager pageId={page._id}/>

</>


const paymentsSection =<>

 <PaymentsSection enablePayPal={page?.enablePayPal} pageType='business' pageId={page?._id}/>


</>



  //! Render
  return (
    <>
      <Header />

      <div className={styles.header}>
        <div className={styles.title}>
          {mode == "add"
            ? "Нова сторінка (Бізнес)"
            : "Редагувати сторінку (Бізнес)"}
        </div>

        <Switcher
          links={[
            "Про бізнес",
            "Фото",
            'Месенджери',
            "Соц мережі",
            "Розклад",
            "Каталог",
            "Інше",
            "Зворотній звязок",
            "Бронювання",
            "Місце розташування",
            'Відслідковування аналітики',
            'Кнопки',
            'Замовлення',
            'Промокоди',
            'Доступи',
            'Платежі',
          ]}
          section={section}
          setSection={setSection}
        />
      </div>

      <div className={styles.container}>

        {section == "Про бізнес" && aboutSection}
        {section == "Фото" && photoSection}
        {section == "Месенджери" && messengersSection}
        {section == "Соц мережі" && socialNetworksSection}
        {section == "Розклад" && scheduleSection}
        {section == "Каталог" && catalogSection}
        {section == "Інше" && othersSection}
        {section == "Місце розташування" && locationSection}
        {section == "Зворотній звязок" && feedbackSection}
        {section == "Бронювання" && appointmentsSection}
        {section == "Відслідковування аналітики" && analyticsTrackingSection}
        {section=='Кнопки' && buttonsSection}
        {section=='Замовлення' && ordersSection}
        {section=='Промокоди' && promocodesSection}
        {section=='Доступи' && accessSection}
        {section=='Платежі' && paymentsSection}

      </div>

      <BottomButtons
        mode={mode}
        onCreate={() => {
          addPage(navigate);
        }}
        onUpdate={() => {
          updatePage(navigate);
        }}
        onCancel={() => {
          closeWindow(navigate);
        }}
      />
      <OrderDetailsModal pageType={page.type} pageId={page._id}/>
      <ButtonModal/>
    </>
  );
}

export default Business;

//c Допоміжні

function Day({ onChange, day, from, to }) {
  let weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  let ukrWeekdays = [
    "Понеділок",
    "Вівторок",
    "Середа",
    "Четвер",
    "П'ятниця",
    "Субота",
    "Неділя",
  ];
  let i = weekdays.indexOf(day);
  day = ukrWeekdays[i];

  return (
    <>
      <div className={styles.day_container}>
        <div>{day}</div>

        <Select
          name="from"
          value={timeOptions.find((time) => time.value === from)}
          onChange={onChange}
          options={timeOptions}
        />

        <Select
          name="to"
          value={timeOptions.find((time) => time.value === to)}
          onChange={onChange}
          options={timeOptions}
        />

        {/* <div 
style={{display:'flex',alignItems:'center'}}
>Вихідний: <Checkbox onChange={(v,c)=>{}} />
</div> */}
      </div>
    </>
  );
}

function CatalogCategory({ name, onEdit, onDelete }) {
  return (
    <>
      <div className={styles.catalog_category_container}>
        <div onClick={onEdit}>{name}</div>
        <WarningRoundIcon onClick={onDelete} style={{ color: "red" }} />
      </div>
    </>
  );
}

let times = [
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
];

let timeOptions = times.map((time) => ({ value: time, label: time }));


export function Order ({responsible,clientName,createdAt,onClick,status,paid}) {

  responsible=responsible&&<div>Responsible: {responsible}</div>

  return <>
  <div className={styles.order_container}>
    <strong>{clientName}</strong>
    <div>{createdAt}</div>
    <div>Статус: {status}</div>
    <div>Оплачений: {paid}</div>
  
    <div onClick={onClick} className={'link'}>Деталі</div>
    {responsible}
  </div>
  </>
}


function StatTable ({property,value}) {
return <>
<div className={styles.stat_table_container}></div>

</>

}