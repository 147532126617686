import { useEffect, useRef, useState } from "react";
import styles from "./PhotoUploader.module.css";
import { Oval } from  'react-loader-spinner'
import edit from './edit.png'
import Compressor from 'compressorjs'
import { api } from "../../helpers/api";




function PhotoUploader({type,onUploaded,url}) {
  
  let [status,setStatus]=useState()
  let [photo,setPhoto]=useState()
  
  
  
  let filePicker=useRef(null)

  
let   handlePicker = ()=>  {
      filePicker.current.click()
  }
  
let   handleFileChange=(e)=>{

    let image = e.target.files[0]
    if(!image) return


    new Compressor(image, {
      quality: 0.6,
      // width: 175, 
      success: (compressedImage) => {

        handleSubmit(compressedImage)

      }
      });

}

let   handleSubmit = async (image)=>{

      setStatus('loading')

      
      try {

        let formData = new FormData()
        formData.append('file',image, image.name)
        let response = await api.user.uploadImage(formData)

          if(response){

            setPhoto(response)
            setStatus('loaded')
            onUploaded(response)

          }
        
       } catch (error) {
           console.log(error);  
       }
}
 
useEffect(()=>{

  if(url){setPhoto(url)}

},[])
 
  


if(type=='photo'){return (<>
      
      <input style={{display:'none'}} ref={filePicker} onChange={handleFileChange} type="file"  name="file"/>
      
      
      
            <div  className={styles.photo_container}>
      
              <div style={{width:'100px', height:'100px'}} className={styles.photo}>
    
              {status=='loading'? spiner:<img width={'175px'} src={photo} alt="" />}
              <img className={styles.edit} onClick={handlePicker} src={edit} alt="" />

            </div>
          
         
       </div>
      
      </>)}
 
if(type=='backgroundPhoto') {


  return <>
        <input style={{display:'none'}} ref={filePicker} onChange={handleFileChange} type="file"  name="file"/>
        {status=='loading'? 
        <div className={styles.background_photo_container} onClick={handlePicker}>{spiner}</div>
        :
        
<>
<div className={styles.background_photo_container} >
<img width={'400px'}  src={photo} alt="" />

</div>
<div onClick={handlePicker} className={'link'} >Змінити обкладинку</div>
</>
  }
  
  </>
} 
  
  
}

export default PhotoUploader;



export function Photo ({type, url}){


  if(type=='backgroundPhoto'){
    return <div className={styles.background_photo_container_view}>

          <img width={'500px'} src={url} />



    </div>
  }


  return <>
  <div  className={styles.photo_container}>
      
      <div style={{width:'50px', height:'50px'}} className={styles.photo}>

      <img width={'175px'} src={url} />

      </div>
   
  
      </div>
  
  
  </>
}





let spiner =

<Oval
height={40}
width={40}
color="#ffff"
wrapperStyle={{}}
wrapperClass=""
visible={true}
ariaLabel='oval-loading'
secondaryColor="#25B3F5"
strokeWidth={4}
strokeWidthSecondary={4}

/>


  