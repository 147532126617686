import styles from "./PayPalForPage.module.css";
import { PayPalButtons } from "@paypal/react-paypal-js"

function PayPalForPage({pageType,pageId,basket,onSuccess,onError}) {




  const createOrder = (data) => {

console.log(pageType,pageId,basket); 

    console.log('createOrder');
     // Order is created on the server and the order id is returned
     return fetch(`${process.env.REACT_APP_API}/api/pages/payments`, {
       method: "POST",
        headers: {
         "Content-Type": "application/json",
       },
       // use the "body" param to optionally pass additional order information
       // like product skus and quantities
       body: JSON.stringify({pageType,pageId,basket}),
     })
     .then((response) => response.json())
     .then((order) => order.id);
   };
 
 
   const onApprove = (data) => {
    
    console.log('approveOrder');
      // Order is captured on the server and the response is returned to the browser
      return fetch(`${process.env.REACT_APP_API}/api/pages/payments/orders/${data.orderID}/capture`, {
       method: "POST",
        headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         orderID: data.orderID
       })
     })
     .then((response) => {
       
       return response.json()
     }
       )
     .then(response=>{
     
       const orderStatus = response.status
 
       if(orderStatus=='COMPLETED') onSuccess(true)
       if(orderStatus!='COMPLETED') onError(true)
 
     })
     
   };
 



  return <>
  
  <PayPalButtons
      createOrder={(data,actions) => createOrder(data, actions)}
      onApprove={(data,actions) => onApprove(data, actions)}
  />

  
  </>;
}

export default PayPalForPage;
