import {create} from 'zustand'
import { frontDB } from '../../../helpers/frontDB'
import { api } from '../../../helpers/api'
import { showToast } from '../../../Components/Toast/useToast'
import shortid from 'shortid'
import { sortReviews } from '../../../helperFunctions'



export let useReview = create((set,get)=>({

    section:'Основне',

    setSection:async (e)=>{set({section:e.target.id})

    if(e.target.id=='Відгуки'){
        let page = await api.user.getPageById('review',get().page._id)
        set({page,categories:page?.categories})
 
    }

    },

    showReviewPageEditData:(pageId)=>{


        let page = frontDB.getState().getPageById(pageId)
        set({page,categories:page?.categories})
    },

    closeWindow:(navigate)=>{

      
      get().clear()

    navigate(-1)

    },




    page:{

        folderId:'Без папки',
    
        name:'',
        title:'',
        about:'',
        googleLink:'',
        reviews:[],
        
    },

    inputHandler:(v,e)=>{

        set({page:{...get().page,[e.target.id]:v}})
    
    },

    folderInputHandler:(e)=>{
        set({page:{...get().page,[e.target.id]:e.target.value}})
    
    },

    
    changePhoto:async({type,url})=>{

    if(type=='photo'){set({page:{...get().page,photo:url}})}
    let response = await api.user.updatePage(get().page)
  
    },


   //Категорії
   categories:[],

   categoryInputHandler:(v,e,i)=>{
    
    let updatedCategories = [...get().categories];
    updatedCategories[i][e.target.id] = v;
    set({ categories: updatedCategories });
},


   addCategory:()=>{

    let {categories}=get()
    set({categories:[...categories,{id:shortid.generate(),name:''}]})

   },

   deleteCategory:(categoryId)=>{
    let {categories}=get()
    let index =categories.findIndex(category=>(categoryId==category.id))
    categories.splice(index,1)
    set({categories})
   },


    addPage: async (navigate)=>{

        let {page,categories} = get()
       
        if(page.name==''){showToast('e','Введіть назву');return}
    
        page.type='review'
        page.categories=categories
    
        let response = await api.user.addPage(page)
        if(response===true){
    
            get().clear()
            navigate('/pages')
        }
    
    
    },

    updatePage:async(navigate)=>{

        let {page,categories} = get()
    
        page.categories=categories
    
        let response = await api.user.updatePage(page)
        if(response){
    
            get().clear()
            navigate('/pages')
    
        }
            
    },


    sortSettings:{byDate:1,byRate:1},

    sortHandler:(e)=>{
        let id=e.target.id
       
        let {sortSettings}=get()
        let {reviews}=get().page

        if(id=='date'){
            console.log(sortSettings.byDate);

            if(sortSettings.byDate==1 ){
                set({sortSettings:{...get().sortSettings,byDate:-1}})
                reviews=sortReviews(get().sortSettings,reviews)
                return
                
            } 
            if (sortSettings.byDate== -1) {
                set({sortSettings:{...get().sortSettings,byDate: 1}})
                reviews=sortReviews(get().sortSettings,reviews)
                return
            }

        }

        if(id=='rate') {

        if(sortSettings.byRate==1 ){
                set({sortSettings:{...get().sortSettings,byRate:-1}})
                reviews=sortReviews(get().sortSettings,reviews)
                return
        } 
        
        if(sortSettings.byRate==-1) {
                set({sortSettings:{...get().sortSettings,byRate:1}})
                reviews=sortReviews(get().sortSettings,reviews)
                return
        }

 
        }
    set({page:{...get().page,reviews}})

    },






    clear:()=>{

     set({ 
            page:{

            folderId:'Без папки',

            name:'',
            title:'',
            about:'',
            googleLink:'',
            reviews:[],

            
        },

        categories:[],
        section:'Основне'

        })
    }
}))