import { Button,Placeholder,Input } from "rsuite";
import { InputsContainer, ItemContainer, PublicGridContainer, ServiceContainer } from "../../../../../../Components/BaseComponents/BaseComponents";
import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import { usePersonalP } from "../../usePersonalP";
import styles from "./BasketModal.module.css";
import { useBasketModal } from "./useBasketModal";
import { calculateOrderTotal, calculateServiceTotal } from "../../../../../../helperFunctions";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalForPage from "../../../../../../Components/PayPalForPage/PayPalForPage";



function BasketModal() {

  let {
    
    display,
    closeBasket,
    createOrder,
    inputHandler,
    clientInfo,
    section,
    setSection,
    orderId,
    basket,
    removeFromBasket,
    disablePromocodeInput,
    disableCheckPromocodeButton,
    checkPromocode,
    total,
    onPaymentSuccess,
    onPaymentError,
  
  }=useBasketModal()



  const {page}=usePersonalP()



  const initialOptions = {
    clientId: page?.paypalCredentials?.clientId,
    currency: "USD",
    intent: "capture",
  };
  

const PayPalButton = <>

<PayPalForPage
    pageType={'personal'}
    pageId={page?._id}
    basket={basket}
    onSuccess={onPaymentSuccess}
    onError={onPaymentError}
    
    
/>

</>

const CreateOrderButton =<>

<Button
            color="blue"
            appearance="primary"
            onClick={createOrder}
          >
            Замовити
          </Button>

</>


  let itemsSection = <>


    <ModalWindow title={'Корзина'} style={{width:'500px'}}>
   
   {basket.length==0 && <Placeholder rows={8}/>}

   {basket?.map(item=>(
    
    <ServiceContainer
      allowOrders
      defaultType={'-'}
      name={item.itemInfo?.name}
      price={item.itemInfo?.price}
      currency={item.itemInfo?.currency}
      onRemoveService={()=>{removeFromBasket(item.itemInfo?.id)}}
   />
   ))}
<span ><strong>До сплати:</strong> {total}</span>


    <Input
        disabled={disablePromocodeInput}
        id="promocode"
        onChange={inputHandler}
        value={clientInfo?.promocode}
        placeholder="Промокод"
      />

       {basket.length!=0 && <>
        <Button
            disabled={disableCheckPromocodeButton}
            color="orange"
            appearance="primary"
            onClick={checkPromocode}
          >
            Застосувати промокод
          </Button>
       
       
         <Button
            color="blue"
            appearance="primary"
            onClick={()=>{setSection('оформити замовлення')}}
          >
            Оформити замовлення
          </Button>

          </>
        }
          <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>
        


  </ModalWindow>

  </>

  let  confirmOrderSection =<>
<PayPalScriptProvider options={initialOptions}>
      <ModalWindow title={'Оформити замовлення'} >
<div className={styles.inputs_container}>
      <InputsContainer>

      <Input
        id="name"
        onChange={inputHandler}
        value={clientInfo?.name}
        placeholder="ПІБ"
      />

      <Input
        id="adress"
        onChange={inputHandler}
        value={clientInfo?.adress}
        placeholder="Адреса"
      />

      <Input
        id="phone"
        onChange={inputHandler}
        value={clientInfo?.phone}
        placeholder="Номер телефону"
      />

      <Input
        id="email"
        onChange={inputHandler}
        value={clientInfo?.email}
        placeholder="Ваш емейл"
      />

      <Input
        as='textarea'
        rows={3}
        id="message"
        onChange={inputHandler}
        value={clientInfo?.message}
        placeholder="Повідомлення" 
        />



      </InputsContainer>
</div>




{page?.enablePayPal ? PayPalButton : CreateOrderButton}

          




          <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>




      </ModalWindow>
      </PayPalScriptProvider>
  </>

  let successOrderSection =<>
   
   <ModalWindow title={'Замовлення успішне'} >

<PublicGridContainer>
  <div>Ваш номер замовлення: &nbsp;&nbsp;</div>
  <strong>{orderId}</strong>

</PublicGridContainer>

   <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>


   </ModalWindow>




  </>



  if  (display) return <>

  {section=='послуги' && itemsSection}
  {section=='оформити замовлення' && confirmOrderSection}
  {section=='замовлення успішне' && successOrderSection}


  </>;
}

export default BasketModal;
