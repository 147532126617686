import { frontDB } from "../../../../../../helpers/frontDB";
import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./AccessModal.module.css";
import { useAccessModal } from "./useAccessModal";
import { Button, Input,InputPicker,TagPicker  } from "rsuite";

function AccessModal({pageId}) {

let {pages}=frontDB()

  let { 
    display, 
    mode, 
    closeModal, 
    inputHandler, 
    addAccess, 
    updateAccess,
    access,
    roleHandler,
    pagesHandler,
    generatePassword,
   } =
    useAccessModal();

    //Transform stage

    let roleOptions = [{name:'Адміністратор',id:'admin'},{name:'Обробка замовлень',id:'orderManager'}].map((item) => ({ label: item.name, value: item.id }))
    

    let pagesOptions = pageId
    ? pages.filter(page=>page._id==pageId).map((item) => ({ label: item.name, value: {type:item.type,id:item._id} }))
    : pages.map((item) => ({ label: item.name, value: {type:item.type,id:item._id} }))



  if (display)
    return (
      <>
      
        <ModalWindow title={mode == "add" ? "Додати доступ" : "Редагувати доступ"}>
          
        <Input
            id={'name'}
            onChange={inputHandler}
            value={access.name}
            placeholder="Імя"
          />

          <Input
            id={'email'}
            onChange={inputHandler}
            value={access.email}
            placeholder="Email"
          />
          <div className={styles.password_container}>
            <Input
              id={'password'}
              onChange={inputHandler}
              value={access.password}
              placeholder={mode =='add' ? "Пароль":"Новий пароль"}
            />

            <div onClick={generatePassword} className={'link'}>Згенерувати</div>
          </div>
          
          <InputPicker
            
            value={access.role}
            onChange={roleHandler}
            cleanable={false}
            placeholder="Оберіть роль"
            data={roleOptions}
            style={{ width: 364 }}
          />

          
          <TagPicker
              data={pagesOptions}
              value={access.pages}
              onChange={pagesHandler}
              style={{ width: 364 }}
              searchable={false}
              cleanable={false}
              placeholder="Оберіть сторінки"

              checkable
              placement="auto"
            />
          
          
          
          <Button
            onClick={mode == "add" ? addAccess : updateAccess}
            color="blue"
            appearance="primary"
          >
            Зберегти
          </Button>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
      </>
    );
}

export default AccessModal;
