import {create} from 'zustand'
import { frontDB } from '../../../helpers/frontDB'
import { api } from '../../../helpers/api'



export let useBusiness = create((set,get)=>({

section:'Про бізнес',

setSection:async (e)=>{set({section:e.target.id})

    let{page}=get()


    if(e.target.id=='Зворотній звязок'&& page?._id){
        let updatedPage = await api.user.getPageById('business',page._id)
        set({page:{...get().page,feedbacks:updatedPage.feedbacks}})

    }

    if(e.target.id=='Бронювання'&& page?._id){
        let updatedPage = await api.user.getPageById('business',page._id)
        set({page:{...get().page,appointments:updatedPage.appointments}})

    }

    if(e.target.id=='Замовлення'&& page?._id){
        let updatedPage = await api.user.getPageById('business',page._id)
        set({page:{...get().page,orders:updatedPage.orders}})

    }


},

showBusinessPageEditData:(pageId)=>{

    let page = frontDB.getState().getPageById(pageId)
    set({
    
        page:page,
        schedule:page?.schedule,
        catalog:page?.catalog,
    
    })
},

closeWindow:(navigate)=>{



    get().clear()
    navigate(-1)
},



page:{

    folderId:'Без папки',

    name:'',
    phone:'',
    email:'',
    website:'https://www.',
    city:'',
    street:'',
    about:'',
    ssid:'',
    ssidPassword:'',
    location:{lat:undefined,lng:undefined},
    appointmentBlock:false,
    appointments:[],
    feedbackType:'Форма',
    feedbacks:[],
    messengers:undefined,
    socialNetworks:undefined,
    analyticsTracking:{googleAnalytics:'',facebookPixelId:'',googleTagManagerId:''},
    buttons:[],
    allowOrders:false,
    orders:[],
    promocodes:[],
    orderSettings:{
        deliveryType:{
            inRestaurant: false,
            takeaway    : false,
            toTheAddress: false,
        },
        paymentType:{
            online : false,
            offline: false,
        }
        },
    paypalCredentials:{clientId:undefined,secretKey:undefined},  
    enablePayPal:false,

},

changePhoto:async({type,url})=>{

    if(type=='photo'){set({page:{...get().page,photo:url}})}
    if(type=='backgroundPhoto'){set({page:{...get().page,backgroundPhoto:url}})}
  
},


inputHandler:(v,e)=>{

    set({page:{...get().page,[e.target.id]:v}})

},

folderInputHandler:(e)=>{
    set({page:{...get().page,[e.target.id]:e.target.value}})

},

locationHandler:(v,e)=>{
    
    let {page}=get()
    set({page:{...page,location:{...page.location,[e.target.id]:v}}})
    
},

appointmentBlockHandler:(v,c)=>{
    set({page:{...get().page,appointmentBlock:c}});
},
 
feedbackTypeHandler:(option,action)=>{

    let value =option.value
    let {page} =get()

    set({page:{...page,['feedbackType']:value}})
},


whatsappHandler:(v)=>{
    set({page:{...get().page,messengers:{...get().page.messengers,
    
    whatsapp:v    
    
    }}})
},

onMessangersChange:(v)=>{
    set({page:{...get().page,messengers:v}})
},

onSocialNetworksChange:(v)=>{
    set({page:{...get().page,socialNetworks:v}})
},

analyticsTrackingHandler:(v,e)=>{
    let id = e.target.id
        set({page:{...get().page,analyticsTracking:{...get().page.analyticsTracking,
        [id]:v
    }}})
},

ordersHandler:(v)=>{
   
    set({page:{...get().page,allowOrders:v}})
},



//Графік

schedule:{
    monday: {from:'08:00',to:'17:00',weekend:false},
    tuesday: {from:'08:00',to:'17:00',weekend:false},
    wednesday: {from:'08:00',to:'17:00',weekend:false},
    thursday: {from:'08:00',to:'17:00',weekend:false},
    friday: {from:'08:00',to:'17:00',weekend:false},
    saturday: {from:'08:00',to:'17:00',weekend:false},
    sunday: {from:'08:00',to:'17:00',weekend:false},
  },

scheduleInputHandler:(value,day,event)=>{

    let time =value.value
    let type = event.name

    let updatedSchedule = get().schedule;
    updatedSchedule[day][type] = time;
    set({ schedule: updatedSchedule });
},

//Каталог

catalog:[],

deleteCategory:async (categoryId)=>{
    let {catalog} =get()
    let index = catalog.findIndex(category=>(categoryId==category.id))

    catalog.splice(index,1)
    set({catalog})
},

// Order status

orderStatus:'all',
orderStatusHandler:(v)=>{
    set({orderStatus:v})
},


deliveryOptionHandler:(property,value)=>{
    let {page}=get()
    page.orderSettings.deliveryType[property]=value
    set({page})  
},



orderPaymentHandler:(property,value)=>{
    let {page}=get()
    page.orderSettings.paymentType[property]=value
    set({page})  

},




addPage: async (navigate)=>{

    let {page} = get()


    page.type='business'
    page.schedule=get().schedule
    page.catalog=get().catalog

    let response = await api.user.addPage(page)
    if(response===true){

        get().clear()
        navigate('/pages')
    }


},

updatePage:async(navigate)=>{

    let {page} = get()
    page.schedule=get().schedule
    page.catalog=get().catalog

        
    let response = await api.user.updatePage(get().page)
    if(response){

        get().clear()
        navigate('/pages')

    }
        
},



clear:()=>{
    set({
    page:{

       folderId:'Без папки',

       name:'',
       phone:'',
       email:'',
       website:'https://www.',
       city:'',
       street:'',
       about:'',
       ssid:'',
       ssidPassword:'',
       location:{lat:undefined,lng:undefined},
       appointmentBlock:false,
       appointments:[],
       feedbackType:'Форма',
       feedbacks:[],
       messengers:undefined,
       socialNetworks:undefined,
   
       analyticsTracking:{googleAnalytics:'',facebookPixelId:'',googleTagManagerId:''},
       buttons:[],
       allowOrders:false,
       orders:[],
       promocodes:[],
       orderSettings:{
        deliveryType:{
            inRestaurant: false,
            takeaway    : false,
            toTheAddress: false,
        },
        paymentType:{
            online : false,
            offline: false,
        }
        },
       paypalCredentials:{clientId:undefined,secretKey:undefined}, 
       enablePayPal:false,

       
   
   },
   section:'Про бізнес',
   orderStatus:'all',

   catalog:[],
   schedule:{
    monday: {from:'08:00',to:'17:00',weekend:false},
    tuesday: {from:'08:00',to:'17:00',weekend:false},
    wednesday: {from:'08:00',to:'17:00',weekend:false},
    thursday: {from:'08:00',to:'17:00',weekend:false},
    friday: {from:'08:00',to:'17:00',weekend:false},
    saturday: {from:'08:00',to:'17:00',weekend:false},
    sunday: {from:'08:00',to:'17:00',weekend:false},
   },
   
   })
   }
    
}))


//! Допоміжні 


