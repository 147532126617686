import { PriceContainer } from "../../../../../../../Components/BaseComponents/BaseComponents";
import PhotoUploader from "../../../../../../../Components/PhotoUploader/PhotoUploader";
import { currencyOptions } from "../../../../../../../helperFunctions";
import { frontDB } from "../../../../../../../helpers/frontDB";
import { ModalWindow } from "../../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./GoodWindow.module.css";
import { useGoodWindow } from "./useGoodWindow";
import { Input, Button,Toggle,InputPicker} from "rsuite";


function GoodWindow() {



  let {
    good,
    display,
    mode,
    addGood,
    updateGood,
    closeWindow,
    inputHandler,
    changePhoto,
    statusHandler,
    topHandler,
    currencyHandler,
  } = useGoodWindow();



  if (display) {
    return (
      <>
        <ModalWindow
          title={mode == "add" ? "Новий товар" : "Редагувати товар"}
        >
          <div className={styles.photo_container}>
            <PhotoUploader
              type="photo"
              url={good?.photo}
              onUploaded={(url) => {
                changePhoto({ type: "photo", url });
              }}
            />
          </div>

          <Input
            id="name"
            onChange={inputHandler}
            value={good?.name}
            placeholder="Назва товару"
          />

          <Input
            as="textarea"
            rows={3}
            id="about"
            onChange={inputHandler}
            value={good?.about}
            placeholder="Опис"
          />

          <Input
            id="url"
            onChange={inputHandler}
            value={good?.url}
            placeholder="URL"
          />
    <PriceContainer>
          <Input
            id="price"
            onChange={inputHandler}
            value={good?.price}
            placeholder={"Ціна"}
          />

           <InputPicker
              value={good?.currency}
              onChange={currencyHandler}
              cleanable={false} 
              data={currencyOptions} 
              style={{ display:'block' }} 
            />
      </PriceContainer>      

            <span>
              Active: &nbsp; &nbsp; &nbsp;
              <Toggle size={'sm'} checked={good?.active} onChange={statusHandler} />
            
              &nbsp;&nbsp;&nbsp;

              Top: &nbsp; &nbsp; 
              <Toggle size={'sm'} checked={good?.top} onChange={topHandler} />

            
            </span>

            

          <Button
            color="blue"
            appearance="primary"
            onClick={mode == "add" ? addGood : updateGood}
          >
            Зберегти
          </Button>

          <Button onClick={closeWindow} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
      </>
    );
  }
}

export default GoodWindow;
