




export const LocalStorage ={}


LocalStorage.setItem = (key,value)=>{

value=JSON.stringify(value)

localStorage.setItem(key,value)


}

LocalStorage.getItem =  (key)=>{

   let value=  localStorage.getItem(key)
   value=JSON.parse(value)
   return value
 
}

LocalStorage.removeItem =  (key)=>{

    localStorage.removeItem(key)
    

}