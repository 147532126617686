import {create} from 'zustand'
import { api } from '../../../../../helpers/api'
import { showToast } from '../../../../../Components/Toast/useToast'



export let useResumeUploader = create((set,get)=>({

state:'chooseFile',    

file:undefined,


fileHandler:(e)=>{

let file =e.target.files[0]
set({file,state:'uploaded'})

},


uploadFile:async ()=>{
    let {file}=get()

    if(!file?.size) return false

    let formData = new FormData()
    formData.append('file',file, file.name)
    let response = await api.user.uploadResume(formData)
    if(response?.url){return response}
    set({state:'chooseFile',file:undefined})

},


deleteFile: async(url)=>{

    if(url){
        let response = await api.user.deleteResume(url)
    }
    set({state:'chooseFile',file:undefined})
},

getCurrentFile:(fileName)=>{
    if(fileName){set({state:'uploaded',file:{...get.file,['name']:fileName}});return}
    if(!fileName){set({state:'chooseFile',file:undefined});return}

}



    
}))