import {create} from 'zustand'
import { showToast } from '../../../../../../Components/Toast/useToast'
import shortid from 'shortid'
import { api } from '../../../../../../helpers/api'
import { frontDB } from '../../../../../../helpers/frontDB'
import { arrayMethods } from '../../../../../../helperFunctions'



export let useNotificationSettingsModalModal = create((set,get)=>({

    display:false,

    mode:undefined,

    section:undefined,


    showAddNotificationSettingsModal:()=>{
        set({mode:'add',section:'chooseNotificationType',display:true})
    },

    chooseProvider:()=>{
        let {section,usersNotificationsSetting}=get()
        let type = usersNotificationsSetting.type

        if(['email','push'].includes(type)) return showToast('e','Цей метод не доступний')
        if(section=='chooseNotificationType') set({section:'chooseProvider'})
    },
    goToProviderSettings:()=>{
        let {section,usersNotificationsSetting}=get()
        let provider = usersNotificationsSetting.provider

        if(provider=='sms-fly')  set({section:'smsFlySettings'})

    },

    showEditNotificationSettingsModal:(notificationSetting)=>{
        if(notificationSetting.provider=='sms-fly') {
            set({
                usersNotificationsSetting:notificationSetting,
                mode:'edit',
                display:true, 
                section:'smsFlySettings'
            })
        }
        
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    usersNotificationsSetting:{type:'sms',provider:'sms-fly',apiKey:undefined,active:true},

    notificationTypeHandler:(v)=>{
        let {usersNotificationsSetting}=get()
        set({usersNotificationsSetting:{...usersNotificationsSetting,type:v}})
    },

    providerHandler:(v)=>{
        let {usersNotificationsSetting}=get()
        set({usersNotificationsSetting:{...usersNotificationsSetting,provider:v}})

    },

    inputHandler:(v,e)=>{
        let {usersNotificationsSetting}=get()
        set({usersNotificationsSetting:{...usersNotificationsSetting,apiKey:v}})
    },


    statusHandler:async (v,itemId)=>{

       

        let {usersNotificationsSetting}=get()
        let {user}=frontDB.getState()

       

        let usersNotificationsSettings=user.usersNotificationsSettings

        usersNotificationsSettings=arrayMethods.updateElementPropertyById(
            usersNotificationsSettings,
            itemId,
            'active',
            v
        )

        let response = await api.user.updateProfile({usersNotificationsSettings})
        if(response)set({usersNotificationsSetting:{...usersNotificationsSetting,active:v}})

    },


    addNotificationsSettings:async()=>{

        let {usersNotificationsSetting,closeModal}=get()
        let {user}=frontDB.getState()

        usersNotificationsSetting.id=shortid.generate()

        let usersNotificationsSettings=user.usersNotificationsSettings
        usersNotificationsSettings.push(usersNotificationsSetting)

        let response = await api.user.updateProfile({usersNotificationsSettings})
        if(response) closeModal()
    },

    updateNotificationsSettings:async()=>{
        let {usersNotificationsSetting,closeModal}=get()
        let {user}=frontDB.getState()
        let itemId=usersNotificationsSetting.id

        let usersNotificationsSettings=user.usersNotificationsSettings

        usersNotificationsSettings=arrayMethods.updateWholeElementById(
            usersNotificationsSettings,
            itemId,
            usersNotificationsSetting
        )


        let response = await api.user.updateProfile({usersNotificationsSettings})
        if(response) closeModal()

    },

    deleteNotificationsSettings:async(itemId)=>{
        
        let {user}=frontDB.getState()

        let usersNotificationsSettings=user.usersNotificationsSettings
        
        usersNotificationsSettings=arrayMethods.deleteElementById(usersNotificationsSettings,itemId)
        
        let response = await api.user.updateProfile({usersNotificationsSettings})
        console.log(response);

    },




    clear:()=>{
        set({
            mode:undefined,
            section:undefined,
            usersNotificationsSetting:{type:'sms',provider:'sms-fly',apiKey:undefined,active:true},

        })
        

    }
    
}))