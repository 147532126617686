import { useEffect } from "react";
import styles from "./LinksP.module.css";
import { useLinksP } from "./useLinksP";
import { PublicPageContainer, PublicPageHeader, PublicPageTitle } from "../../../../Components/BaseComponents/BaseComponents";
import { Photo } from "../../../../Components/PhotoUploader/PhotoUploader";
import { Link } from "../../../PagesTypes/Links/Links";

function LinksP() {

  let  {page,getPage}=useLinksP()


  useEffect(()=>{
    async function af () {
  
    getPage()

    }
    af()
    },[])
  



  return <>

  <PublicPageContainer>


<PublicPageHeader>

<Photo type={'backgroundPhoto' } url={page?.backgroundPhoto}/>
<Photo  url={page?.photo}/>

<div className={styles.name}>{page?.name}</div>



</PublicPageHeader>

<PublicPageTitle title='Посилання'/>

{page?.links.map(link=>(
<Link
  photo={link?.photo}
  name={link?.name}
  description={link?.description}
  url={link?.url}
  deleteIcon={0}
  />

))}



  </PublicPageContainer>
  
  
  </>;
}

export default LinksP;
