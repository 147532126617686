import { useNavigate, useParams } from "react-router-dom";
import { InputsContainer, PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import Header from "../../../Components/Header/Header";
import Switcher from "../../../Components/Switcher/Switcher";
import styles from "./Event.module.css";
import { useEvent } from "./useEvent";
import { Input } from "rsuite";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import { frontDB } from "../../../helpers/frontDB";
import { DatePicker } from 'rsuite'
import moment from "moment/moment";
import PhotoUploader from "../../../Components/PhotoUploader/PhotoUploader";

function Event() {

  let navigate=useNavigate()
  let {mode}=useParams()
  let {user}=frontDB()



  let {
    page,
    section,
    setSection,
    inputHandler,
    folderInputHandler,
    dateTimeInputHandler,

    addPage,
    updatePage,
    closeWindow,
    changePhoto

   }=useEvent()




let aboutSection = <>

<Input
  id="organizer"
  onChange={inputHandler}
  value={page?.organizer}
  placeholder="Організатор" 
/>

<Input
  id="name"
  onChange={inputHandler}
  value={page?.name}
  placeholder="Назва події" 
/>

<Input
  as="textarea" rows={3}
  id="about"
  onChange={inputHandler}
  value={page?.about}
  placeholder="Про подію" 
/>

<Input
  id="city"
  onChange={inputHandler}
  value={page?.city}
  placeholder="Місто" 
/>

<Input
  id="street"
  onChange={inputHandler}
  value={page?.street}
  placeholder="Адреса" 
/>


<div> Дата та Час</div>


<div className={styles.time_container}>

<input
  type="date"

  id="date"
  style={{ width: 300 }} 
  onChange={dateTimeInputHandler}
  value={moment(page?.date).format('yyyy-MM-DD')} 


/>


<input
  type="time"
  id="time"
  onChange={dateTimeInputHandler}
  value={page?.time}
  style={{ width: '200px' }}
  placeholder="Адреса" 
/>


</div>



</>

let photoSection =<>


 <PhotoUploader
   type='backgroundPhoto'
   onUploaded={(url)=>{changePhoto({type:'backgroundPhoto',url})}} 
   url={page?.backgroundPhoto} 
   />


</>

let contactsSection =<>

<Input
  id="phone"
  onChange={inputHandler}
  value={page?.phone}
  placeholder="Телефон" 
/>
<Input
  id="email"
  onChange={inputHandler}
  value={page?.email}
  placeholder="Емейл" 
/>
<Input
  id="website"
  onChange={inputHandler}
  value={page?.website}
  placeholder="Веб сайт" 
/>


</>


let othersSection = <>


<select
value={page?.folderId} 
id="folderId"  
placeholder={'Оберіть папку'}
onChange={folderInputHandler}>
{[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>

</>



  return <>

  <Header/>
    <PageContainer>

      <PageTitle title ={mode=='add'?'Нова сторінка (Подія)':'Редагувати сторінку (Подія)'}/>
      
      <Switcher
        links={['Про подію','Фото','Контакти','Інше']}
        section={section} 
        setSection={setSection}
    />
       <InputsContainer>

         {section=='Про подію'&& aboutSection}
         {section=='Фото'&& photoSection}
         {section=='Контакти'&& contactsSection}
         {section=='Інше'&& othersSection}


         
      </InputsContainer>



    <BottomButtons
    mode={mode}
    onCreate={()=>{addPage(navigate)}}
    onUpdate={()=>{updatePage(navigate)}}
    onCancel={()=>{closeWindow(navigate)}}
    />

    </PageContainer>

    
  
  </>;
}

export default Event;
