import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../Components/Header/Header";
import styles from "./GoodsCategory.module.css";
import BottomButtons from "../../../../../Components/BottomButtons/BottomButtons";
import { Input,Toggle } from "rsuite";
import { useGoodsCategory } from "./useGoodsCategory";
import { useGoodWindow } from "./Components/GoodWindow/useGoodWindow";
import GoodWindow from "./Components/GoodWindow/GoodWindow";
import WarningRoundIcon from "@rsuite/icons/WarningRound";
import { Photo } from "../../../../../Components/PhotoUploader/PhotoUploader";


function GoodsCategory() {
  let navigate = useNavigate();

  let { mode } = useParams();

  let {
    category,
    goods,
    inputHandler,
    closeWindow,
    deleteGood,
    addCategory,
    updateCategory,
    goodStatusHandler,
    goodTopHandler,
    
    
  } = useGoodsCategory();

  let { showAddGoodWindow, showEditGoodWindow } = useGoodWindow();



  





  return (
    <>
      <Header />

      <div className={styles.title}>
        {mode == "add" ? "Нова категорія " : "Редагувати категорію "}
      </div>

      <div className={styles.container}>
        <Input
          id="name"
          onChange={(v, e) => {
            inputHandler(v, e);
          }}
          value={category?.name}
          placeholder="Назва категорії"
        />

        <Input
          as="textarea"
          rows={3}
          id="about"
          onChange={(v, e) => {
            inputHandler(v, e);
          }}
          value={category?.about}
          placeholder="Опис"
        />

        <div className={styles.goods_title}> Товари </div>

        {goods.map((good) => (
          <Good
            photo={good?.photo}
            name={good.name}
            price={good.price}
            currency={good.currency}
            onEdit={() => {
              showEditGoodWindow(good.id);
            }}
            onDelete={() => {
              deleteGood(good.id);
            }}
            status={good?.active}
            statusHandler={(v)=>{goodStatusHandler(good.id,v)}}
            top={good?.top}
            topHandler={(v)=>{goodTopHandler(good.id,v)}}
          />
        ))}

        <div onClick={showAddGoodWindow} className={"link"}>
          {" "}
          + Додати товар
        </div>


      </div>

      <BottomButtons
        mode={mode}
        saveButtonName={"Зберегти категорію"}
        onCreate={() => {
          addCategory(navigate);
        }}
        onUpdate={() => {
          updateCategory(navigate);
        }}
        onCancel={() => {
          closeWindow(navigate);
        }}
      />

      <GoodWindow />
    </>
  );
}

export default GoodsCategory;

//! Допоміжні

function Good({ photo, name, price,currency, status, onDelete, onEdit,statusHandler,top,topHandler }) {
  
  
  return (
    <>
      <div className={styles.good_container}>
        <Photo url={photo} />
        <div onClick={onEdit} className={styles.good_name}>
          {name}
        </div>
        <div className={styles.price}>{price} {currency}</div>
        <span>
        Show: &nbsp;<Toggle size={'sm'} checked={status} onChange={statusHandler} />
        </span>

        <span>
        Top: &nbsp;<Toggle size={'sm'} checked={top} onChange={topHandler} />
        </span>

            <WarningRoundIcon onClick={onDelete} style={{ color: "red" }} />

      </div>
    </>
  );
}
