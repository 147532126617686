import {create} from 'zustand'
import { api } from './api'
import { usePersonal } from '../Pages/PagesTypes/Personal/usePersonal'
import { useRestaurant } from '../Pages/PagesTypes/Restaurant/useRestaurant'
import { useEvent } from '../Pages/PagesTypes/Event/useEvent'
import { useReview } from '../Pages/PagesTypes/Review/useReview'
import { useWifi } from '../Pages/PagesTypes/Wifi/useWifi'
import { useWeb } from '../Pages/PagesTypes/Web/useWeb'
import { useLocation } from '../Pages/PagesTypes/Location/useLocation'
import { useLinks } from '../Pages/PagesTypes/Links/useLinks'
import { useAction } from '../Pages/PagesTypes/Action/useAction'
import { useBusiness } from '../Pages/PagesTypes/Business/useBusiness'



export let usePageManager = create((set,get)=>({



deletePage:async(type,pageId)=>{

    let response = await api.user.deletePage(type,pageId)

},


showEditWindow:(type,pageId,navigate)=>{

    
    if(type=='personal'){
        usePersonal.getState().showPersonalPageEditData(pageId)
        navigate(`/pages/personal/edit`)
    }
    
    if(type=='restaurant'){
        useRestaurant.getState().showRestaurantPageEditData(pageId)
        navigate(`/pages/restaurant/edit`)
    }

    if(type=='event'){
        useEvent.getState().showEventPageEditData(pageId)
        navigate(`/pages/event/edit`)
    }

    if(type=='review'){
        useReview.getState().showReviewPageEditData(pageId)
        navigate(`/pages/review/edit`)
    }

    if(type=='wifi'){
        useWifi.getState().showWifiPageEditData(pageId)
        navigate(`/pages/wifi/edit`)
    }

    if(type=='webPage'){
        useWeb.getState().showWifiPageEditData(pageId)
        navigate(`/pages/web/edit`)
    }
    
    if(type=='location'){
        useLocation.getState().showLocationPageEditData(pageId)
        navigate(`/pages/location/edit`)
    }

    if(type=='links'){
        useLinks.getState().showLinksPageEditData(pageId)
        navigate(`/pages/links/edit`)
    }

    if(type=='action'){
        useAction.getState().showActionPageEditData(pageId)
        navigate(`/pages/action/edit`)
    }

    if(type=='business'){
        
        useBusiness.getState().showBusinessPageEditData(pageId)
        navigate(`/pages/business/edit`)
    }


},



}
    
))