import {create} from 'zustand'
import { api } from '../../../../helpers/api';
import { frontDB } from '../../../../helpers/frontDB';
import shortid from 'shortid';
import { showToast } from '../../../../Components/Toast/useToast';




export let useFolderModal = create((set,get)=>({

display:false,
mode:undefined,


showAddFolderModal:()=>{set({display:true,mode:'add'})},

showEditFolderModal:async(folderId)=>{

    set({display:true,mode:'edit'})
    let folder = await frontDB.getState().getFolderById(folderId) 
    set({folder})

},

hideModal:()=>{set({display:false});get().clear()},


folder:{id:undefined,name:''},

inputHandler:(v,e)=>{
    set({folder:{...get().folder,[e.target.id]:v}})
},

createFolder:async()=>{

    let response = folder.create(get().folder)
   
    if(response){
        get().hideModal()
    }  

},

updateFolder:async ()=>{

    let response = folder.update(get().folder)

    if(response){
        get().hideModal()
    } 

},


clear:()=>{set({
    display:false,
    mode:undefined,
    folder:{id:undefined,name:''}
})}


    
}))


//!Допоміжні

let folder ={

    create: async (folder)=>{
        if(folder.name==''){showToast('e','Введіть назву папки');return}

        let user = frontDB.getState().user
        let userFolders = user.folders;

        
        let sameNames = userFolders.filter(existingFolder=>(folder.name==existingFolder.name))
        if(sameNames.length>0){showToast('e','Назва вже існує. Оберіть іншу');return}



        folder.id=shortid.generate()


        let updatedUserFolders = [...userFolders,folder]

        user.folders=updatedUserFolders
        let response = await api.user.updateProfile(user)
        if(response){return true}
    },

    update:async(folder)=>{

        let {id:folderId,name:folderName} = folder
        let user = frontDB.getState().user
        let folders =user.folders
    
        let index = user.folders.findIndex(folder=>folderId==folder.id)
        folders[index].name=folderName
    
        user.folders=folders
    
        let response = await api.user.updateProfile(user)

        if(response) return true
    

    }



}