import { useEffect } from "react";
import { PageContainer, PageTitle } from "../../Components/BaseComponents/BaseComponents";
import Header from "../../Components/Header/Header";
import styles from "./OrdersManagement.module.css";
import { useOrdersManagement } from "./useOrdersManagement";
import { Order } from "../PagesTypes/Business/Business";
import moment from "moment";
import OrderDetailsModal from "./Components/OrderDetailsModal/OrderDetailsModal";
import { useOrderDetailsModal } from "./Components/OrderDetailsModal/useOrderDetailsModal";
import { useOrderDetailsRestaurantModal } from "./Components/OrderDetailsRestaurantModal/useOrderDetailsRestaurantModal";
import OrderDetailsRestaurantModal from "./Components/OrderDetailsRestaurantModal/OrderDetailsRestaurantModal";
import { getUserId } from "../../helpers/access";
import { frontDB } from "../../helpers/frontDB";
import Switcher from "../../Components/Switcher/Switcher";
import { ClientRequest } from "../PagesTypes/Restaurant/Restaurant";

function OrdersManagement() {

  
let {updateDB,user}=frontDB()
let {getPagesWithOrdersAndRequests,pagesWithOrdersAndRequests,section,setSection}=useOrdersManagement()
let {showOrderDetails}=useOrderDetailsModal()
let {showOrderDetailsRestaurant}=useOrderDetailsRestaurantModal()


useEffect(()=>{
  updateDB('user')
  getPagesWithOrdersAndRequests()
},[])




const ordersSection = <>
 {pagesWithOrdersAndRequests.map(page=>{

return <> 
<div>{page?.name}</div>  
{page.orders.map(order=>{


if(order.responsible == user?.email ) return (
  <Order
  clientName={order?.info.delivery}
  createdAt={moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}
  onClick={()=>{

    page.type == 'business' ?
    showOrderDetails(order.id,page)
    :
    showOrderDetailsRestaurant(order.id,page)
  
  }}
  status={order?.status}
  paid={order?.paid ? 'Так':'Ні'}
/>)

})

}


{page.type=='business' ? <OrderDetailsModal pageType={page.type} pageId={page?._id}/>:<OrderDetailsRestaurantModal pageType={page.type} pageId={page?._id}/>}

</>

})}
</>

const requestsSection = <>
  {pagesWithOrdersAndRequests.map(page=>{
  return <>

  <div>{page?.name}</div>

  {page?.requests?.length && page.requests.map(request=>{
//Filter by user email
    if(request.responsibleEmail==user?.email) return <>
    
    <ClientRequest date={request.createdAt} msg={request.message}/>

    </>

  }
  
  
  )}
  
  </>  

})}
</>


  return <>
  <Header role={'orderManager'}/>
  <PageContainer>

  <Switcher
    links={[
     'Замовлення',
     'Запити клієнтів'
    ]}
    section={section} 
    setSection={setSection}
    />


{section=='Замовлення' && ordersSection}
{section=='Запити клієнтів' && requestsSection}





  </PageContainer>

  
  
  
  </>;
}

export default OrdersManagement;
