import axios from "axios";
import { showToast } from "../Components/Toast/useToast";
import { getUserId } from "./access";
import { frontDB } from "./frontDB";
import Cookies from "js-cookie"

let {updateDB}=frontDB.getState()


let a = axios.create({
   baseURL: process.env.REACT_APP_API,
   withCredentials:true
});

a.interceptors.response.use(undefined, err=>{

   let error=err.response.data.error
   let details=err.response.data.details

   // if(error=='account blocked') showToast('e','Аккаунт заблокований')
   // if(error=='Forbidden') showToast('e','Сторінка не може бути видалена. Зверніться до власника сторінки')
 
   console.log({error,details});
   showToast('e',`${error} | ${details}`)
})








export let api = {

      
 
     user:{

         signUp:async (form)=>{

            let {email,password,repeatPassword}=form
            if(isEmpty(email) || isEmpty(password) || isEmpty(repeatPassword)){showToast('e','Поля не повинні бути пустими');return}

            let {data} = await a.post(`/api/users/signup`,form)
            if(!responseHandler(data)){return}  

            if(data=='Account already exists'){showToast('e','Account already exists')}
            if(data=='Passwords do not match'){showToast('e','Passwords do not match')}
            if(data=='error'){showToast('e','Error')}
            if(data=='success'){return true}


         },

         login:async (form)=>{
            
      try {
            let {email,password}=form
            
            if(isEmpty(email) || isEmpty(password)){showToast('e','Поля не повинні бути пустими');return}

            let {data} = await a.post(`/api/users/login`,form)
            if(!responseHandler(data)){return}  

            


            if(data.msg=='Email or password is incorrect'){ showToast('e','Email or password is incorrect')}
            if(data.msg=='error'){showToast('e','Error')}
            if(data.msg=='Successful authorization'){return true}
      }catch(err){
         console.log(err);
      }

         },

         googleAuth: async(gtoken)=>{

            let {data}= await a.post(`/api/google-auth`,{gtoken})
            if(!responseHandler(data)){return}  

            if(!Cookies.get('uToken')){showToast('e','Помилка Google авторизації');return}
            if(data=='Successful authorization'){window.location.href='/pages'}
            if(data!=='Successful authorization'){showToast('e','Помилка Google авторизації')}

         },

         changePassword:async(newPassword)=>{

         let {status} = await a.put(`/api/users/change-password`,{userId:getUserId(),password:newPassword})
         
         if (status==200){showToast('s','Пароль успішно змінено');return true}
         if (status!==200){showToast('e','Помилка зміни паролю'); return false}


         },

         deleteAccount:async()=>{
            let {status} = await a.delete(`/api/users/${getUserId()}`)
            console.log(status);
            if (status==200){return true}
            if (status!==200){return false}
   
         },

         getProfile: async ()=>{

            let {data}= await a.get(`/api/users/${getUserId()}`)
            if(!responseHandler(data)){return}  

            return data

         },
         
         updateProfile:async(user)=>{
           
            let {data}=await a.put(`/api/users/${getUserId()}`,user)
            
            if(data){
               updateDB('user')
               return true
            
            }
         },


         addPage:async (page)=>{

            if (!addPageDV(page)) return;

            page.userId = getUserId()

            let {data}= await a.post(`/api/pages`,page)

            if(!responseHandler(data)){return}  

            if  (data){
               updateDB('pages')
               showToast('s','Сторінку додано');
               return true}
         },

         getPageById:async (type,pageId)=>{

            let {data} = await a.get(`/api/pages/${type}/${pageId}`)
            return data
         },
    

         getPages:async ()=>{
            
            let {data}= await a.get(`/api/users/${getUserId()}/pages`)
            if(!responseHandler(data)){return}  

            if  (data) return data
            },

         deletePage: async (type,pageId)=>{ 
               try {
               let {data}= await a.delete(`/api/users/${getUserId()}/pages/${type}/${pageId}`)
               if(!responseHandler(data)){return}  

               if  (data){

                  updateDB('pages')
                  showToast('s','Сторінку видалено')
                  return true}
            }catch(err){
            return false
            }
            },

         updatePage: async (page)=>{ 
               try {
               if(!addPageDV(page)) return;


               let {data}= await a.put(`/api/users/${getUserId()}/pages/${page._id}`,page)
               if(!responseHandler(data)){return}  

               if  (data){

                  updateDB('pages')
                  showToast('s','Оновлено')
                  return true}
            }catch(err){
               return false
            }
         }, 
            
   
         getPagesWithOrdersAndRequests: async()=>{
            try {
               let response = await a.get(`/api/users/${getUserId()}/orders`)
               return response.data
            }catch(error) {
               return false
            }
         },


         changeOrderStatus:async (orderId,pageType,pageId,status)=>{
         try {

         let response = await a.put(`/api/orders/${orderId}/status`,{pageType,pageId,status}) 
         updateDB('pages') 
         return response.data

         }catch(err){
            return false
         }
         },

         changeOrderPaymentStatus:async (orderId,pageType,pageId,status)=>{
            try {
   
            let response = await a.put(`/api/orders/${orderId}/payment/status`,{pageType,pageId,status}) 
            updateDB('pages') 
            return response.data
   
            }catch(err){
               return false
            }
            },

         getTransactions:async ()=>{
            try {
            let resposne = await a.get(`/api/transactions/${getUserId()}`)
             return resposne.data

             }catch(err){
               return false
             }
         },
         
         
         // Uploads
         
         uploadImage:async(body)=>{
          let {data}=await a.post('/upload',body)
          return data
         },                 

         uploadFiles:async (formData)=>{

            try {
               let response = await a.post(`/api/upload`,formData)
               return response.data
            }catch(err){
               console.log(err);
               return false
            }
            
         },

         uploadResume:async(body)=>{
            showToast('l','Зберігаємо...')
            let {data}=await a.post('/upload/resume',body)
            return data
         },

         deleteResume:async(url)=>{
         let {data}=await a.post('/resume',{url})
         return data
         },

         access:{

            async create (access){

               try {
                  
                  access.ownerId=getUserId()

                  let {data}=await a.post('/api/accesses',access)
                  updateDB('accesses')
                  return data

               }catch(err){
                  console.log(err);
                  return false
               }

            },

            async updateById (accessId,access){

               try {
                  

                  let {data}=await a.put(`/api/accesses/${accessId}`,access)
                  updateDB('accesses')
                  return data

               }catch(err){
                  console.log(err);
                  return false
               }

            },

            async deleteById (accessId){

               try {
                  

                  let {data}=await a.delete(`/api/accesses/${accessId}`)
                  updateDB('accesses')

                  return data

               }catch(err){
                  console.log(err);
                  return false
               }

            },

            async getAllByUserId (){

               try {
                  

                  let {data}=await a.get(`/api/users/${getUserId()}/accesses`)
                  
                  return data

               }catch(err){
                  console.log(err);
                  return false
               }

            },

            async changeOrderManagerStatus (accessId,body){

               try {
                  
               let {data}=await a.put(`/api/accesses/${accessId}/orderManager/status`,body)
               updateDB('accesses')

               return data

                  }catch(err){
                     return false
                  }
            },

            async changeAccessStatus (accessId,body){

               try {
                  
               let {data}=await a.put(`/api/accesses/${accessId}/changeStatus`,body)
               updateDB('accesses')

               return data

                  }catch(err){
                     return false
                  }
            }




         }
         

     },

     getPageByLogin:async(login)=>{

      let {data} =await a.get(`/api/pages/${login}`)

      if(data=='доступ закритий'){window.location.href='/unavaliable'}


      return data


     },


     

     downloadVCard:async (type,pageId)=>{

      let response = await a.get(`/api/vcards/${type}/${pageId}`,{responseType: 'blob', })

   
      const url2 = window.URL.createObjectURL(response.data);
      
      const link = document.createElement('a');
      link.href = url2;
      link.download = 'contact.vcf';
      link.click();


      window.URL.revokeObjectURL(process.env.REACT_APP_API);

      return response.data


     },

     getVCard:async (type,pageId)=>{

     let {data} = await a.get(`/api/vcards/${type}/${pageId}`,{responseType: 'blob', })
     
     const blobString = await convertBlobToString(data);
     return blobString


     },

    


     sendReview:async(pageId,review)=>{
     if(review.author==''){showToast('e','Заповніть данні'); return false}
      let {data}=await a.post(`/api/pages/${pageId}/reviews`,review)

      if(data=='you left review'){showToast('e','Ви вже залишали оцінку');return false}
      return data
     },

     addEventToGoogleCalendar: async(msg)=>{

      let {data}= await a.post(`/add-event-to-google`,msg)
      return data
     },

    

    addFeedbackOnPage:async(pageType,pageId,feedback)=>{

      if(!feedback.name){showToast('e','Введіть Ваше імя');return false}
      if(!feedback.phone && !feedback.email ){showToast('e','Вкажіть контактні данні');return false}
      if(!feedback.message){showToast('e','Введіть повідомлення');return false}


      let {data}=await a.post(`/api/pages/${pageType}/${pageId}/feedbacks`,feedback)
      if(data){
         showToast('s','Повідомлення відправлено')
         return data
      }
    },

    createAppointmentOnPage:async(pageType,pageId,appointmetnt)=>{

      if(!appointmetnt.name){showToast('e','Введіть Ваше імя');return false}
      if(!appointmetnt.phone ){showToast('e','Введіть Ваш номер телефону');return false}


      let {data}=await a.post(`/api/pages/${pageType}/${pageId}/appointments`,appointmetnt)
      if(data){
         showToast('s','Запис успішно створено')
         return data
      }
    },

    getPageSettings:async (userId)=>{
    try {
         let response = await a.get(`/api/pages/settings/user/${userId}`)
         return response.data
    }catch(error) {
         console.log(error)
         return false
    }
    },


    createOrderOnPage: async(pageType,pageId,body)=>{

      try {
         let response = await a.post(`/api/pages/${pageType}/${pageId}/orders`,body)
         if(response.data=='invalid promocode') {showToast('Invalid promocode');return false}
         
         return response.data
    }catch(error) {
         console.log(error)
         return false
    }



    },

    checkPromocode:async(pageType,pageId,promocode)=>{
      try {

         if(!promocode){showToast('e','Введіть промокод');return}

         let response = await a.post(`/api/pages/${pageType}/${pageId}/promocodes/check`,{promocode})
        
         
         if(response.data=='Промокод не знайдено'){
            showToast('e','Промокод не знайдено')
            return false
         }
         if(response.data=='you already used this promocode'){
            showToast('e','Ви вже використали цей промокод')
            return false 
         }
         
         return response.data
      }catch(error) {
         console.log(error)
         return false
      }
 
    },
    
    blockUser:async(userIp)=>{

      try {
         let response = await a.post(`/api/pages/blockedusers`,{userId:getUserId(),userIp})
         updateDB('user')
         return response.data

      }catch(error) {
         console.log(error)
         return false
      }

    },

    unblockUser:async(userIp)=>{
      
      try {
         let response = await a.post(`/api/pages/blockedusers/unblock`,{userId:getUserId(),userIp})
         updateDB('user')

         return response.data

      }catch(error) {
         console.log(error)
         return false
      }

    },


   stats:{

     getTotalScans:async()=>{

      let {data}=await a.get(`/users/${getUserId()}/stats/total-scans`)
      return data
     },


     


   },


    admin:{

      getAccounts:async()=>{
         let {data}= await a.get(`/accounts`)
         return data
      },

      getUserPages:async (userId)=>{
            
         let {data}= await a.get(`/api/users/${userId}/pages`)
         if(!responseHandler(data)){return}  

         if  (data){
            return data}
         },

      changeAccountStatus:async(userId,newStatus)=>{
         try {

            let {data}= await a.post(`/accounts/${userId}/status`,{newStatus})
            return data

         }catch(err){
            console.log(err);
            return false
         }
      }
      
    },

    getClientOrders:async(pageId,orders)=>{
      try {

         let response= await a.post(`/api/restaurant/clientOrders`,{pageId,orders})
         return response.data

      }catch(err){
         console.log(err);
         return false
      }
   },

   getClientOrdersForBusinessPage:async(pageId,orders)=>{
      
      try {
         let response= await a.post(`/api/business/clientOrders`,{pageId,orders})
         return response.data

      }catch(err){
         console.log(err);
         return false
      }
   },

   getClientOrdersForPersonalPage:async(pageId,orders)=>{
      
      try {
         let response= await a.post(`/api/personal/clientOrders`,{pageId,orders})
         return response.data

      }catch(err){
         console.log(err);
         return false
      }
   },

   createRequest:async(pageId,message,responsibleEmail)=>{
      try {

         let response= await a.post(`/api/pages/${pageId}/requests`,{message,responsibleEmail})
         return response.data

      }catch(err){
         console.log(err);
         return false
      }
   },

   getPageTransactions:async(pageType,pageId)=>{

      try {

         let response= await a.get(`/api/pages/${pageType}/${pageId}/transactions`)
         return response.data

      }catch(err){
         console.log(err);
         return false
      }
   },


 }
 // Допоміжні

 const isEmpty = (value) => {
    if(value==undefined || value==''){return true}
 };

 const convertBlobToString = (blob) => {
   return new Promise((resolve, reject) => {
     const reader = new FileReader();
 
     reader.onloadend = () => {
       const text = reader.result;
       resolve(text);
     };
 
     reader.onerror = () => {
       reject(new Error('Error converting Blob to string'));
     };
 
     reader.readAsText(blob);
   });
 };



function responseHandler(data){
   if(data=='access denied'){window.location.href='/login';return false}
   return true 
};


 function addPageDV(page){
   if(!page.name&&page.type=='personal'){showToast('e','Вкажіть ПІБ');return false}
   if(!page.name&&page.type=='restaurant'){showToast('e','Вкажіть назву ресторану');return false}
   if(!page.name&&page.type=='business'){showToast('e','Вкажіть назву ');return false}

   return true
 };