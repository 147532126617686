import {create} from 'zustand'



export let useCreatePageModal = create((set,get)=>({

    display:false,

    showCreatePageModal:()=>{set({display:true})},
    hideModal:()=>{set({display:false});get().clear()},

    pageType:undefined,
    
    inputHandler:(v)=>{
       set({pageType:v})
    },
    
    next:async (navigate)=>{

        
        let {pageType,hideModal}= await get()
        

        if(pageType=='personal'){navigate('/pages/personal/add')}
        if(pageType=='restaurant'){navigate('/pages/restaurant/add')}
        if(pageType=='event'){navigate('/pages/event/add')}
        if(pageType=='review'){navigate('/pages/review/add')}
        if(pageType=='wifi'){navigate('/pages/wifi/add')}
        if(pageType=='web'){navigate('/pages/web/add')}
        if(pageType=='location'){navigate('/pages/location/add')}
        if(pageType=='links'){navigate('/pages/links/add')}
        if(pageType=='action'){navigate('/pages/action/add')}
        if(pageType=='business'){navigate('/pages/business/add')}


        hideModal()

    },



    clear:()=>{set({pageType:undefined})}
    
}))