import {create} from 'zustand'
import { OrdersService } from '../../../../../../Services/Orders.services'
import { api } from '../../../../../../helpers/api'
import { showToast } from '../../../../../../Components/Toast/useToast'


export let useOrdersModal = create((set,get)=>({

    display:false,
    clientOrders:[],


    showOrdersModal:(pageId)=>{
        get().getOrders(pageId)
        set({display:true})
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    getOrders:async (pageId)=>{

        const orders = OrdersService.getOrders()
        const clientOrders = await api.getClientOrders(pageId,orders) 
       
        if(clientOrders){
            set({clientOrders})
        }
    },

    createRequest:async(pageId,message)=>{
        const {clientOrders}=get()
        if(!clientOrders.length) return showToast('e','У Вас немає активних замовленнь')
        
        const responsibleEmail =clientOrders[0].responsible
        const response = await api.createRequest(pageId,message,responsibleEmail)
        if(response===true) return showToast('s','Запит надіслано')
    },


    clear:()=>{
        set({
        })
        

    }
    
}))