import { Button, Input } from "rsuite";
import { ModalWindow } from "../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./LinksModal.module.css";
import { useLinksModal } from "./useLinksModal";
import PhotoUploader from "../../../../../Components/PhotoUploader/PhotoUploader";

function LinksModal() {

let {
  display,
  mode,
  link,
  onPhotoChange,
  inputHandler,
  addLink,
  updateLink,
  closeLinkModal,
  

}=useLinksModal()





if(display) return <>

<ModalWindow title={mode=='add'?'Додати посилання':'Редагувати посилання'}>

<div className={styles.photo_container}>

<PhotoUploader 
  type={'photo'}
  url={link?.photo}
  onUploaded={onPhotoChange}
/>

</div>



<Input
id="name"
onChange={inputHandler}
value={link?.name}
placeholder="Ім'я" 
/>


<Input
as='textarea'
rows={3}
id="description"
onChange={inputHandler}
value={link?.description}
placeholder="Опис" 
/>

<Input
id="url"
onChange={inputHandler}
value={link?.url}
placeholder="URL" 
/>




<Button
onClick={mode=='add'?addLink:updateLink}  
color="blue"
appearance="primary"

>Зберегти</Button>


<Button 
onClick={closeLinkModal}
color="blue" 
appearance="ghost"

>Закрити</Button>

  </ModalWindow>
  </>
}

export default LinksModal;
