import { useParams } from 'react-router-dom'
import {create} from 'zustand'
import { useBusiness } from '../../useBusiness'
import shortid from 'shortid'
import { showToast } from '../../../../../Components/Toast/useToast'



export let useButtonModal = create((set,get)=>({

display:false,
mode:undefined,

showAddButtonModal:()=>{
    
    set({display:true,mode:'add'})

},

showEditButtonModal:(buttonId)=>{

    let {buttons}=useBusiness.getState().page
    let button=buttons.find(button=>(buttonId==button.id))

    set({button,display:true,mode:'edit'})

},

closeModal:()=>{
    set({display:false})
    get().clear()
},


button:{id:'',photo:'',name:'',url:'https://'},


inputHandler:(v,e)=>{
    set({button:{...get().button,[e.target.id]:v}})
},

changePhoto:({type,url})=>{
    
    if(type=='photo'){set({button:{...get().button,photo:url}})}
    
},


addButton:()=>{
    
    let {button,closeModal}=get()
    let {buttons}=useBusiness.getState().page

    if(!button.name){showToast('e','Введіть назву кнопки'); return}


    button.id=shortid.generate()
    buttons=[...buttons,button]

    useBusiness.setState(state=>(
        state.page.buttons=buttons       
    ))

closeModal()
},


updateButton:()=>{

    let {button,closeModal}=get()
    let {buttons}=useBusiness.getState().page
    let buttonId=button.id

    if(!button.name){showToast('e','Введіть назву кнопки'); return}


    let buttonIndex = buttons.findIndex(button=>(buttonId==button.id))
    buttons[buttonIndex]=button
    useBusiness.setState(state=>(
        state.page.buttons=buttons       
    ))

    closeModal()

},


deleteButton:(buttonId)=>{

    let {buttons}=useBusiness.getState().page
    
    let buttonIndex = buttons.findIndex(button=>(buttonId==button.id))
    buttons.splice(buttonIndex,1)
    useBusiness.setState(state=>(
        state.page.buttons=buttons       
    ))

},

clear:()=>{
    set({
        button:{id:'',photo:'',name:'',url:'https://'}
  
    })
}

    
}))