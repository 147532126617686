import {create} from 'zustand'
import { api } from '../../../helpers/api'



export let useAdminDashboard = create((set,get)=>({


    accounts:[],

    getAccounts:async()=>{
    
        let accounts = await api.admin.getAccounts()
        if(accounts){
            set({accounts})
        }
    
    },
    
    statusHandler:async(accountId,active)=>{

        if(active){
            let response = await api.admin.changeAccountStatus(accountId,false)
        }
        if(!active){
            let response = await api.admin.changeAccountStatus(accountId,true)
        }

        get().getAccounts()
    },


    
}))