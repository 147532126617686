import {create} from 'zustand'
import { api } from '../../../../helpers/api'
import { frontDB } from '../../../../helpers/frontDB';
import shortId from 'shortid'
import { usePhotoUploader } from '../../../../Components/PhotoUploader/usePhotoUploader';


export let usePageWindow = create((set,get)=>({

display:false,
state:undefined,
pageType:undefined,
mode:undefined,
pageCategory:undefined,

page:{
    name:'',
    phone:'',
    email:'',
    country:'',
    city:'',
    website:'https://www.',
    company:'',
    proffesion:'',
    about:'',
},



showAddNew:()=>{set({display:true,mode:'add',state:'serviceType'})},

showEditWindow:(pageId)=>{
    let page = frontDB.getState().getPageById(pageId)
    
    set({
    
        mode:'edit',
        display:true,
        state:page.type,
        pageType:page.type,
        pageCategory:page.type=='Візитка'?"Про мене":'Про заклад',
        page:page,
        services:page?.services,
        socialNetworks:page?.socialNetworks,
        schedule:page?.schedule,
        menu:page?.menu
    
    })
    
},

hide:()=>{
    set({
        mode:undefined,
        display:false,
        pageType:undefined,
        services:[],
        socialNetworks:{facebook:'',whatsapp:'',instagram:'',youtube:''},
        pageCategory:undefined,
        state:undefined,
        page:{
            name:'',
            phone:'',
            email:'',
            country:'',
            city:'',
            website:'https://www.',
            company:'',
            proffesion:'',
            about:'',
        },
        dish:{name:undefined,about:undefined,price:undefined},
        dishWindowState:undefined,
        menu:[],
        schedule:{
            monday: {from:'',to:''},
            tuesday: {from:'',to:''},
            wednesday: {from:'',to:''},
            thursday: {from:'',to:''},
            friday: {from:'',to:''},
            saturday: {from:'',to:''},
            sunday: {from:'',to:''},
        
            },
    })
usePhotoUploader.getState().clearPhoto()
},


setPageType:(pageType)=>{
    set({pageType})

},

next:()=>{
    if(get().pageType=='Візитка'){set({state:'Візитка', pageCategory:'Про мене'})}
    if(get().pageType=='Ресторан'){set({state:'Ресторан',pageCategory:'Про заклад'})}  
},


setState:(state)=>{
    set({state})
},

setPageCategory:(e)=>{ set({pageCategory:e.target.id})},




inputHandler:(v,e)=>{
    set({page:{...get().page,[e.target.id]:v}})
},




addPage: async ()=>{

    let page = get().page
    page.socialNetworks=get().socialNetworks
    page.type=get().pageType
    page.photo =usePhotoUploader.getState().photo
        

    if(get().pageType=='Візитка'){
        page.services=get().services
    }


    if(get().pageType=='Ресторан'){
        page.schedule=get().schedule
        page.menu=get().menu
    }

    let response = await api.user.addPage(page)
    if(response){get().hide()}


},

updatePage:async()=>{

    let page = get().page
    page.socialNetworks=get().socialNetworks
    page.photo =usePhotoUploader.getState().photo
    
    
    if(get().pageType=='Візитка'){page.services=get().services}
    if(get().pageType=='Ресторан'){

        page.schedule=get().schedule
        page.menu=get().menu
    
    }
        
    let response = await api.user.updatePage(get().page)
    if(response){get().hide()}
        
},

deletePage:async(pageId)=>{


    let response = await api.user.deletePage(pageId)
},


//Соц мережі
socialNetworks:{facebook:'',whatsapp:'',instagram:'',youtube:''},

socialNetworksInputHandler:(v,e)=>{

    set({socialNetworks:{...get().socialNetworks,[e.target.id]:v}})
    
},


//Графік

schedule:{
    monday: {from:'',to:''},
    tuesday: {from:'',to:''},
    wednesday: {from:'',to:''},
    thursday: {from:'',to:''},
    friday: {from:'',to:''},
    saturday: {from:'',to:''},
    sunday: {from:'',to:''},
  },

  scheduleInputHandler:(v,e,day)=>{

    let updatedSchedule = get().schedule;
    updatedSchedule[day][e.target.id] = v;
    set({ schedule: updatedSchedule });
},

//Послуги

services:[],


serviceInputHandler:(v,e,i)=>{
    
    let updatedServices = [...get().services];
    updatedServices[i][e.target.id] = v;
    set({ services: updatedServices });
},

addService:()=>{
    set({services:[...get().services,{name:'',price:''}]})
},

deleteService:(i)=>{

    let services =get().services
    services.splice(i,1)
    set({services})

},


//Menu

dishWindowState:undefined,

showAddDish:()=>{
    set({
        dishWindowState:'add',
        pageCategory:'Додати страву',
        dish:{name:undefined,about:undefined,price:undefined},
    })
},

showEditDish:(id)=>{
    let dishes = get().menu.filter(dish=>(dish.id==id))
    set({
        dish:dishes[0],
        pageCategory:'Додати страву',
        dishWindowState:'edit',
    })
    
},

backToMenu:()=>{
    set({pageCategory:'Меню',dish:{name:undefined,about:undefined,price:undefined}})
},

menu:[],

dish:{name:undefined,about:undefined,price:undefined},

dishInputHandler:(v,e)=>{

    set({dish:{...get().dish,[e.target.id]:v}})   
},



addDish:()=>{

    let dish =get().dish
    dish.id =shortId.generate()
    set({menu:[...get().menu,dish],pageCategory:'Меню'})

},

updateDish:()=>{

    let menu = get().menu
    let [dish] = menu.filter(dish=>(dish.id==get().dish.id))
    let i = menu.indexOf(dish)
    menu[i]=get().dish
    set({menu,pageCategory:'Меню'})

},

deleteDish:(id)=>{
    let menu =get().menu
    menu = menu.filter(dish=>(dish.id!=id))
    set({menu})
},






}))