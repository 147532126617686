import { ModalWindow } from "../../Pages/UserPages/Components/PageWindow/PageWindow";
import styles from "./ConfirmationModal.module.css";
import { Button } from "rsuite";


function ConfirmationModal({text,onConfirm,onCancel}) {


   return <>

  <ModalWindow >

 <div className={styles.confirmation_text}>{text}</div>

  <Button
    onClick={onConfirm}  
    color="blue"
    appearance="primary"

  >Підтвердити</Button>

<Button 
   onClick={onCancel}
   color="blue" 
   appearance="ghost"

   >Відмінити</Button>

  </ModalWindow>
  </>;
}

export default ConfirmationModal;
