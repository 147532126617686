import { useEffect } from "react";
import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./OrdersModal.module.css";
import { useOrdersModal } from "./useOrdersModal";
import { Button } from "rsuite";
import { calculateTotalByClientOrdersPersonal } from "../../../../../../helperFunctions";

function OrdersModal({pageId}) {



  let { 
    display,
    closeModal, 
   
    clientOrders,
  } =useOrdersModal();




  const ordersTotal = calculateTotalByClientOrdersPersonal(clientOrders)



  if (display) return (
      <>

        <ModalWindow title={'Мої замовлення'}>




        {clientOrders.length && clientOrders.map(order=>(<>

        <MyOrder
        orderId={order.id}
        status={order.status}
        basket={order.basket}
        />
        
        
        </>))}


          {/* <strong>Чайові: 0 грн</strong> */}
          <strong>До сплати: {ordersTotal} грн</strong>




          {/* {PayButton} */}

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
      </>
    );
}

export default OrdersModal;




const MyOrder =({orderId,status,basket})=>{


return <>

<div className={styles.myorder_container}>
    <div>Номер замовлення: {orderId}</div>
    <div>Статус:<strong>{status}</strong> </div>
{basket && basket.map(item=>(<>
<Item 
name={item.name || item.itemInfo.name}
qty={item.quantity || 1}
price={item.price || item.itemInfo.price}
/>
</>))}

</div>

</>

}


const Item =({name,qty,price})=>{
  return <>
  <div className={styles.item_container}>
    <div>{name} x{qty} </div>
    <div>{price} грн </div>

  </div>
  
  </>
}