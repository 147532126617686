import { useEffect } from "react";
import { PageContainer, PageTitle } from "../../Components/BaseComponents/BaseComponents";
import Header from "../../Components/Header/Header";
import styles from "./Stats.module.css";
import { useStats } from "./useStats";
import { frontDB } from "../../helpers/frontDB";
import Switcher from "../../Components/Switcher/Switcher";


function Stats() {
  let{updateDB,pages}=frontDB()

let{
  totalScans,
  getTotalScans,
  section,
  setSection,


}=useStats()


useEffect(()=>{
async function af () {
  updateDB('pages')
  getTotalScans()
}
af()
},[])


let regionStats =<>

<div>Загальна к-сть скануваннь: <strong>{totalScans}</strong></div>

<div>Регіони скануваннь:</div>
{pages.map(page=>(
  page.scans.map(data=>(
    <div>- {data.regionName}</div>
  ))
))}


</>


  return <>
  <Header/>
      <PageContainer>
      <PageTitle title={'Статистика'}/>

        <Switcher
        links={[
        'Регіони',
        ]}
        section={section} 
        setSection={setSection}
        />
        

        {section=='Регіони' &&  regionStats}



      </PageContainer>

  
  </>;
}

export default Stats;
