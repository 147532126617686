import {create} from 'zustand'
import { frontDB } from '../../../../helpers/frontDB'
import { api } from '../../../../helpers/api'



export let usePage = create((set,get)=>({

    changePageStatus:async (checked,pageId)=>{
    
        let page = frontDB.getState().getPageById(pageId)
        if(!page) return

        page.active=checked
        let response = api.user.updatePage(page)
    },




    
}))