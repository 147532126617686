import {create} from 'zustand'
import { useBusiness } from '../../useBusiness'
import shortid from 'shortid'
import { frontDB } from '../../../../../helpers/frontDB'
import { showToast } from '../../../../../Components/Toast/useToast'
import { api } from '../../../../../helpers/api'
import Papa from 'papaparse';



export let useGoodsCategory = create((set,get)=>({


    category:{name:'',about:''},
    
    showAddCategoryWindow:(navigate)=>{
        get().clear()
        navigate(`/pages/business/catalog/category/add`)
    },

    showCategoryEditWindow:(navigate,categoryId)=>{
    
        
        let {catalog}=useBusiness.getState()
        let category = catalog.find(category=>(categoryId==category.id))
        let {id,name,about,goods}=category

        set({
            category:{...get.category,id,name,about},
            goods
        })

        navigate(`/pages/business/catalog/category/edit`)
    },

    closeWindow:async(navigate)=>{
        


        get().clear()
        navigate(-1)
    },


    inputHandler:(v,e)=>{
        set({category:{...get().category,[e.target.id]:v}})
    },

    

    addCategory:(navigate)=>{
        let {category,goods}=get() 

        if(category.name==''){showToast('e','Введіть назву категорії');return}

        category.id=shortid.generate()  
        category.goods = goods 
        let {catalog}=useBusiness.getState()    
        useBusiness.setState({catalog:[...catalog,category]})
        get().clear()
        navigate(-1)
    },

    updateCategory:(navigate)=>{

        let {category,goods}=get()
        let {id,name,about}=category
        let {catalog} = useBusiness.getState()
        let index = catalog.findIndex(item=>(category.id==item.id))
        catalog[index]={id,name,about,goods}
        useBusiness.setState({catalog})
        navigate(-1)

    },

    handleFileUpload : (event) => {


        let {catalog} = useBusiness.getState()
    
        const file = event.target.files[0];
    
        Papa.parse(file, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
          let goods = result.data.map(item=>({...item,id:shortid.generate(),active:true}))
    
         

          for(let i=0;i<goods.length;i++){

            let checkCSVBusiness = (item)=>{
                try {

                let {category,name,about,url,price,photo}=item

                if(!category){
                    throw new Error(`Missing category in row ${i+1}`)
                }
                if(!name){
                    throw new Error(`Missing item name in row ${i+1}`)
                }
                if(!price){
                    throw new Error(`Missing item price in row ${i+1}`)
                }

                }catch(err){
                    showToast('e',err.message)
                    return true
                }
            }

            let errors = checkCSVBusiness(goods[i])
            if(errors) return
            
            goods[i].price = +goods[i].price

            let categoryName = goods[i].category
            
            let indexOfCatalog = catalog.findIndex(category=>category.name==categoryName)
           
            if(indexOfCatalog == -1){
                catalog.push({id:shortid.generate(), name:categoryName,about:'',goods:[goods[i]]})
            }else {
                catalog[indexOfCatalog].goods.push(goods[i])
            }
            
          }
          useBusiness.setState({catalog})

    
          showToast('s','Завантаження даних закінчено')
          },
          error: (error) => {
            console.error('CSV parsing error:', error.message);
            showToast('e','Помилка завантаження')
          },
        });


      
    },
    

    
    //Страви
    goods:[],
    
    deleteGood:(goodId)=>{

       let {goods}=get() 
       let index = goods.findIndex(good=>(goodId==good.id))
       goods.splice(index,1)
       set({goods})
    },


    goodStatusHandler:(goodId,value)=>{
        let {goods}=get()
        let index = goods.findIndex(good=>(goodId==good.id))
        goods[index].active=value
        set({goods})

    },

    goodTopHandler:(goodId,value)=>{
        let {goods}=get()
        let index = goods.findIndex(good=>(goodId==good.id))
        goods[index].top=value
        set({goods})

    },

    clear:()=>{set({category:{name:'',about:''},goods:[]})}
    
}))