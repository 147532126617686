import { useEffect } from "react";
import Header from "../../Components/Header/Header";
import { frontDB } from "../../helpers/frontDB";
import styles from "./UserPages.module.css";
import PageWindow from "./Components/PageWindow/PageWindow";
import Page from "./Components/Page/Page";
import { useFolderModal } from "./Components/FolderModal/useFolderModal";
import FolderModal from "./Components/FolderModal/FolderModal";
import Folders from "./Components/Folders/Folders";
import { useCreatePageModal } from "./Components/CreatePageModal/useCreatePageModal";
import CreatePageModal from "./Components/CreatePageModal/CreatePageModal";
import { usePageManager } from "../../helpers/pageManager";
import { useNavigate } from "react-router-dom";
import { Input } from "rsuite";
import { useUserPages } from "./useUserPages";
import TimeToPay from "./Components/TimeToPay/TimeToPay";
import moment from "moment";


function UserPages() {

let navigate = useNavigate()


let {updateDB,pages,user}=frontDB()

let {showAddFolderModal}=useFolderModal()

let {showCreatePageModal}=useCreatePageModal()
let {showEditWindow,deletePage}=usePageManager()
let {searchHandler,name,filter}=useUserPages()

const planExpiresIn =user?.plan?.expiresIn





useEffect(()=>{

  
async function af () {

  await updateDB('*')
}
af()
},[name])



const pagesSection = <>

<div className={styles.pages_container}>

  <Input
      id="name"
      onChange={searchHandler}
      value={name}
      placeholder="Пошук по назві" 
      style={{width:'300px'}}
    />

    {filter(pages).map(page=>(<Page
      key={page._id}
      pageId={page._id}
      active={page?.active} 
      onClick={()=>{deletePage(page?.type,page._id)}}
      onEdit={()=> {showEditWindow(page?.type,page._id,navigate)}}
      name={page?.name}
      type={page?.type}
      login={page?.login}
      scans={page?.scans?.length}

    />
  ))}

  </div>
</>



  return <>

  <Header/>

  <div className={styles.header}>
    <div className={styles.title}>Мої сторінки</div>

    <div className={styles.links}>
    <div onClick={showAddFolderModal} className={styles.link}>+ Створити папку</div>
    <div onClick={showCreatePageModal} className={styles.link}>+ Створити сторінку</div>
    </div>
  </div>

  <Folders/>

 

  {planExpiresIn>moment().format() ? pagesSection : <TimeToPay/>}
  

  
  <PageWindow/>
  <FolderModal/>
  <CreatePageModal/>
  
  </>;
}

export default UserPages;
