import styles from "./OrderDetailsModal.module.css";
import { Drawer,SelectPicker, Toggle } from 'rsuite';
import { useOrderDetailsModal } from "./useOrderDetailsModal";
import { ItemContainer, PublicGridContainer } from "../../../../../Components/BaseComponents/BaseComponents";
import { calculateOrderTotal } from "../../../../../helperFunctions";
import { orderStatuses } from "../../../../PublicPages/helper";

function OrderDetailsModal({pageType,pageId}) {


  let {
    display,
    closeModal,
    orderId,
    tableNumber,
    basket,
    message,
    userPromocode,
    total,
    order,
    statusHandler,
    paidStatusHandler,

  }=useOrderDetailsModal()



// Blocks
const inRestaurant = <>

<PublicGridContainer>
    <div>Cтолик №</div>
    <strong>{tableNumber}</strong>

    <div>Промокод</div>
    <strong>{userPromocode}</strong>

    <div>Повідомлення:</div>
    <strong>{message}</strong>

    <div>Тип оплати:</div>
  <strong>{order?.info?.paymentType}</strong>

    </PublicGridContainer>
  
 </>


 const toTheAddress = <>
<PublicGridContainer>
  <div>Імя клієнта:</div>
  <strong>{order?.info.name}</strong>
  <div>Телефон:</div>
  <strong>{order?.info.phone}</strong>
  <div>Адреса:</div>
  <strong>{order?.info.address}</strong>
  <div>Коли доставити:</div>
  <strong>{order?.info.when}</strong>
  <div>Час:</div>
  <strong>{order?.info.time}</strong>

  <div>Тип оплати:</div>
  <strong>{order?.info?.paymentType}</strong>

  <div>Повідомлення:</div>
  <strong>{message}</strong>
  </PublicGridContainer>

</>

const takeaway = <>
<PublicGridContainer>
<div>Імя клієнта:</div>
<strong>{order?.info.name}</strong>
<div>Телефон:</div>
<strong>{order?.info.phone}</strong>
<div>Коли доставити:</div>
<strong>{order?.info.when}</strong>
<div>Час:</div>
<strong>{order?.info.time}</strong>
<div>Тип оплати:</div>
  <strong>{order?.info?.paymentType}</strong>

<div>Повідомлення:</div>
<strong>{message}</strong>
</PublicGridContainer>

</>

  return <>
  
    <Drawer open={display} onClose={closeModal}>
        <Drawer.Header>
          <Drawer.Title> Деталі замовлення: {orderId}</Drawer.Title>
           
          
        </Drawer.Header>
        <Drawer.Body>

        <PublicGridContainer>

        <div>Cтатус:</div>
            <SelectPicker

            value={order?.status}
            onChange={(v)=>{statusHandler(pageType,pageId,v)}}
            searchable={false}
            cleanable={false}
            data={orderStatuses} 
            style={{ width: 324 }} />

         <div>Оплачено:</div>
        
        <Toggle onChange={(v)=>{paidStatusHandler(pageType,pageId,v)}} checked={order?.paid} size={'sm'}/> 
 

        </PublicGridContainer>
  
        {tableNumber && inRestaurant }
      {order?.info?.delivery=='toTheAddress' && toTheAddress }
      {order?.info?.delivery=='takeaway' && takeaway }

        <h5 style={{marginTop:'50px',marginBottom:'30px'}}>Замовлення:</h5>

<div className={styles.items_container}>
        {basket.map(item=>(
          <ItemContainer
            basketView={true}
            quantity={item?.quantity}
            photo={item?.photo}
            name={item?.name}
            price={item?.price}
            currency={item?.currency}
        />
        ))}

<span ><strong>До сплати:</strong> {total}</span>

</div>


        </Drawer.Body>
  </Drawer>

  
  </>;
}

export default OrderDetailsModal;
