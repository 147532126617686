import {create} from 'zustand'
import { useRestaurant } from '../../useRestaurant'
import { calculateOrderTotal } from '../../../../../helperFunctions'
import { api } from '../../../../../helpers/api'



export let useOrderDetailsModal = create((set,get)=>({

display:false,
orderId:undefined,
tableNumber:undefined,
basket:[],
message:undefined,
userPromocode:undefined,
order:undefined,

total:0,


showOrderDetails:(oId)=>{

    let {orders} =useRestaurant.getState().page
    let order =orders.find(order=>order.id==oId)
    let {id,tableNumber,basket,message,userPromocode,discount}=order


    let total =calculateOrderTotal(basket)
     total=total-total*discount/100
    set({
        orderId:id,
        tableNumber,
        basket,
        message,
        display:true,
        userPromocode,
        total,
        order,
    })

},

closeModal:()=>{
    set({display:false})
},

statusHandler:async (pageType,pageId,v)=>{


    let {orderId} =get()
    let status =v

    let response = await api.user.changeOrderStatus(orderId,pageType,pageId,status)
    
    if  (response) {
        let {page}=useRestaurant.getState()

        let updatedPage = await api.user.getPageById('restaurant',pageId)
        if(!updatedPage?.orders) return

        useRestaurant.setState({page:{...page,orders:updatedPage.orders}})

        set({order:{...get().order,status:v}})
    }
},

paidStatusHandler:async(pageType,pageId,v)=>{


    let {orderId} =get()
    let status =v

    let response = await api.user.changeOrderPaymentStatus(orderId,pageType,pageId,status)
    
    if  (response) {
        let {page}=useRestaurant.getState()

        let updatedPage = await api.user.getPageById('restaurant',pageId)
        if(!updatedPage?.orders) return

        useRestaurant.setState({page:{...page,orders:updatedPage.orders}})

        set({order:{...get().order,paid:v}})
    }



},


clear:()=>{
    set({
        orderId:undefined,
        tableNumber:undefined,
        basket:[],
        message:undefined,
        userPromocode:undefined,
        total:0,
        order:undefined,


    })
}
    
}))