import {create} from 'zustand'
import { api } from '../../helpers/api'
import { formatDateToCustomFormat,generateCalendarLink } from '../../Pages/PublicPages/helper'
import moment from 'moment'



export let useAppointmentSection = create((set,get)=>({

    section:'форма',

    setSection:(section)=>{
      set({section})
    },

    appointment:{name:'',phone:'', date: Date.now(),time:undefined, message:''},

    appointmentHandler:(v,e)=>{
      set({appointment:{...get().appointment,[e.target.id]:v}})
    },
  
    dateHandler:(date)=>{
      set({appointment:{...get().appointment,['date']:date}})
    },
  
    timeHandler:(time)=>{
      set({appointment:{...get().appointment,['time']:time}})
    },
  
  
    createAppointment:async(pageType,pageId)=>{
  
      let {appointment}=get()
      let  response = await api.createAppointmentOnPage(pageType,pageId,appointment)
      if(response){
        set({section:'успішний запис'})
      }
      
   }, 
  

   addToGoogleCalendar:async(who)=>{

    let {appointment} =get()
    let time=moment(appointment.time).format('HH:mm')  
    let date = formatDateToCustomFormat(appointment.date,time)
    let url = generateCalendarLink (
      `У Вас назначена встреча c ${who} на ${time}`,
      date,
      ` Не забудьте быть вовремя! В случае отмены - напишите об этом здесь: ${window.location.href}`,
      `-`)

    window.open(url,'_blank')
},




    clear:()=>{
    set({
        appointment:{name:'',phone:'', date: Date.now(),time:undefined, message:''},
        // section:'форма',

      })
    }



    
}))