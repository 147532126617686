import { Button,Placeholder,Input } from "rsuite";
import { InputsContainer, ItemContainer, PublicGridContainer, Selector } from "../../../../../../Components/BaseComponents/BaseComponents";
import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./BasketModal.module.css";
import { useBasketModal } from "./useBasketModal";
import { calculateOrderTotal } from "../../../../../../helperFunctions";
import { useLocation } from "react-router-dom";
import { timeOptionsForDelivery } from "../../../../helper";
import { useRestaurantP } from "../../useRestaurantP";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalForPage from "../../../../../../Components/PayPalForPage/PayPalForPage";


function BasketModal({orderSettings}) {


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tableNumber = +searchParams.get('tableNumber');  



  let {
    
    display,
    closeBasket,
    createOrder,
    inputHandler,
    section,
    orderId,
    basket,
    addToBasket,
    removeFromBasket,
    message,
    disablePromocodeInput,
    disableCheckPromocodeButton,
    checkPromocode,
    promocodeHandler,
    promocode,
    total,
    messageHandler,
    info,
    next,
    onPaymentSuccess,
    onPaymentError,
  
  }=useBasketModal()

  

  const {page}=useRestaurantP()



  const initialOptions = {
    clientId: page?.paypalCredentials?.clientId,
    currency: "USD",
    intent: "capture",
  };
  



  function getActiveDeliveryOptions (orderSettings) {
   if(!orderSettings) return []

    let options=[]
    if(orderSettings.deliveryType.inRestaurant) options.push('inRestaurant')
    if(orderSettings.deliveryType.takeaway) options.push('takeaway') 
    if(orderSettings.deliveryType.toTheAddress) options.push('toTheAddress') 
    return options
  }

  function getActivePaymentMethods (orderSettings) {
    if(!orderSettings) return []
 
     let options=[]
     if(orderSettings.paymentType.online)  options.push('online')
     if(orderSettings.paymentType.offline) options.push('offline') 
     return options
   }

//Data   
   let paymentTypesOptions =getActivePaymentMethods (orderSettings).map(
    item => ({ label: item, value: item })
  );
 
  let deliveryOptions =getActiveDeliveryOptions (orderSettings).map(
    item => ({ label: item, value: item })
  );

//Elements

const PayPalButton = <>

<PayPalForPage
    pageType={'restaurant'}
    pageId={page?._id}
    basket={basket}
    onSuccess={onPaymentSuccess}
    onError={onPaymentError}
    
    
    />



</>


const CreateOrderButton =<>

  <Button
    color="blue"
    appearance="primary"
    onClick={()=>{createOrder(tableNumber)}}
  >
    Замовити
  </Button>

</>



// Blocks

const inRestaurant =<>


На коли:
  <Selector
    id={'when'}
    value={info?.when}
    placeholder={'Оберіть'}
    onChange={inputHandler}
    data={timeOptionsForDelivery}
/>



 {info?.when=='На певний час' &&
      
      <Input
      type="time"
        id={'time'}
        value={info?.time}
        placeholder={'Оберіть час'}
        onChange={inputHandler}
      />
      
  }
  
  Імя:
      <Input
        id={'name'}
        value={info?.name}
        placeholder={'Ваше імя'}
        onChange={inputHandler}
      />
  
  {info?.when=='На певний час' && <>
  Телефон:
      <Input
        id={'phone'}
        value={info?.phone}
        onChange={inputHandler}
      />
  
  </>}
  
  
  
</>
  
const toTheAdress = <>
  На коли:
  <Selector
    id={'when'}
    value={info?.when}
    placeholder={'Оберіть'}
    onChange={inputHandler}
    data={timeOptionsForDelivery}
/>

  {info?.when=='На певний час' &&
      
      <Input
      type="time"
        id={'time'}
        value={info?.time}
        placeholder={'Оберіть час'}
        onChange={inputHandler}
      />
      
  }

Адреса:
<Input
  id={'address'}
  value={info?.address}
  placeholder={'Вкажіть адресу'}
  onChange={inputHandler}
/>
Імя:
  <Input
    id={'name'}
    value={info?.name}
    placeholder={'Ваше імя'}
    onChange={inputHandler}
  />
Телефон:
<Input
id={'phone'}
value={info?.phone}
onChange={inputHandler}
/>
</>

const takeaway =   <> 

На коли:
  <Selector
    id={'when'}
    value={info?.when}
    placeholder={'Оберіть'}
    onChange={inputHandler}
    data={timeOptionsForDelivery}
/>
{info?.when=='На певний час' &&
      
      <Input
      type="time"
        id={'time'}
        value={info?.time}
        placeholder={'Оберіть час'}
        onChange={inputHandler}
      />
      
  }
Імя:
  <Input
      id={'name'}
      value={info?.name}
      placeholder={'Ваше імя'}
      onChange={inputHandler}
    />

Телефон:
  <Input
    id={'phone'}
    value={info?.phone}
    onChange={inputHandler}
  />

  </> 

// Sections

const choosePlaceSection =<>


<PayPalScriptProvider options={initialOptions}>

   <ModalWindow title={'Куди доставити'} style={{width:'500px'}}>
   
  Опції:
    <Selector
      id={'delivery'}
      value={info?.delivery}
      placeholder={'Оберіть'}
      onChange={inputHandler}
      data={deliveryOptions}
    />

    {info?.delivery=='toTheAddress' && toTheAdress}

    {info?.delivery=='takeaway' && takeaway}

    {info?.delivery=='inRestaurant' && inRestaurant}


    
    
    <>

    { 
    info?.delivery && info?.paymentType=='online' && page?.enablePayPal && page?.orderSettings?.paymentType?.online
    ? PayPalButton 
    : CreateOrderButton
    }
     

          </>
        
          <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>


  </ModalWindow>
  </PayPalScriptProvider>
</>

const itemsSection = <>


    <ModalWindow title={'Корзина'} style={{width:'500px'}}>
   
   {basket.length==0 && <Placeholder rows={8}/>}

   {basket?.map(item=>(
    <ItemContainer
    photo={item?.photo}
    name={item?.name}
    price={item?.price}
    currency={item?.currency}
    about={item?.about}
    allowOrders={true}
    defaultType={'quantity'}
    quantity={item?.quantity}
    onIncrease={()=>{addToBasket(item.id)}}
    onDecrease={()=>{removeFromBasket(item.id)}}


  />
   ))}


    <Input
        as='textarea'
        rows={3}
        id="message"
        onChange={messageHandler}
        value={message}
        placeholder="Повідомлення" 
   />


<span ><strong>До сплати:</strong> {total}</span>




<Input
        disabled={disablePromocodeInput}
        id="promocode"
        onChange={promocodeHandler}
        value={promocode}
        placeholder="Промокод"
      />

       {basket.length!=0 && <>
        <Button
            disabled={disableCheckPromocodeButton}
            color="orange"
            appearance="primary"
            onClick={checkPromocode}
          >
            Застосувати промокод
          </Button>
       
       
          <Selector
              id={'paymentType'}
              value={info?.paymentType}
              placeholder={'Оберіть варіант оплати'}
              onChange={inputHandler}
              data={paymentTypesOptions}
            />



         <Button
            color="blue"
            appearance="primary"
            onClick={()=>{next(tableNumber)}}
          >
            Оформити замовлення
          </Button>

          </>
        }
          <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>


  </ModalWindow>

  </>

const successOrderSection =<>
   
   <ModalWindow title={'Замовлення успішне'} >

<PublicGridContainer>
  <div>Ваш номер замовлення: &nbsp;&nbsp;</div>
  <strong>{orderId}</strong>

</PublicGridContainer>

   <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>


   </ModalWindow>




  </>



  if  (display) return <>

  {section=='товари' && itemsSection}
  {section=='замовлення успішне' && successOrderSection}
  {section=='опції доставки' && choosePlaceSection}

  
  </>;
}

export default BasketModal;
