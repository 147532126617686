import { ModalWindow } from "../PageWindow/PageWindow";
import styles from "./FolderModal.module.css";
import { useFolderModal } from "./useFolderModal";
import { Button,Input } from 'rsuite';





function FolderModal() {

let {

  display,
  mode,
  inputHandler,
  folder,
  createFolder,
  updateFolder,
  hideModal

}=useFolderModal()





  if(display){return <>
  
  <ModalWindow title={mode=='add'?'Створити нову папку':'Редагувати папку'}>


  <Input
        id="name"
        onChange={inputHandler}
        value={folder.name}
        placeholder="Назва" 
        />


  <Button  
  color="blue"
  appearance="primary"
  onClick={mode=='add'?createFolder:updateFolder}
  >Зберегти</Button>

  <Button onClick={hideModal} color="blue" appearance="ghost">Закрити</Button>


  </ModalWindow>
  
  </>}
}

export default FolderModal;
