import BuyPlanModal from "./Components/BuyPlan/BuyPlanModal";
import { useBuyPlanModal } from "./Components/BuyPlan/useBuyPlanModal";
import styles from "./TimeToPay.module.css";

function TimeToPay() {

  const {showBuyPlan}=useBuyPlanModal()

  return <>
  
      <div className={styles.container}>

          <div>
            Для доступу до сторінок, придбайте тарифний план.
            &nbsp;&nbsp;
          <span onClick={showBuyPlan} className="link">Придбати</span>
          </div>

      </div>

  <BuyPlanModal/>
  </>;
}

export default TimeToPay;
