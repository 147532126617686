import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./NotificationSettingsModalModal.module.css";
import { useNotificationSettingsModalModal } from "./useNotificationSettingsModalModal";
import { Button,RadioGroup,Radio,Input } from "rsuite";

function NotificationSettingsModalModal() {
  let {
    display,
    mode,
    closeModal,
    inputHandler,
    addNotificationsSettings,
    updateNotificationsSettings,
    notificationType,
    notificationTypeHandler,
    section,
    usersNotificationsSetting,
    chooseProvider,
    providerHandler,
    goToProviderSettings,
    createNotificationsSettings,
  } = useNotificationSettingsModalModal();


let chooseNotificationTypeSection =<>

<ModalWindow title={"Оберіть канал звязку"}>


<RadioGroup 
value={usersNotificationsSetting.type} 
onChange={notificationTypeHandler} 
name="radioList">
    
      <Radio value="sms">СМС сповіщення</Radio>
      <Radio value="email">Email сповіщення</Radio>
      <Radio value="push">Push сповіщення</Radio>
     
    </RadioGroup>

          <Button
            onClick={chooseProvider}
            color="blue"
            appearance="primary"
          >
            Далі
          </Button>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
</>

let chooseProviderSection =<>
<ModalWindow title={"Оберіть провайдера"}>


<RadioGroup 
value={usersNotificationsSetting.provider} 
onChange={providerHandler} 
name="radioList">
    
      <Radio value="sms-fly">SMS-fly</Radio>
     
    </RadioGroup>

          <Button
            onClick={goToProviderSettings}
            color="blue"
            appearance="primary"
          >
            Далі
          </Button>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>


</>

let smsFlySettingsSection =<>

<ModalWindow title={mode=='add' ? "Додайте API ключ від провайдера":'Редагувати API ключ'}>

        <Input
          id={'apiKey'}
          onChange={inputHandler}
          value={usersNotificationsSetting.apiKey}
          placeholder="API KEY"
        />


          <Button
            onClick={mode=='add' ?addNotificationsSettings:updateNotificationsSettings}
            color="blue"
            appearance="primary"
          >
            Зберегти
          </Button>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>


</>



  if (display)
    return (
      <>
       {section=='chooseNotificationType' && chooseNotificationTypeSection}
       {section=='chooseProvider'         && chooseProviderSection}
       {section=='smsFlySettings'         && smsFlySettingsSection}

      </>
    );
}

export default NotificationSettingsModalModal;
