import {create} from 'zustand'
import { api } from '../../../../../../../helpers/api'
import { useGoodsCategory } from '../../useGoodsCategory'
import shortid from 'shortid'
import { showToast } from '../../../../../../../Components/Toast/useToast'
import { frontDB } from '../../../../../../../helpers/frontDB'



export let useGoodWindow = create((set,get)=>({

display:false,
mode:undefined,


showAddGoodWindow:()=>{
    let {currency}=frontDB.getState().user
    set({display:true,mode:'add'})
    set({good:{...get().good,currency}})
},

showEditGoodWindow:(goodId)=>{

    set({display:true, mode:'edit'})
    let {goods} = useGoodsCategory.getState()
    let good = goods.find(good=>(goodId==good.id))
    set({good})
},

closeWindow:()=>{

    get().clear()
},





good:{name:'',about:'',url:'',price:'',currency:'',active:true,top:false,},

inputHandler:(v,e)=>{
    set({good:{...get().good,[e.target.id]:v}})
},

statusHandler:(v)=>{
    set({good:{...get().good,active:v}})
},

topHandler:(v)=>{
    set({good:{...get().good,top:v}})
},

changePhoto:({type,url})=>{
    
    if(type=='photo'){set({good:{...get().good,photo:url}})}
    
},

currencyHandler:(v)=>{
    set({good:{...get().good,currency:v}})
},




addGood:()=>{
    
    let {good}=get()

    if(good.name==''){showToast('e','Введіть назву страви');return}

    good.id = shortid.generate()

    let goods = useGoodsCategory.getState().goods
    
    useGoodsCategory.setState({goods:[...goods,good]})
    get().closeWindow()
},




updateGood:()=>{
    
    let {good}=get()

    if(good.name==''){showToast('e','Введіть назву страви');return}

    let goods = useGoodsCategory.getState().goods
    let index =goods.findIndex(item=>(good.id==item.id))
    goods[index]=good
    useGoodsCategory.setState({goods})

    get().closeWindow()


},


clear:()=>{
    let {currency}=frontDB.getState().user

    set({
        display:false,
        mode:undefined,
        good:{name:'',about:'',url:'',price:'',currency,active:true,top:false}
    })
}
    
}))