import {create} from 'zustand'
import { showToast } from '../../../../Components/Toast/useToast';
import { api } from '../../../../helpers/api';



export let useChangePassword = create((set,get)=>({

display:false,


showChangePasswordModal:()=>{set({display:true})},
hideChangePasswordModal: ()=>{set({display:false});get().clear()},



password:{password:undefined,repeatedPassword:undefined},

inputHandler:(v,e)=>{
    
    set({password:{...get().password,[e.target.id]:v}})  

},


changePassword:async()=>{

    let{password}=get()
    if (!checkPassword(password)) return

    let newPassword =password.password
    let response = await api.user.changePassword(newPassword)
    if(response===true){
        get().hideChangePasswordModal()
        
    }

},


clear:()=>{set({password:undefined,repeatedPassword:undefined})}
   
}))



function checkPassword(password){

    if(!password?.password){showToast('e','Введіть новий пароль');return false}
    if(password.password.length<5){showToast('e','Пароль має мати більше 4 символів');return false}
    if(!password?.repeatedPassword){showToast('e','Повторіть новий пароль');return false}
    if(password.password!==password.repeatedPassword){showToast('e','Паролі не співпадають');return false}
    
    return true
}