import { useState } from "react";
import styles from "./ResumeUploader.module.css";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import { useResumeUploader } from "./useResumeUploader";
import { useEffect } from "react";


function ResumeUploader({fileName,url,onResumeDelete}) {

let{

state,
fileHandler,
file,
deleteFile,
getCurrentFile,



}=useResumeUploader()


useEffect(()=>{
  getCurrentFile(fileName)
},[])



  if(state=='chooseFile') return <>

      <div>Резюме:</div>

      <input 

      type="file" 
      id="fileInput" 
      name="uploadedFile"
      onChange={fileHandler}
      />

  
  </>;



  if(state=='uploaded') return <>

      <div>Резюме:</div>

      <div className={'link'} onClick={(e)=>{window.open(url,'_blank')}} 
      >{file.name} </div>
       <WarningRoundIcon
      style={{color:'red'}}
      onClick={()=>{deleteFile(url);onResumeDelete()}}

    />

  </>

}

export default ResumeUploader;
