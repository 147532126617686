import {create} from 'zustand'



export let useStatsBlock = create((set,get)=>({

   
    top5items:[], //    {id, name, numberOfOrders}
    totalOrders:0,
    income:0,
  
    period:{from:new Date('2023-01-01T00:00:00.000Z'),to:new Date('2024-01-01T00:00:00.000Z')},

    dateRangeHandler:(v)=>{
        let[from,to]=v
        set({period:{from,to}})
    },

    getStats:(pageType,orders)=>{
       let {period}=get()

        if(pageType=='business'){

            let totalOrders =getTotalOrders(orders,period)
            let income    = calculateIncomeForBusinessPage(orders,period)
            let top5items = getTop5ItemsByNumberOfOrders(orders,period)
            set({totalOrders,income,top5items})    

        }

        if(pageType=='restaurant'){

          let totalOrders =getTotalOrders(orders,period)
          let income    = calculateIncomeForBusinessPage(orders,period)
          let top5items = getTop5ItemsByNumberOfOrders(orders,period)

          set({totalOrders,income,top5items})    

      }

        if(pageType=='personal'){

        let totalOrders =getTotalOrders(orders,period)
        let income    = calculateIncomeForPersonalPage(orders,period)
        let top5items = getTop5ItemsByNumberOfOrdersForPersonalPage(orders,period)

        set({totalOrders,income,top5items})    

    }




    }

    
}))

//c Допоміжні


let getTotalOrders =(orders,period)=>{

orders = getOrdersFromPeriod(period,orders)

return orders.length


}


let calculateIncomeForBusinessPage=(orders,period)=>{

    orders = getOrdersFromPeriod(period,orders)

    let income = 0

    for(let i=0;i<orders.length;i++){

    let basket = orders[i].basket

            for(let i=0;i<basket.length;i++){

                let itemPrice =basket[i].price
                let itemQuantity = +basket[i].quantity

                income += itemPrice*itemQuantity
            }


    }

    return income

}

let getTop5ItemsByNumberOfOrders=(orders,period)=>{

orders = getOrdersFromPeriod(period,orders)

    
// Get array of items
let items = orders.map(order=>(order.basket)).flat()

//Group by id
let occurrenceList = getItemOccurrences(items)

let topList =occurrenceList.sort((a,b)=>b.totalQuantity-a.totalQuantity)

return topList.slice(0,5)
}



// Personal page
let calculateIncomeForPersonalPage=(orders,period)=>{

  orders = getOrdersFromPeriod(period,orders)

  let income = 0

  for(let i=0;i<orders.length;i++){

  let basket = orders[i].basket

          for(let i=0;i<basket.length;i++){

              let itemPrice = +basket[i].itemInfo.price

              income += itemPrice
          }


  }

  return income

}

let getTop5ItemsByNumberOfOrdersForPersonalPage=(orders,period)=>{

orders = getOrdersFromPeriod(period,orders)

    
// Get array of items
let items = orders.map(order=>(order.basket)).flat()

//Group by id
const itemMap = new Map();
  
   
for (const item of items) {
  const itemId = item.itemId;
  const itemName = item.itemInfo.name;
  const itemQuantity=1

  if (itemMap.has(itemId)) {
    const existingItem = itemMap.get(itemId)
    existingItem.numberOfOccurrence++;
     existingItem.totalQuantity += itemQuantity
} else {
    itemMap.set(itemId, {
      id: itemId,
      name: itemName,
      numberOfOccurrence: 1,
      totalQuantity: itemQuantity

    });
  }
}


const occurrenceList = Array.from(itemMap.values());

let topList =occurrenceList.sort((a,b)=>b.numberOfOccurrence-a.numberOfOccurrence)

return topList.slice(0,5)

}


//
function getItemOccurrences(inputArray) {

    const itemMap = new Map();
  
   
    for (const item of inputArray) {
      const itemId = item.id;
      const itemName = item.name;
      const itemQuantity=item.quantity
  
      if (itemMap.has(itemId)) {
        const existingItem = itemMap.get(itemId)
           existingItem.numberOfOccurrence++;
            existingItem.totalQuantity += itemQuantity
      } else {
        itemMap.set(itemId, {
          id: itemId,
          name: itemName, 
          numberOfOccurrence: 1,
          totalQuantity: itemQuantity
        });
      }
    }

    const result = Array.from(itemMap.values());

    return result


}

function getOrdersFromPeriod(period,orders){

    let {from,to}=period

    let filteredOrders = orders.filter(order=>{
    
        let createdAt =new Date(order.createdAt)
           from=from
           to=to
   
        return from < createdAt && createdAt< to
   })
   
   return filteredOrders

};