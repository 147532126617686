import PromocodeModal from "./Components/PromocodeModal/PromocodeModal";
import { usePromocodeModal } from "./Components/PromocodeModal/usePromocodeModal";
import styles from "./Promocodes.module.css";
import WarningRoundIcon from '@rsuite/icons/WarningRound'

function Promocodes({pageType,promocodes}) {

  let {
    
    showAddPromocode,
    showEditPromocode,
    deletePromocode,

  }=usePromocodeModal()

  
  return <>
  
  <div 
  onClick={showAddPromocode} 
  className={'link'}>+ Додати промокод</div>


{promocodes.map(promocode=>(

<Promocode
  onEdit={()=>{showEditPromocode(promocode?.id)}}
  onDelete={()=>{deletePromocode(promocode?.id)}}
  name={promocode?.name}
  discount={promocode?.discount}
  status={promocode?.status}
/>

))}
  
  <PromocodeModal pageType={pageType}/>
  </>;
}

export default Promocodes;

//c Допоміжні

function Promocode ({name,discount,status,onEdit,onDelete}) {
  return <>
  
  <div className={styles.promocode_container}>
 
   <div onClick={onEdit}>{name}</div>
   <strong style={{color:'red'}}>{discount}%</strong>
   <div style={{color:'green'}}>{status}</div>
   <WarningRoundIcon onClick={onDelete}/>
 
  </div>
  
  </>
}