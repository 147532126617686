import {create} from 'zustand'
import { showToast } from '../../../../../../Components/Toast/useToast'
import { api } from '../../../../../../helpers/api'
import { useRestaurantP } from '../../useRestaurantP'
import { calculateOrderTotal } from '../../../../../../helperFunctions'
import { OrdersService } from '../../../../../../Services/Orders.services'



export let useBasketModal = create((set,get)=>({

    display:false,

    section:'товари',

    showBasket:(tableNumber)=>{
        
        set({section:'товари',display:true})
    },


    closeBasket:()=>{
        
        if(get().section=='замовлення успішне'){
            window.location.reload()
            return
        }
    
        set({display:false})
        get().clear()
    },


    orderId:undefined,
    basket:[],
    total:0,


    disablePromocodeInput:false,
    disableCheckPromocodeButton:false,

    promocode:undefined,
    discount:0,

    promocodeHandler:(v)=>{
        set({promocode:v})
    },


    checkPromocode:async()=>{

        let {promocode}=get()

        let {page} =useRestaurantP.getState()
        let response = await api.checkPromocode('restaurant',page._id,promocode)
        
        if(response){
            let {total}=get()
            let discount = response

            set({
                total:total-(discount/100*total),
                discount,
                disablePromocodeInput:true,
                disableCheckPromocodeButton:true,
            })
        }

        if(!response){
            set({promocode:''})

        }
    },


    
    addToBasket:(itemId)=>{

    let {basket,discount}=get()

  
    let item =basket.find(item=>item.id==itemId)

    if(!item){

        let {menu} = useRestaurantP.getState().page
        let dishes = menu.map(catalog=>catalog.dishes).flat()
        let dish=dishes.find(dish=>dish.id==itemId)
        dish.quantity=1
        
        basket=[...basket,dish]
        let total=calculateOrderTotal(basket)
        
        total=total-(discount/100*total)

        set({basket,total})
        return
    }

    let indexOfItem=basket.findIndex(item=>item.id==itemId)
    basket[indexOfItem].quantity +=1

    let total=calculateOrderTotal(basket)
    total=total-(discount/100*total)

    set({basket,total})


    set({basket})

    },

    removeFromBasket:(itemId)=>{

    let {basket,discount}=get()
    let indexOfItem=basket.findIndex(item=>item.id==itemId)

    
    basket[indexOfItem].quantity -=1

    let total=calculateOrderTotal(basket)
    total=total-(discount/100*total)

    set({basket,total})


    if(basket[indexOfItem].quantity==0){
        basket.splice(indexOfItem,1)
        set({basket,total:0})

        return

    }


    },


    next:async(tableNumber)=>{
        if(tableNumber) {
            get().createOrder(tableNumber)
            return
        }

        set({section:'опції доставки'})
    },

    createOrder:async (tableNumber)=>{

        let {basket,message,promocode,discount,info,paid}=get()
        let {page}=useRestaurantP.getState()

        if(tableNumber) info.delivery='inRestaurant'

        let response = await api.createOrderOnPage('restaurant',page._id,{tableNumber,basket,message,userPromocode:promocode,discount,info,paid})
            
        if(response){
                set({orderId:response})
                set({section:'замовлення успішне'})
                OrdersService.addOrder(response)
            }

    },

    paid:false,

    onPaymentSuccess:async(response)=>{
        const {createOrder}=get()
        set({paid:true})
        createOrder()
    },

    onPaymentError:async(response)=>{
        const {closeBasket}=get()
        showToast('e','Помилка оплати')
        closeBasket()
    },

    message:undefined,

    messageHandler:(v)=>{
        set({message:v})
    },


    info:{delivery:undefined,name:'', address:'',when:undefined,time:'',phone:'+380',paymentType:undefined},

    inputHandler:(v,e)=>{
        let id = e.target.id
        set({info:{...get().info,[id]:v}})
    },




    clear:()=>{
        set({
            section:'товари',
            order:{name:undefined,adress:undefined,phone:undefined,email:undefined},
            orderId:undefined,
            message:undefined,
            promocode:undefined,
            discount:0,
            info:{delivery:undefined,address:'',when:undefined,time:'',name:'',phone:'+380',paymentType:undefined},
            paid:false,

        })
    }


    
}))


//c Допоміжні

function getUniqueItemsWithHighestQuantity(input) {
    const itemMap = new Map();
  
    for (const { itemId, itemInfo, quantity } of input) {
      if (!itemMap.has(itemId) || itemMap.get(itemId).quantity < quantity) {
        itemMap.set(itemId, { itemId,itemInfo, quantity });
      }
    }
  
    return Array.from(itemMap.values());
  }