import {create} from 'zustand'
import { api } from '../../helpers/api'



export let useSignUp = create((set,get)=>({

form:{email:undefined,password:undefined,repeatPassword:undefined},

inputHandler:(v,e)=>{

set({form:{...get().form,[e.target.id]:v}})  

},
  

signUp: async()=>{
    
    let data = await api.user.signUp(get().form) 
    if(data){window.location.href='/pages'}
}

    
}))


