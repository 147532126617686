import styles from "./GoogleMaps.module.css";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function GoogleMaps({lat,lng}) {

  const mapStyles = {
    
    width: "360px",
    height: "200px",
  };

  const defaultCenter = {
    lat,lng
  };
    return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={10}
        center={defaultCenter}
      >
        {/* Add markers or other map elements here */}
        <Marker position={defaultCenter} />
      </GoogleMap>
    </LoadScript>
  );
}


export default GoogleMaps;
