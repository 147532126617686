import {create} from 'zustand'
import { api } from '../../../../helpers/api'



export let useLinksP = create((set,get)=>({


    page:undefined,

    getPage:async()=>{
    
        let login = getLoginFromUrl()
        
        if(!login){window.location.href='/login'}
        
        let data = await api.getPageByLogin(login)
        if(!data){window.location.href='/login'}
        set({page:data})
        
        return data
    
    },





    
}))


function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
};