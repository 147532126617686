import { fn } from "moment/moment";
import styles from "./AccessManager.module.css";
import AccessModal from "./Components/AccessModal/AccessModal";
import { useAccessModal } from "./Components/AccessModal/useAccessModal";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import { InputsContainer } from "../../../../Components/BaseComponents/BaseComponents";
import { frontDB } from "../../../../helpers/frontDB";
import { useEffect } from "react";
import { Toggle } from "rsuite";

function AccessManager({pageId}) {

let {updateDB,accesses}=frontDB()


let {
  showAddAccess,
  showEditAccess,
  deleteAccess,
  managerStatusHandler,
  changeAccessStatus,

}=useAccessModal()


useEffect(()=>{
  updateDB('accesses')
},[])

// Transform stage

  let filterAccesses = pageId 
  ? accesses.filter(access=>access.pages.some(page=>page.id==pageId))
  : accesses



  return <>


  <div onClick={showAddAccess} className={'link'}>+ Додати доступ</div>
  <InputsContainer>


    {filterAccesses.map(access=>(

    <Access
      active={access?.active}
      onActiveChange={(v)=>{changeAccessStatus(v,access._id)}}

      role={access?.role}
      name={access?.name}
      email={access?.email}
      onClick={()=>{showEditAccess(access?._id)}}
      onDelete={()=>{deleteAccess(access?._id)}}
      avaliableForOrders={access?.avaliableForOrders}
      managerStatusChange={(v)=>{managerStatusHandler(v,access._id)}}
    />


    ))}
  
  
  </InputsContainer>
  
  <AccessModal pageId={pageId}/>
  </>;
}

export default AccessManager;



// Допоміжні

function Access ({name,email,role,onClick,onDelete,avaliableForOrders,managerStatusChange,active,onActiveChange}) {
  
  let toggle = role=='orderManager' && <Toggle checked={avaliableForOrders} onChange={managerStatusChange} /> 

  
  return <>
  <div className={styles.access_container}>
    <div onClick={onClick}>{name}</div>
    <div onClick={onClick}>{email}</div>
    <Toggle checked={active} onChange={onActiveChange} />
    <div>Role:{role}</div>
    {toggle}

    <WarningRoundIcon onClick={onDelete}/>
  </div>
  </>
}
