import { ModalWindow } from "../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./TableModal.module.css";
import { useTableModal } from "./useTableModal";
import { Button, Input,RadioGroup,Radio } from "rsuite";

function TableModal() {
  let { 
    display, 
    mode, 
    closeModal, 
    inputHandler, 
    table, 
    addTable, 
    updateTable,
    section,
    functionHandler,
    tableNumbersHandler,
    tableNumbers,
    addManyTables,
  
  } = useTableModal();

let chooseFunctionSection = <>

<ModalWindow title={'Оберіть функцію'}>

<RadioGroup onChange={functionHandler} name="radioList"> 
        <Radio  value="один стіл"> Додати один стіл</Radio>
        <Radio  value="декілька столів"> Додати декілька одночасно</Radio>
</RadioGroup>


</ModalWindow>


</>

let oneTableSection = <>


<ModalWindow title={mode == "add" ? "Додати столик" : "Редагувати столик"}>
          
          
          <Input
           type="number"
           id={'number'}
           onChange={inputHandler}
           value={table?.number}
           placeholder="Номер столику"
          />           
           
           
           <Button onClick={mode == "add" ? addTable:updateTable} color="blue" appearance="primary">
             Зберегти
           </Button>
 
           <Button onClick={closeModal} color="blue" appearance="ghost">
             Закрити
           </Button>
         </ModalWindow>
 

</>

let manyTablesSection =<>


<ModalWindow title={"Додати столики"}>

<Input
  id={'tableNumbers'}
  type="number"
  onChange={tableNumbersHandler}
  value={tableNumbers}
  placeholder="Введіть к-сть столів "
/>

<Button onClick={addManyTables} color="blue" appearance="primary">
             Додати столики
           </Button>
 
           <Button onClick={closeModal} color="blue" appearance="ghost">
             Закрити
           </Button>


</ModalWindow>




</>


  if (display)

    return (
      <>
      
      {section=='оберіть функцію' && chooseFunctionSection}
      {section=='один стіл' && oneTableSection}
      {section=='декілька столів' && manyTablesSection}


      </>
    );
}

export default TableModal;
