import {create} from 'zustand'
import { frontDB } from '../../../../helpers/frontDB'



export let usePageStats = create((set,get)=>({

section:'Переходи',

setSection:(e)=>{
    set({section:e.target.id})
},

page:undefined,



getPage:async (pageId)=>{
    let page = frontDB.getState().getPageById(pageId)
    set({page})
},



    
}))