import { useEffect } from "react";
import styles from "./LocationP.module.css";
import { useLocationP } from "./useLocationP";

function LocationP() {
  
let {getPage}=useLocationP()



useEffect(()=>{
  getPage()
},[])

  return <>
  </>;
}

export default LocationP;


