import { PriceContainer } from "../../../../../../../Components/BaseComponents/BaseComponents";
import PhotoUploader from "../../../../../../../Components/PhotoUploader/PhotoUploader";
import { currencyOptions } from "../../../../../../../helperFunctions";
import { ModalWindow } from "../../../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./DishWindow.module.css";
import { useDishWindow } from "./useDishWindow";
import { Input,Button,Toggle,InputPicker} from 'rsuite';





function DishWindow() {

  let {

    dish,
    display,
    mode,
    addDish,
    updateDish,
    closeWindow,
    inputHandler,
    changePhoto,
    statusHandler,
    topHandler,
    currencyHandler,
  
  }=useDishWindow()


  if(display){return <>


  <ModalWindow title={mode=='add'?'Нова страва' :'Редагувати страву'}>


  <div className={styles.photo_container}>

  <PhotoUploader
    type='photo'
    url={dish?.photo} 
    onUploaded={(url)=>{changePhoto({type:'photo',url})}}
  />



  </div>



  <Input
        id="name"
        onChange={inputHandler}
        value={dish?.name}
        placeholder="Назва страви" 
    />

  <Input
        as="textarea" rows={3}
        id="about"
        onChange={inputHandler}
        value={dish?.about}
        placeholder="Опис" 
    />
<PriceContainer>


<Input
        id="price"
        onChange={inputHandler}
        value={dish?.price}
        placeholder="Ціна" 
    />

<InputPicker
    value={dish?.currency}
    onChange={currencyHandler}
    cleanable={false} 
    data={currencyOptions} 
    style={{ display:'block' }} 
  />

</PriceContainer>
 <span>
              Active: &nbsp; &nbsp; &nbsp;
              <Toggle size={'sm'} checked={dish?.active} onChange={statusHandler} />
            
              &nbsp;&nbsp;&nbsp;

              Top: &nbsp; &nbsp; 
              <Toggle size={'sm'} checked={dish?.top} onChange={topHandler} />

            
  </span>





  <Button  
  color="blue"
  appearance="primary"
  onClick={mode=='add'?addDish:updateDish}
  >Зберегти</Button>

  <Button 
  onClick={closeWindow} 
  color="blue" 
  appearance="ghost"
  >Закрити</Button>


  </ModalWindow>
  
  
  
  </>};
}

export default DishWindow;
