import styles from "./Switcher.module.css";

function Switcher({links,section,setSection}) {
  
  

  let fontStyles = {
    
    nonActive: {
      fontSize: "14px",
      color: "#979797",
      cursor: "pointer",
    },

    active: {
      fontSize: "14px",
      color: "#25B3F5",
      cursor: "pointer",
    },
  };
  

  return (
    <>
      <div className={styles.category_selector_container}>

        {links.map((link) => (
          <span
            key={Math.random()}
            id={link}
            onClick={setSection}
            style={fontStyles[section == link ? "active" : "nonActive"]}
          >
            {link}
          </span>
        ))}

      </div>
    </>
  );

  
  
}

export default Switcher;
