import styles from "./BottomButtons.module.css";
import {Button} from 'rsuite';



BottomButtons.defaultProps={saveButtonName:'Зберегти'}

function BottomButtons({mode,onCreate,onUpdate,onCancel,saveButtonName}) {
  return <>
  
  <div className={styles.container}>

  <Button 
   onClick={onCancel}
   color="blue" 
   appearance="ghost"
   style={{width:'200px'}}

   >Закрити</Button>


  <Button
    onClick={mode=='add'?onCreate:onUpdate}  
    color="blue"
    appearance="primary"
    style={{width:'200px'}}

    >{saveButtonName}</Button>


  </div>
  
  </>;
}

export default BottomButtons;
