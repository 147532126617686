import styles from "./PersonalP.module.css";
import { usePersonalP } from "./usePersonalP";
import { Button,DatePicker } from 'rsuite';
import QRCode from 'qrcode.react';
import { useEffect, useState } from "react";
import { api } from "../../../../helpers/api";
import  { Photo } from "../../../../Components/PhotoUploader/PhotoUploader";
import { PublicPageContainer, PublicPageHeader, PublicPageMessangers, PublicPageSocialNetworks, PublicPageTitle, ServiceContainer } from "../../../../Components/BaseComponents/BaseComponents";
import { isVible } from "../../helper";
import { Input } from "rsuite";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay'
import { Review } from "../../../PagesTypes/Personal/Personal";
import { File } from "../../../../Components/GalleryUploader/GalleryUploader";
import GoogleMaps from "../../../../Components/GoogleMaps/GoogleMaps";
import { fn } from "moment";
import { usePersonal } from "../../../PagesTypes/Personal/usePersonal";
import {UserButton} from "../../../PagesTypes/Personal/Personal";
import AppointmentSection from "../../../../Components/AppointmentSection/AppointmentSection";
import { useBasketModal } from "./Components/BasketModal/useBasketModal";
import BasketModal from "./Components/BasketModal/BasketModal";
import { useOrdersModal } from "./Components/OrdersModal/useOrdersModal";
import OrdersModal from "./Components/OrdersModal/OrdersModal";

function PersonalP() {

 let  {
  page,
  getPage,

  feedbackInputHandler,
  feedback,
  sendFeedback,


}=usePersonalP ()

 let {showBasket,basket,addToBasket,removeFromBasket}=useBasketModal()

 let  [vcard,setVcard]=useState ()


 async function getVCard () {
  let response = await api.downloadVCard('personal',page?._id)
}

const {showOrdersModal}=useOrdersModal()


 useEffect(()=>{
  async function af () {

    let data =await getPage()
    let value= await api.getVCard('personal',data?._id)
    setVcard(value)

  }
  af()
  },[])


  return <>
   <PublicPageContainer 
    allowOrders={page?.allowOrders} 
    itemsInBasket={basket.length}
    onBasketClick={showBasket}
    showOrders
    onOrdersClick={()=>{showOrdersModal(page._id)}}

  >
        <PublicPageHeader>
        
            <Photo type={'backgroundPhoto' } url={page?.backgroundPhoto}/>
           < Photo url={page?.photo}/>
            <div className={styles.name}>{page?.name}</div>

            <QRCode value={vcard}/>

            <Button  
            color="blue"
            appearance="primary"
            onClick={getVCard}
            >Додати в контакти</Button>

        </PublicPageHeader>
          
         <PublicPageTitle title='Кнопки'/>
          {page?.buttons.map(button=>(
            <UserButton
            type={'view'}
            photo={button?.photo}
            url={button?.url} 
            name={button?.name}
          />
          
          ))}
            
         <PublicPageTitle title='Контакти'/>
          
            <div  className={styles.info1}>
            <div style={isVible(page?.phone)}>Телефон:</div>
            <div style={isVible(page?.phone)} className={styles.value}>{page?.phone}</div>
            
            <div style={isVible(page?.email)}>Email:</div>
            <div style={isVible(page?.email)} className={styles.value}>{page?.email}</div>
            
            <div style={isVible(page?.country)}>Країна:</div>
            <div style={isVible(page?.country)} className={styles.value}>{page?.country}</div>
            
            <div style={isVible(page?.city)}>Місто:</div>
            <div style={isVible(page?.city)} className={styles.value}>{page?.city}</div>
            
            <div style={page?.website=='https://www.'?{display:'none'}:{}}>Сайт:</div>
            <a className={styles.value} href={page?.website=='https://www.'?'':page?.website}>{page?.website=='https://www.'?'':page?.website}</a>

            <div style={isVible(page?.company)}>Компанія:</div>
            <div style={isVible(page?.company)} className={styles.value}>{page?.company}</div>
            
            <div style={isVible(page?.proffesion)}>Професія:</div>
            <div style={isVible(page?.proffesion)} className={styles.value}>{page?.proffesion}</div>
          </div>

          <PublicPageTitle title='Про мене'/>

          <div className={styles.info2}>
            <div>{page?.about}</div>
          </div>

          <div className={styles.info2}>
            <div>{page?.education}</div>
          </div>

          
          <div className={styles.info2}>
            <div>{page?.workExperience}</div>
          </div>

           {page?.resume?.url && 
           <>
           <div>Резюме:</div>
           <a href={page?.resume?.url}>Переглянути</a>
           </>
           }

          <PublicPageTitle title='Галерея'/>

          <div className={styles.gallery_container}>

            {page?.gallery.map(file=>(
              <File
              place={'public'} 
              type={file?.type=='Video'?'Video': 'photoPreview'}
              url={file?.url}
              />
            ))}

          </div>

          <PublicPageMessangers messangers={page?.messengers}/>
          <PublicPageSocialNetworks socialNetworks={page?.socialNetworks}/>

          <PublicPageTitle title='Послуги'/>

          <div className={styles.info4}>
            {page?.services.map((service) => {
              
              if(!service.active) return
              
              // return <>
              // <div className={styles.value}>
              //   {service?.name} : &nbsp; {service?.price} {service?.currency}
              // </div>
              // </>

              return <ServiceContainer
                allowOrders={page?.allowOrders}
                name={service?.name}
                price={service?.price}
                currency={service?.currency}
                onAddService={()=>{addToBasket(service)}}
                onRemoveService={()=>{removeFromBasket(service.id)}}
              />
            })}
          </div>
          
          <PublicPageTitle title='Зворотній звязок'/>

          <FeedbackSection
            page={page}
            feedbackInputHandler={feedbackInputHandler}
            feedback={feedback}
            sendFeedback={sendFeedback}
          />

          <PublicPageTitle title='Відгуки'/>

          <Reviews page={page}/>

          {page?.appointmentBlock && 
          <>
          
         <AppointmentSection 
            pageType='personal'
            pageId={page?._id}
            who={page?.name}
 
         />

          </>
          }

          <PublicPageTitle title='Місце розташування'/>

          <GoogleMaps lat={+page?.location?.lat} lng={+page?.location?.lng}/>

          </PublicPageContainer>
   
 <BasketModal/>
 <OrdersModal/>
  
  </>;
}

export default PersonalP;


//c Допоміжні

export function FeedbackSection ({page,feedbackInputHandler,feedback,sendFeedback}) {



 if(page?.feedbackType=='Форма') return <>

  <Input
  id="name"
  onChange={feedbackInputHandler}
  value={feedback?.name}
  placeholder="Ім'я" 
  />

<Input
  id="email"
  onChange={feedbackInputHandler}
  value={feedback?.email}
  placeholder="Email" 
/>

<Input
  id="phone"
  onChange={feedbackInputHandler}
  value={feedback?.phone}
  placeholder="Телефон" 
/>

  <Input
  as='textarea'
  rows={3}
  id="message"
  onChange={feedbackInputHandler}
  value={feedback?.message}
  placeholder="Повідомлення" 
  />

  <Button
  onClick={sendFeedback}  
  color="blue"
  appearance="primary"

  >Відправити повідомлення</Button>
 
  
  </>


if(page?.feedbackType=='Whatsapp'){

  return <>
  
  <Button

    onClick={()=>{window.location.href=`https://api.whatsapp.com/send/?phone=%2B${page?.messengers?.whatsapp}&type=phone_number&app_absent=0`}}  
    color="blue"
    appearance="primary"

    >Написати</Button>

  
  
  </>
}

}






function Reviews ({page}) {


  
  return <>


  <div className={styles.reviews_container}>



  <Swiper
      spaceBetween={10}
      slidesPerView={1}
      modules={[Autoplay]}
      autoplay={{delay:3000}}

    >
      
      {page?.reviews.map(review=>(

        <SwiperSlide>
            <Review
              photo={review?.photo}
              name={review?.name}
              position={review?.postion}
              company={review?.company}
              message={review?.message}
              deleteIcon={0}
            />

        </SwiperSlide>
      ))}
    
    </Swiper>






  </div>
 
  
  </>
}

