import {create} from 'zustand'



export let useBuyPlanModal = create((set,get)=>({

    display:false,

    section:undefined,

    setSection:(section)=>{
        set({section})
    },

    showBuyPlan:()=>{
        set({section:'choosePlan',display:true})
    },

  

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    months:undefined,

    inputHandler:(v,e)=>{
        set({months:v})
    },



   

    clear:()=>{
        set({
            section:undefined,
            months:undefined,
        })
        

    }
    
}))