import {create} from 'zustand'
import { api } from '../../helpers/api'



export let useStats = create((set,get)=>({



section:'Регіони',
 
setSection:(e)=>{
    set({section:e.target.id})
    
},
    


totalScans:undefined,

getTotalScans:async()=>{
    let totalScans = await api.stats.getTotalScans()
    if(typeof totalScans === 'number'){set({totalScans})}
    
}




    
}))