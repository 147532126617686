import {create} from 'zustand'

 

export let useQR = create((set,get)=>({

display:false,
url:undefined,

showQR:(login)=>{

set({display:true,url:`${process.env.REACT_APP_API}/${login}qr`})},

hideQR:()=>{set({display:false})}



    
}))