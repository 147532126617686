import { useState } from "react";
import styles from "./Messangers.module.css";
import { Input } from "rsuite";


Messangers.defaultProps={
  dafaultValue:{
    whatsapp:'380',
    skype:'',
    viber:'+380',
    telegram:'',
  }
}


function Messangers({dafaultValue,onChange}) {

let [messangers,setMessangers]=useState(dafaultValue)

let inputHandler =(v,e)=>{

  let value =v
  let id=e.target.id

  setMessangers({...messangers,[id]:value})
  onChange({...messangers,[id]:value})

}


  return <>

      <Input
        id="whatsapp"
        onChange={inputHandler}
        value={messangers?.whatsapp}
        placeholder="WhatsApp" 
        />
        <Input
        id="skype"
        onChange={inputHandler}
        value={messangers?.skype}
        placeholder="Skype" 
        />
        <Input
        id="viber"
        onChange={inputHandler}
        value={messangers?.viber}
        placeholder="Viber" 
        />
        <Input
        id="telegram"
        onChange={inputHandler}
        value={messangers?.telegram}
        placeholder="Telegram" 
        />

  
  </>;
}

export default Messangers;
