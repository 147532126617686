import { useNavigate } from "react-router-dom";
import { ModalWindow } from "../PageWindow/PageWindow";
import styles from "./CreatePageModal.module.css";
import { useCreatePageModal } from "./useCreatePageModal";
import { Radio, RadioGroup,Button } from 'rsuite';

function CreatePageModal() {

let navigate =useNavigate()  

let {
  
  display,
  inputHandler,
  next,
  hideModal

}=useCreatePageModal()

  
if(display){

  return <>
  <ModalWindow title='Створити нову сторінку'>

       <RadioGroup name="radioList">
        
        <Radio onChange={inputHandler}   value="personal">Особиста сторінка</Radio>
        <Radio onChange={inputHandler} value="restaurant">Ресторан</Radio>
        <Radio onChange={inputHandler} value="event">Подія</Radio>
        <Radio onChange={inputHandler} value="review">Відгук</Radio>
        <Radio onChange={inputHandler} value="wifi">Wi-Fi</Radio>
        <Radio onChange={inputHandler} value="web">Веб-сторінка</Radio>
        <Radio onChange={inputHandler} value="location">Локація</Radio>
        <Radio onChange={inputHandler} value="links">Links</Radio>
        <Radio onChange={inputHandler} value="action">Action</Radio>
        <Radio onChange={inputHandler} value="business">Business</Radio>


       </RadioGroup>

      <Button onClick={()=>{next(navigate)}} color="blue" appearance="primary">Далі</Button>
      <Button onClick={hideModal} color="blue" appearance="ghost">Закрити</Button>

  </ModalWindow>
  
  </>
}


}

export default CreatePageModal;
