import { useNavigate, useParams } from "react-router-dom";
import { InputsContainer, PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import Header from "../../../Components/Header/Header";
import styles from "./Review.module.css";
import Switcher from "../../../Components/Switcher/Switcher";
import { useReview } from "./useReview";
import { Input, Rate } from "rsuite";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import PhotoUploader from "../../../Components/PhotoUploader/PhotoUploader";
import { frontDB } from "../../../helpers/frontDB";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import moment from "moment";
import { sortReviews } from "../../../helperFunctions";
import { useEffect } from "react";
import { calculateAvarageRateByCategories } from "../../PublicPages/helper";


function Review() {

  let navigate =useNavigate()

  let {mode}=useParams()
  let {user}=frontDB()

  let {
    page,
    inputHandler,
    section,
    setSection,
    folderInputHandler,

    addPage,
    updatePage,
    closeWindow,

    categories,
    categoryInputHandler,
    addCategory,
    deleteCategory,
    changePhoto,
    sortHandler,
   
    sortSettings,

  }=useReview()



let categoryRates = page.reviews.map(review=>review.categories).flat()

let avarageRateByCategories =calculateAvarageRateByCategories(categoryRates)


// total numberOfRates avarageRate



let generalSection = <>

<Input
  id="name"
  onChange={inputHandler}
  value={page?.name}
  placeholder="Назва" 
/>

<Input
  id="title"
  onChange={inputHandler}
  value={page?.title}
  placeholder="Заголовок" 
/>

<Input
  id="about"
  as={'textarea'}
  rows={3}
  onChange={inputHandler}
  value={page?.about}
  placeholder="Опис" 
/>

<Input
  id="googleLink"
  onChange={inputHandler}
  value={page?.googleLink}
  placeholder="Посилання на Google Карти" 
/>


<div style={{fontWeight:'bold'}}>Категорії для оцінки</div>


{categories.map((category,i)=>(
  <Category
    key={category.id}
    value={category?.name}
    onChange={(v,e)=>{categoryInputHandler(v,e,i)}}
    onDelete={()=>{deleteCategory(category.id)}}
  />
))}


<div className="link" onClick={addCategory}> + Додати категорію</div>

</>

let photoSection = <>

<div className={styles.photo_container}>

  <PhotoUploader
    type='photo'
    onUploaded={(url)=>{changePhoto({type:'photo',url})}} 
    url={page?.photo} 
  />

</div>

</>


let othersSection = <>


<select
value={page?.folderId} 
id="folderId"  
placeholder={'Оберіть папку'}
onChange={folderInputHandler}>
{[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>

</>

let reviewSection = <>



{avarageRateByCategories.map(avarageRateByCategory=><div>{avarageRateByCategory.categoryName} :{avarageRateByCategory.averageRate} </div>)}




<div className={styles.sort_links}>
  <div id='date' onClick={sortHandler} >{sortSettings.byDate==-1 ? 'To oldest':'To newest'}</div>
  <div id='rate' onClick={sortHandler} >{sortSettings.byRate==-1 ? 'To lowest':'To biggest'}</div>
</div>

{page.reviews.map(review=>(

<ReviewItem

time={moment(review?.timeStamp).format('DD.MM.YYYY HH:mm')}
author={review?.author}
avarageRate={review?.avarageRate}
categories={review?.categories}
rate={review?.rate}
message={review?.message}

/>


))}






</>


  return <>
  <Header/>
  <PageContainer>
    <PageTitle title ={mode=='add'?'Нова сторінка (Відгук)':'Редагувати сторінку (Відгук)'}/>
    
    <Switcher
        links={['Основне','Фото','Інше','Відгуки']}
        section={section} 
        setSection={setSection}
    /> 

   <InputsContainer>
   
  {section == 'Основне' && generalSection}
  {section == 'Фото' && photoSection}
  {section == 'Інше' && othersSection}
  {section == 'Відгуки' && reviewSection}

   
   </InputsContainer>


  
  <BottomButtons
      mode={mode}
      onCreate={()=>{addPage(navigate)}}
      onUpdate={()=>{updatePage(navigate)}}
      onCancel={()=>{closeWindow(navigate)}}
  
  
  
  
  />


  </PageContainer>
  
  </>;
}

export default Review;




//! Допоміжні


function Category({value,onChange,onDelete}){
  return <>
    <div className={styles.category_container}>
    <Input
      id="name"
      onChange={onChange}
      value={value}
      placeholder="Назва категорії" 
    />  
  <WarningRoundIcon style ={{color:'red'}} onClick={onDelete}/>
</div>
  </>
}



function ReviewItem({time,author,categories,avarageRate,message}){


  return <>
  
  <div className={styles.review_item_container}>

  <div className={styles.property}>Час:</div>
  <div>{time}</div>

  <div className={styles.property}>Автор:</div>
  <div>{author}</div>

  <div className={styles.property}>Середня оцінка:</div>
  <strong >{avarageRate}</strong>

  <div className={styles.property}>По категоріям:</div>

  <div className={styles.review_item_categories}>
  {categories.map(category=><div className={styles.item_category_container}>{category.categoryName} - {category.rate ? category.rate :'Не оцінено'} <Rate  value={category.rate} readOnly/> </div>)}
  </div>


  <div className={styles.property}>Повідомлення:</div>
  <div>{message}</div>

  </div>
  </>

}
