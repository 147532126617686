import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../Components/Header/Header";
import styles from "./Restaurant.module.css";
import { useRestaurant } from "./useRestaurant";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import Switcher from "../../../Components/Switcher/Switcher";
import {Input,Checkbox, Toggle, InputPicker,Divider} from 'rsuite';
import PhotoUploader from "../../../Components/PhotoUploader/PhotoUploader";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import { useMenuCategory } from "./Components/MenuCategory/useMenuCategory";
import { frontDB } from "../../../helpers/frontDB";
import Select from 'react-select';
import GoogleMaps from "../../../Components/GoogleMaps/GoogleMaps";
import moment from "moment";
import { Feedback } from "../Personal/Personal";
import { feedbackOptions } from "../../../helperFunctions";
import SocialNetworks from "../../../Components/SocialNetworks/SocialNetworks";
import Messangers from "../../../Components/Messangers/Messangers";
import { useTableModal } from "./Components/TableModal/useTableModal";
import TableModal from "./Components/TableModal/TableModal";
import QRCode from 'qrcode.react';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { Order } from "../Business/Business";
import { useOrderDetailsModal } from "./Components/OrderDetailsModal/useOrderDetailsModal";
import OrderDetailsModal from "./Components/OrderDetailsModal/OrderDetailsModal";
import Promocodes from "../../../Components/Promocodes/Promocodes";
import StatsBlock from "../../../Components/StatsBlock/StatsBlock";
import { formatDate, orderStatuses } from "../../PublicPages/helper";
import { useEffect } from "react";
import AccessManager from "../../AccountSettings/Components/AccessManager/AccessManager";
import { useRef } from "react";
import PaymentsSection from "../../../Components/PaymentsSection/PaymentsSection";
import NotificationSection from "../../../Components/NotificationSection/NotificationSection";



function Restaurant() {

  let navigate =useNavigate()

  let {mode}=useParams()
  let {user}=frontDB()

  let {
    page,
    inputHandler,
    section,
    setSection,
    addPage,
    updatePage,
    closeWindow,
    schedule,
    scheduleInputHandler,
    menu,
    deleteCategory,
    folderInputHandler,
    changePhoto,
    locationHandler,

    appointmentBlockHandler,
    feedbackTypeHandler,
    onMessangersChange,
    onSocialNetworksChange,
    whatsappHandler,
    ordersHandler,
    orderStatus,
    orderStatusHandler,
    deliveryOptionHandler,
    orderPaymentHandler,

  }=useRestaurant()

  let {
    showAddCategoryWindow,
    showCategoryEditWindow,
    handleFileUpload,
  }=useMenuCategory()

let {showAddTable,showEditTable,deleteTable}=useTableModal()
let {showOrderDetails}=useOrderDetailsModal()




let filteredOrders = page?.orders?.filter(order=>{
  if(orderStatus=='all') return order
  return order.status==orderStatus
})

const fileInputRef = useRef(null);


useEffect(()=>{
  
},[orderStatus])



//Розділи

let aboutSection = <>
      <Input
        id="name"
        onChange={inputHandler}
        value={page?.name}
        placeholder="Назва закладу" 
        />
    
    <Input
        id="phone"
        onChange={inputHandler}
        value={page?.phone}
        placeholder="Телефон" 
        />
    
    <Input
        id="email"
        onChange={inputHandler}
        value={page?.email}
        placeholder="Email" 
    />

  <Input
        id="website"
        onChange={inputHandler}
        value={page?.website}
        placeholder="Веб сайт" 
    />
  <Input
        id="city"
        onChange={inputHandler}
        value={page?.city}
        placeholder="Місто" 
    />

  <Input
        id="street"
        onChange={inputHandler}
        value={page?.street}
        placeholder="Адреса" 
    />

  <Input
        as="textarea" rows={3}
        id="about"
        onChange={inputHandler}
        value={page?.about}
        placeholder="Опис" 
    />

</>

let photoSection = <>

<PhotoUploader
  type={'photo'}
  onUploaded={(url)=>{changePhoto({type:'photo',url})}}
  url={page?.photo} 
/>


<PhotoUploader
  type={'backgroundPhoto'}
  onUploaded={(url)=>{changePhoto({type:'backgroundPhoto',url})}}
  url={page?.backgroundPhoto} 
/>


</>

let messengersSection =<>

<Messangers
  dafaultValue={page?.messengers} 
  onChange={onMessangersChange}
/>

</>

let socialNetworksSection = <>

<SocialNetworks
  dafaultValue={page?.socialNetworks} 
  onChange={onSocialNetworksChange}
/>

</>

let scheduleSection = <>

{ Object.keys(schedule).map((day) => {
      let { from, to } = schedule[day];

      return <Day onChange={(value,event)=>{scheduleInputHandler(value,day,event)}} day={day} from={from} to={to}/>
    
      })} 
</>

let menuSection = <>

<div>Категорії меню</div>

{menu.map(category=>(
<MenuCategory 
  key={category?.id} 
  name={category?.name} 
  onEdit={()=>{showCategoryEditWindow(navigate,category?.id)}}
  onDelete={()=>{deleteCategory(category?.id)}}
  />))}


<div onClick={()=>{showAddCategoryWindow(navigate)}} className={styles.link}> + Додати категорію</div>

      <input  ref={fileInputRef}  type="file" accept=".csv" onChange={handleFileUpload} />
      <button onClick={()=>{fileInputRef.current.value = ''}}>Clear File Input</button>


</>

let othersSection = <>

    


<select
value={page?.folderId} 
id="folderId"  
placeholder={'Оберіть папку'}
onChange={folderInputHandler}>
{[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>


<div>WiFi Доступ:</div>

<Input
        id="ssid"
        onChange={inputHandler}
        value={page?.ssid}
        placeholder="SSID" 
        />
 <Input
        id="ssidPassword"
        onChange={inputHandler}
        value={page?.ssidPassword}
        placeholder="Password" 
        />   

</>

let locationSection=<>

  <div>Широта:</div>
  <Input
  id="lat"
  onChange={locationHandler}
  value={page?.location?.lat}
  placeholder="Широта" 
  />

  Довгота:
  <Input
  id="lng"
  onChange={locationHandler}
  value={page?.location?.lng}
  placeholder="Довгота" 
  />

  <GoogleMaps lat={+page?.location?.lat} lng={+page?.location?.lng}/>



</>

let appointmentsSection=<>

<Checkbox onChange={appointmentBlockHandler} checked={page?.appointmentBlock}>Відображати блок бронювання</Checkbox>


Записи:
{page?.appointments.map(appointment=>(

  <Feedback
    key={appointment?.id}
    name={appointment?.name}
    phone={appointment?.phone}
    email={appointment?.email}
    timestamp={moment(appointment?.timestamp).format('DD.MM.YYYY')}
    message={appointment?.message}
    date={appointment?.date}
    time={appointment?.time}

/>
))
}



</>

let feedbackSection = <>



<div>Тип зворотнього звязку:</div>

<Select
  name="feedbackType"
  placeholder='Оберіть тип зворотнього звязку'
  value={feedbackOptions.find(item=>item.value===page?.feedbackType)} 
  onChange={feedbackTypeHandler}
  options={feedbackOptions}
/>



{page?.feedbackType=='Whatsapp'&&   <>  

<div>Номер whatsapp</div>
        <Input
        id="whatsapp"
        onChange={whatsappHandler}
        value={page?.messengers?.whatsapp || '+380'}
        placeholder="WhatsApp" 
        />

</>
}

<div>Повідомлення</div>


{

page?.feedbacks?.map(feedback=>(

<Feedback
key={feedback?.id}
name={feedback?.name}
phone={feedback?.phone}
email={feedback?.email}
timestamp={moment(feedback?.timestamp).format('DD.MM.YYYY')}
message={feedback?.message}
/>

))
}





</>

let tablesSection = <>

<div className="link" onClick={showAddTable}>+ Додати столик</div>

{page?.tables.map(table=>(

    <Table
      pageLogin={page?.login}
      number={table.number}
      onClick={()=>{showEditTable(table.id)}}
      onDelete={()=>{deleteTable(table.id)}}
      responsible={table?.responsible}
      status={table?.status}

    />

))}





</>

let ordersSection = <>

<div className={styles.deliveryTypes}>
  <span>Приймати замовлення: &nbsp; 
    <Toggle onChange={ordersHandler} checked={page?.allowOrders} size={'sm'}/> 
  </span>

  <span>На адресу: &nbsp; 
    <Toggle 
    onChange={(v)=>{deliveryOptionHandler('toTheAddress',v)}}
    checked={page?.orderSettings?.deliveryType?.toTheAddress} 
    size={'sm'}
    /> 
  </span>

  <span>На виніс :&nbsp; 
    <Toggle 
    onChange={(v)=>{deliveryOptionHandler('takeaway',v)}}
    checked={page?.orderSettings?.deliveryType?.takeaway} 
    size={'sm'}/> 
  </span>


  <span>В ресторані: &nbsp; 
    <Toggle 
    onChange={(v)=>{deliveryOptionHandler('inRestaurant',v)}}
    checked={page?.orderSettings?.deliveryType?.inRestaurant} 
    size={'sm'}/> 
  </span>
</div>

<Divider/>

<span>Оплата на сайті: &nbsp; 
  <Toggle 
    onChange={(v)=>{orderPaymentHandler('online',v)}}
    checked={page?.orderSettings?.paymentType?.online} 
    size={'sm'}
    /> 
  </span>

  <span>Оплата на місці: &nbsp; 
  <Toggle 
    onChange={(v)=>{orderPaymentHandler('offline',v)}}
    checked={page?.orderSettings?.paymentType?.offline} 
    size={'sm'}
    /> 
  </span>




  <StatsBlock
  pageType={page?.type}
  orders={page?.orders}
  />

 

  <div>Замовлення:</div>

  <InputPicker
      value={orderStatus}
      onChange={orderStatusHandler}
      cleanable={false}
      placeholder="Оберіть статус"
      data={[{value:'all',label:'all'},...orderStatuses]}
      style={{ width: 364 }}
  />

  {filteredOrders.map(order=>(
    <Order
    clientName={order.tableNumber ? `Столик № ${order.tableNumber}` :order?.info.delivery}
    responsible={order?.responsible}
    createdAt={moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}
    onClick={()=>{showOrderDetails(order.id)}}
    status={order?.status}
    paid={order?.paid ? 'Так' : 'Ні'}

    />
  ))}

</>

let promocodesSection = <>
<Promocodes 
  pageType='restaurant' 
  promocodes={page?.promocodes}
/>

</>

let accessSection =<>
<AccessManager  pageId={page._id}/>

</>

const clientRequestsSection = <>

{page.requests && page.requests.map(request=>(<>
  <ClientRequest date={request.date} msg={request.message}/>
</>))}

</>

const paymentsSection =<>
 <PaymentsSection enablePayPal={page?.enablePayPal} pageType='restaurant' pageId={page?._id}/>

</>

const notificationSection = <>

<NotificationSection notifications={page.notifications}/>

</>

//! Render
  return <>
  <Header/>

    <div className={styles.header}>
    <div className={styles.title}>
      {mode=='add'?'Нова сторінка (Ресторан)':'Редагувати сторінку (Ресторан)'}
    </div>
    
    <Switcher
    links={[
      'Про заклад',
      'Фото',
      'Месенджери',
      'Соц мережі',
      'Розклад',
      'Меню',
      'Інше',
      'Зворотній звязок',
      'Бронювання',
      'Місце розташування',
      'Столики',
      'Замовлення',
      'Промокоди',
      'Доступи',
      'Запити клієнтів',
      'Платежі',
      "Сповіщення",

    ]}
    section={section} 
    setSection={setSection}
    />


   </div>


   <div className={styles.container}>


    {section=='Про заклад'&& aboutSection}
    {section=='Фото'&& photoSection}
    {section=='Месенджери'&& messengersSection}
    {section=='Соц мережі'&& socialNetworksSection}
    {section=='Розклад'&& scheduleSection}
    {section=='Меню'&& menuSection}
    {section=='Інше'&& othersSection}
    {section=='Місце розташування' && locationSection}
    {section=='Зворотній звязок' && feedbackSection}
    {section=='Бронювання' && appointmentsSection}
    {section=='Столики' && tablesSection}
    {section=='Замовлення' && ordersSection}
    {section=='Промокоди' && promocodesSection}
    {section=='Доступи' && accessSection}
    {section=='Запити клієнтів' && clientRequestsSection}
    {section=='Платежі' && paymentsSection}
    {section=='Сповіщення' && notificationSection}




   </div>
   
   <BottomButtons
    mode={mode} 
    onCreate={()=>{addPage(navigate)}}
    onUpdate={()=>{updatePage(navigate)}}
    onCancel={()=>{closeWindow(navigate)}}
   /> 
  
  <TableModal/>
  <OrderDetailsModal pageType={page?.type} pageId={page?._id}/>
  </>;
}

export default Restaurant;

//c Допоміжні

function Day ({onChange,day,from,to}) {

  let weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  let ukrWeekdays = ["Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота", "Неділя"];
  let i =weekdays.indexOf(day)
  day =ukrWeekdays[i]


  return <>
  
  <div className={styles.day_container}>

  <div>{day}</div>

  <Select
  name="from"
  value={timeOptions.find(time=>time.value===from)} 
  onChange={onChange}
  options={timeOptions}
/>

<Select
  name="to"
  value={timeOptions.find(time=>time.value===to)} 
  onChange={onChange}
  options={timeOptions}
/>

{/* <div 
style={{display:'flex',alignItems:'center'}}
>Вихідний: <Checkbox onChange={(v,c)=>{}} />
</div> */}

  </div>
  
  
  </>
}


function MenuCategory ({name, onEdit,onDelete}) {
  return <>
  
  <div className={styles.menu_category_container}>

   <div  onClick={onEdit}>{name}</div>
   <WarningRoundIcon onClick={onDelete} style={{color:'red'}}/>

  </div>
  </>
}



let times = [

  '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', 
  '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', 
  '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', 
  '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', 
  '22:00', '22:30', '23:00'
]


let timeOptions = times.map(time=>({value:time,label:time}))


function Table ({pageLogin,number,onClick,onDelete,status,responsible}) {


  const handleDownload = () => {
    const canvas = document.getElementById('qr');
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `table${number}.png`;
    link.click();
  };


  return <>
  
  <div className={styles.table_container}>

  <QRCode id="qr"  value={`${process.env.REACT_APP_API}/${pageLogin}qr/order/?tableNumber=${number}`}  />
  <div style={{cursor:'pointer'}} onClick={onClick}>Номер столику: &nbsp;&nbsp; <strong>{number}</strong></div>
  <FileDownloadIcon onClick={handleDownload}  style={{color:'var(--primary)',cursor:'pointer'}}/>
  <div>{status}</div>
  <div>Responsible:{responsible}</div>

  <WarningRoundIcon onClick={onDelete}/>
  
  </div>
  </>
}



export const ClientRequest =({date,msg})=>{


  return <>
  
  <div className={styles.clientRequest_container}>
     <div>{formatDate(date)}</div>
     <strong>{msg}</strong>
  </div>
  
  
  </>
}