import {create} from 'zustand'
import { api } from '../../helpers/api'
import { getUserRole } from '../../helpers/access'



export let useLogin = create((set,get)=>({

    form:{email:undefined,password:undefined},

    inputHandler:(v,e)=>{
    
        set({form:{...get().form,[e.target.id]:v}})  
    
    },
      
    
    login: async()=>{
        
        let data = await api.user.login(get().form) 
        
        if(data) {
            let role = getUserRole()
            if(['owner','admin'].includes(role)) window.location.href='/pages'
            if(role=='orderManager') window.location.href='/orders-management'
        }
        
    }
    



    
}))