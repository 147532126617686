import {create} from 'zustand'
import { frontDB } from '../../../helpers/frontDB'
import { showToast } from '../../../Components/Toast/useToast'
import { api } from '../../../helpers/api'



export let useLocation = create((set,get)=>({

    showLocationPageEditData:(pageId)=>{
        let page = frontDB.getState().getPageById(pageId)
        set({page})
    },

    closeWindow:(navigate)=>{
        get().clear()
        navigate(-1)
    },

    page:{

        name:'',
        url:'',
        folderId:'Без папки',

    },

    inputHandler:(v,e)=>{

        set({page:{...get().page,[e.target.id]:v}})
    
    },

    folderInputHandler:(e)=>{
        set({page:{...get().page,[e.target.id]:e.target.value}})
    
    },

    addPage: async (navigate)=>{

        let {page} = get()
        if(page.name==''){showToast('e','Введіть назву сторінки'); return}
    
        page.type='location'
    
        let response = await api.user.addPage(page)
        if(response===true){
    
            get().clear()
            navigate('/pages')
        }
    
    
    },

    updatePage:async(navigate)=>{

        let {page} = get()
    
            
        let response = await api.user.updatePage(page)
        if(response){
    
            get().clear()
            navigate('/pages')
    
        }
            
    },



    clear:()=>{
        set({

            page:{
                name:'',
                url:'',
                folderId:'Без папки',  
            },
           
        })
    }

    
}))