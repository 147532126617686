import PayPal from "../../Components/PayPal/PayPal";
import { LocalStorage } from "../../Services/LocalStorage.service";
import { OrdersService } from "../../Services/Orders.services";
import TimeToPay from "../UserPages/Components/TimeToPay/TimeToPay";




function SandBox() {

const handler =()=>{
  
}

  return <>
  
 <button onClick={handler}>Click</button>
  
  </>;
}

export default SandBox;
