import { showToast } from "../../../../Components/Toast/useToast";
import { frontDB } from "../../../../helpers/frontDB";
import QR from "../QR/QR";
import { useQR } from "../QR/useQR";
import styles from "./Page.module.css";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import { Toggle } from 'rsuite';
import { usePage } from "./usePage";
import BarChartIcon from '@rsuite/icons/BarChart';
import { useNavigate } from "react-router-dom";




function Page({pageId,name,type,onClick,onEdit,login,scans,active}) {
let navigate=useNavigate()
let {changePageStatus}=usePage()
let {showQR}=useQR()

return <>

<QR/>

 <div  className={styles.container}>
  
    <div onClick={onEdit} style={{fontWeight:'bold'}} className={styles.link}>{name}</div>
    <div style={{color:'grey'}}>{type}</div>
    <div onClick={()=>{showQR(login)}} className={styles.link}>Переглянути QR </div>
    <div onClick={()=>{copy(login)}} className={styles.link}>Скопіювати посилання</div>
    <span style={{color:'grey'}}>Переходи: &nbsp;<strong>{scans}</strong></span>
    <BarChartIcon
    onClick={()=>{navigate(`/stats/page/${pageId}`)}} 
    style={{color:'var(--primary)'}}
    />
    <Toggle checked={active} onChange={(checked)=>{changePageStatus(checked,pageId)}} /> 
    <WarningRoundIcon onClick={onClick} style={{color:'#FF8383'}}/>

  </div> 
  
  </>;
}

export default Page;

async function copy(text) {
  try {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_API}/${text}`);
    showToast('s','Посилання скопійовано')
  } catch (error) {
    console.error('Failed to copy text:', error);
  }
}