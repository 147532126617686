import { Toggle } from "rsuite";
import styles from "./PaymentsSection.module.css";
import PayPalCredsModal from "./Components/PayPalCredsModal/PayPalCredsModal";
import { usePayPalCredsModal } from "./Components/PayPalCredsModal/usePayPalCredsModal";
import { usePaymentsSection } from "./usePaymentsSection";
import { useEffect } from "react";
import { formatDate } from "../../Pages/PublicPages/helper";



function PaymentsSection({pageType,pageId,enablePayPal}) {

const {showEditPayPalCreds}=usePayPalCredsModal()
const {paypalHandler,transactions,getTransactions}=usePaymentsSection()



useEffect(()=>{
getTransactions(pageType,pageId)
},[])



  return <>
  <span onClick={()=>{showEditPayPalCreds(pageType)}} className={'link'}>Налаштування PayPal</span>

   <span>Приймати платежі з PayPal  &nbsp; 
    <Toggle onChange={(v)=>{paypalHandler(pageType,v)}} checked={enablePayPal} size={'sm'}/> 
  </span>

  
  <div>Платежі</div>

  {transactions ? transactions.map(transaction=><>

  <Payment
  createdAt={transaction.createdAt}
  sum={transaction.sum}
  />


</>):<></>}


  <PayPalCredsModal/>
  
  </>;
}

export default PaymentsSection;




const Payment =({createdAt,sum})=>{



  return <>
  
  <div className={styles.payment_container}>

    <div>{formatDate(createdAt)}</div>
    <div>{sum} usd</div>
  </div>
  
  </>
}
