import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../../../Components/BaseComponents/BaseComponents";
import Header from "../../../../Components/Header/Header";
import styles from "./PageStats.module.css";
import { usePageStats } from "./usePageStats";
import { useEffect } from "react";
import { Divider, List,Button } from 'rsuite'
import { useScanStats } from "./Components/ScanStats/useScanStats";
import ScanStats from "./Components/ScanStats/ScanStats";
import { frontDB } from "../../../../helpers/frontDB";
import Switcher from "../../../../Components/Switcher/Switcher";
import StatsBlock from "../../../../Components/StatsBlock/StatsBlock";




function PageStats() {

let{updateDB}=frontDB()

let navigate=useNavigate()

let {pageId}=useParams()

let {
  getPage,
  page,
  section,
  setSection,
}=usePageStats()

let {showScanStats}=useScanStats()


useEffect(()=>{
  updateDB('pages')
  getPage(pageId)
},[])



let allStats_section=<>



<div>Всього переходів:{page?.scans.length} </div>
    {page?.scans.map((stats) => (<>
      
        <div
        className={styles.stat} 
        onClick={()=>{showScanStats(stats?.id)}}>


          <div>{stats?.query} </div>
           <div style={{color:'grey'}}>{stats?.type}</div>
          
          
        </div>
        
        </>
    ))}






</>

let ordersSection =<>
<StatsBlock
  pageType={page?.type}
  orders={page?.orders}
/>

</>


  return (
    <>



      <Header />
      <PageContainer>
      <PageTitle title={`Статистика : ${page?.name}`} />

      <Switcher
    links={[
    'Переходи',
    'Замовлення',
   
  ]}
    section={section} 
    setSection={setSection}
    />



        {section=='Переходи'&& allStats_section}
        {section=='Замовлення'&& ordersSection}


      </PageContainer>

<ScanStats />

    </>
  );
}

export default PageStats;
