import { ModalWindow } from "../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./ReviewModal.module.css";
import { Input,Button } from "rsuite";
import { useReviewModal } from "./useReviewModal";
import PhotoUploader from "../../../../../Components/PhotoUploader/PhotoUploader";



function ReviewModal() {

let {
  display,
  mode,
  review,
  inputHandler,
  onPhotoChange,
  addReview,
  updateReview,
  closeReviewModal,

}=useReviewModal()


  if(display) return <>

  <ModalWindow title={mode=='add'?'Додати відгук':'Редагувати відгук'}>

<div className={styles.photo_container}>

<PhotoUploader 
  type={'photo'}
  url={review?.photo}
  onUploaded={onPhotoChange}
/>


</div>



  <Input
    id="name"
    onChange={inputHandler}
    value={review?.name}
    placeholder="Ім'я" 
    />

  <Input
    id="position"
    onChange={inputHandler}
    value={review?.position}
    placeholder="Посада" 
    />

  <Input
    id="company"
    onChange={inputHandler}
    value={review?.company}
    placeholder="Компанія" 
    />

  <Input
    as='textarea'
    rows={3}
    id="message"
    onChange={inputHandler}
    value={review?.message}
    placeholder="Повідомлення" 
    />


    <Button
    onClick={mode=='add'?addReview:updateReview}  
    color="blue"
    appearance="primary"

    >Зберегти</Button>


 <Button 
   onClick={closeReviewModal}
   color="blue" 
   appearance="ghost"

   >Закрити</Button>
  </ModalWindow>
  
  </>;
}

export default ReviewModal;
