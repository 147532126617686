import {create} from 'zustand'
import { useLinks } from '../../useLinks'
import shortid from 'shortid'



export let useLinksModal = create((set,get)=>({

    display:undefined,
    mode:undefined,
    

    showAddLinkModal:()=>{set({display:true,mode:'add'})},

    showEditLinkModal:(linkId)=>{
        let {page}=useLinks.getState()
        let link = page.links.find(link=>(linkId==link.id))     
        set({link,display:true,mode:'edit'})
    },
    
    closeLinkModal:()=>{set({display:false,});get().clear()},


    link:{photo:'',name:'',description:'',url:''},

    inputHandler:(v,e)=>{

        set({link:{...get().link,[e.target.id]:v}})
    
    },
    
    onPhotoChange:(url)=>{
        set({link:{...get().link,photo:url}})
    },
    
    
    
    addLink:()=>{

        let {page}=useLinks.getState()
        let {link}=get()
        link.id=shortid.generate()
        page.links = [...page.links,link]
        useLinks.setState({page})
        get().closeLinkModal()
    
    },
    
    updateLink:()=>{
    
        let {link} = get()
        let linkId=link.id
        let {page}=useLinks.getState()
        let index = page.links.findIndex(link=>(linkId==link.id))
        page.links[index]=get().link
        useLinks.setState({page})
        get().closeLinkModal()
    },
    
    deleteLink:(linkId)=>{
    
        let {page}=useLinks.getState()
        let links = page.links.filter(link=>(linkId!=link.id))
        page.links=links
        useLinks.setState({page})
    
    },
    
    
    clear:()=>{
        set({
            mode:undefined,
            link:{photo:'',name:'',description:'',url:''},

        })
    }
  
    
}))