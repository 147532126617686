import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import Header from "../../../Components/Header/Header";
import PhotoUploader, { Photo } from "../../../Components/PhotoUploader/PhotoUploader";
import styles from "./Personal.module.css";
import { usePersonal } from "./usePersonal";
import { Input,InputPicker,Checkbox,Toggle } from 'rsuite';
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import { useNavigate, useParams } from "react-router-dom";
import { frontDB } from "../../../helpers/frontDB";
import ResumeUploader from "./Components/ResumeUploader/ResumeUploader";
import Select from 'react-select';
import moment, { fn } from "moment";
import { useReviewModal } from "./Components/ReviewModal/useReviewModal";
import ReviewModal from "./Components/ReviewModal/ReviewModal";
import GalleryUploader from "../../../Components/GalleryUploader/GalleryUploader";
import Switcher from "../../../Components/Switcher/Switcher";
import GoogleMaps from "../../../Components/GoogleMaps/GoogleMaps";
import { currencyOptions, feedbackOptions } from "../../../helperFunctions";
import Messangers from "../../../Components/Messangers/Messangers";
import SocialNetworks from "../../../Components/SocialNetworks/SocialNetworks";
import ButtonModal from "./Components/ButtonModal/ButtonModal";
import { useButtonModal } from "./Components/ButtonModal/useButtonModal";
import { Order } from "../Business/Business";
import { useOrderDetailsModal } from "./Components/OrderDetailsModal/useOrderDetailsModal";
import OrderDetailsModal from "./Components/OrderDetailsModal/OrderDetailsModal";
import Promocodes from "../../../Components/Promocodes/Promocodes";
import StatsBlock from "../../../Components/StatsBlock/StatsBlock";
import PaymentsSection from "../../../Components/PaymentsSection/PaymentsSection";

//Switcher

function Personal() {

let navigate=useNavigate()
let {user}=frontDB()
let {mode}=useParams()

let {
  section,
  page,
  inputHandler,
  folderInputHandler,

  
  whatsappHandler,

  services,
  serviceInputHandler,

  addService,
  deleteService,

  addPage,
  updatePage,

  closeWindow,
  changePhoto,
  onResumeDelete,

  schedule,
  scheduleInputHandler,

  feedbackTypeHandler,
  pageStatusHandler,
  setSection,
  locationHandler,
  appointmentBlockHandler,
  serviceStatusHandler,
  onMessangersChange,
  onSocialNetworksChange,
  currencyHandler,
  ordersHandler,

}=usePersonal()


let {

  showAddReviewModal,
  showEditReviewModal,
  deleteReview,

}=useReviewModal()

let {
  showAddButtonModal,
  deleteButton,
  showEditButtonModal,
}=useButtonModal()

let {showOrderDetails}=useOrderDetailsModal()


let aboutMeSection = <>

<Input
        id="name"
        onChange={inputHandler}
        value={page?.name}
        placeholder="ПІБ" 
        />
    
    <Input
        id="phone"
        onChange={inputHandler}
        value={page?.phone}
        placeholder="Телефон" 
        />
    
    <Input
        id="email"
        onChange={inputHandler}
        value={page?.email}
        placeholder="Email" 
    />
    
    
    <div style ={{display:'flex',columnGap:'20px'}}>
    <Input
        id="country"
        onChange={inputHandler}
        value={page?.country}
        placeholder="Країна" 
    />
    <Input
        id="city"
        onChange={inputHandler}
        value={page?.city}
        placeholder="Місто" 
    />
    </div>
    
    <Input
        id="website"
        onChange={inputHandler}
        value={page?.website}
        placeholder="Веб сайт" 
    />
    
    <Input
        id="company"
        onChange={inputHandler}
        value={page?.company}
        placeholder="Компанія" 
    />
    <Input
        id="proffesion"
        onChange={inputHandler}
        value={page?.proffesion}
        placeholder="Професія" 
    />
    
    <Input
        as="textarea" rows={3}
        id="about"
        onChange={inputHandler}
        value={page?.about}
        placeholder="Опис" 
    />


   <Input
        as="textarea" rows={3}
        id="education"
        onChange={inputHandler}
        value={page?.education}
        placeholder="Освіта" 
    />

   <Input
        as="textarea" rows={3}
        id="workExperience"
        onChange={inputHandler}
        value={page?.workExperience}
        placeholder="Досвід роботи" 
    />
<div className={styles.cv_container}>
 

<ResumeUploader 

fileName={page?.resume?.fileName}
url={page?.resume?.url}
onResumeDelete={onResumeDelete}

/>



</div>


</>

let photoSection = <>


<PhotoUploader
  type={'photo'}
  onUploaded={(url)=>{changePhoto({type:'photo',url})}}
  url={page?.photo} 
/>

<PhotoUploader
  type={'backgroundPhoto'}
  onUploaded={(url)=>{changePhoto({type:'backgroundPhoto',url})}}
  url={page?.backgroundPhoto} 
/>



</>

let socialNetworksSection =<>

<SocialNetworks
  dafaultValue={page?.socialNetworks} 
  onChange={onSocialNetworksChange}
/>

</>

let messengersSection=<>

<Messangers
  dafaultValue={page?.messengers} 
  onChange={onMessangersChange}
/>

</>

let servicesSection = <>

{services.map((service,i)=>(
    <Service
    key={i} 
    onChange={(v,e)=>{serviceInputHandler(v,e,i)}}
    onCurrencyChange={(v)=>{currencyHandler(v,i)}} 
    onDelete={()=>{deleteService(i)}}
    name={service?.name} 
    price={service?.price}
    currency={service?.currency}
    status={service?.active}
    statusHandler={(v)=>{serviceStatusHandler(v,i)}}

    
  />))} 

  <div className={styles.link} onClick={addService}>+ Додати послугу</div>
  <div style={{height:'50px'}}></div>

</>

let othersSection = <>


<select
value={page?.folderId} 
id="folderId"  
placeholder={'Оберіть папку'}
onChange={folderInputHandler}>
{[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>

<Checkbox onChange={pageStatusHandler} checked={page?.active}>Відображати сторінку</Checkbox>

</>

let scheduleSection = <>

{ Object.keys(schedule).map((day) => {
      let { from, to } = schedule[day];

      return <Day onChange={(value,event)=>{scheduleInputHandler(value,day,event)}} day={day} from={from} to={to}/>
    
      })} 
</>

let feedbackSection = <>



<div>Тип зворотнього звязку:</div>

<Select
  name="feedbackType"
  placeholder='Оберіть тип зворотнього звязку'
  value={feedbackOptions.find(item=>item.value===page?.feedbackType)} 
  onChange={feedbackTypeHandler}
  options={feedbackOptions}
/>




{page?.feedbackType=='Whatsapp'&&   <>  

<div>Номер whatsapp</div>
        <Input
        id="whatsapp"
        onChange={whatsappHandler}
        value={page?.messengers?.whatsapp || '+380'}
        placeholder="WhatsApp" 
        />

</>
}

<div>Повідомлення</div>


{

page?.feedbacks?.map(feedback=>(

<Feedback
key={feedback?.id}
name={feedback?.name}
phone={feedback?.phone}
email={feedback?.email}
timestamp={moment(feedback?.timestamp).format('DD.MM.YYYY')}
message={feedback?.message}
/>

))
}





</>

let reviewsSection = <>

<div onClick={showAddReviewModal} className={'link'}> + Додати відгук</div>


{page?.reviews.map(review=>(

<Review
  photo={review?.photo}
  name={review?.name}
  position={review?.position}
  company={review?.company}
  message={review?.message}
  onClick={()=>(showEditReviewModal(review?.id))}
  onDelete={()=>{deleteReview(review?.id)}}
/>

))}








</>

let gallerySection=<>


<GalleryUploader
files={page?.gallery}
/>

</>

let locationSection=<>

  <div>Широта:</div>
  <Input
  id="lat"
  onChange={locationHandler}
  value={page?.location?.lat}
  placeholder="Широта" 
  />

  Довгота:
  <Input
  id="lng"
  onChange={locationHandler}
  value={page?.location?.lng}
  placeholder="Довгота" 
  />

  <GoogleMaps lat={+page?.location?.lat} lng={+page?.location?.lng}/>



</>

let appointmentsSection=<>

<Checkbox onChange={appointmentBlockHandler} checked={page?.appointmentBlock}>Відображати блок бронювання</Checkbox>



Записи:
{page?.appointments.map(appointment=>(

  <Feedback
    key={appointment?.id}
    name={appointment?.name}
    phone={appointment?.phone}
    email={appointment?.email}
    timestamp={moment(appointment?.timestamp).format('DD.MM.YYYY')}
    message={appointment?.message}
    date={appointment?.date}
    time={appointment?.time}

/>
))
}



</>


let buttonsSection = <>

<div
onClick={showAddButtonModal} 
className={'link'}
>+ Додати кнопку</div>

{page?.buttons.map(button=>(

<UserButton
  type={'edit'}
  photo={button?.photo}
  url={button?.url} 
  name={button?.name}
  onClick={()=>{showEditButtonModal(button.id)}}
  onDelete={()=>{deleteButton(button.id)}}
/>


))}





</>


let ordersSection = <>

<span>Приймати замовлення: &nbsp; 
  <Toggle onChange={ordersHandler} checked={page?.allowOrders} size={'sm'}/> 
</span>

<StatsBlock
  pageType={page?.type}
  orders={page?.orders}
  />



<div>Замовлення:</div>

{page?.orders.map(order=>(
  <Order
    clientName={order?.clientInfo.name}
    createdAt={moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}
    onClick={()=>{showOrderDetails(order.id)}}
    paid={order?.paid ? 'Так' :'Ні'}
/>
))}

</>


let promocodesSection = <>

<Promocodes 
pageType='personal' 
promocodes={page?.promocodes}
/>

</>

const paymentsSection =<>

 <PaymentsSection enablePayPal={page?.enablePayPal} pageType='personal' pageId={page?._id}/>

</>



//! Render

  return <>
  


<Header/>

   <div className={styles.header}>
    <div className={styles.title}>  {mode=='add'?'Нова сторінка (Візитка)':'Редагувати сторінку (Візитка)'}</div>
    
    <Switcher
    links={[
    'Про мене',
    'Фото',
    'Мессенджери',
    'Соц мережі',
    'Послуги',
    'Інше',
    'Зворотній звязок',
    'Відгуки',
    'Бронювання',
    'Розклад',
    'Галерея',
    'Місце розташування',
    'Кнопки',
    'Замовлення',
    'Промокоди',
    'Платежі',
  ]}
    section={section} 
    setSection={setSection}
    />

   </div>

  <div className={styles.container}>

  {section=='Про мене' && aboutMeSection}
  {section=='Фото' && photoSection}
  {section=='Мессенджери' && messengersSection}
  {section=='Соц мережі' && socialNetworksSection}
  {section=='Послуги' && servicesSection}
  {section=='Інше' && othersSection}
  {section=='Зворотній звязок' && feedbackSection}
  {section=='Відгуки' && reviewsSection}
  {section=='Бронювання' && appointmentsSection}
  {section=='Розклад' && scheduleSection}
  {section=='Галерея' && gallerySection}
  {section=='Місце розташування' && locationSection}
  {section=='Кнопки' && buttonsSection}
  {section=='Замовлення' && ordersSection}
  {section=='Промокоди' && promocodesSection}
  {section=='Платежі' && paymentsSection}

  
  </div>

  <BottomButtons

  mode={mode} 
  onCreate={()=>{addPage(navigate)}}
  onUpdate={()=>{updatePage(navigate)}}
  onCancel={()=>{closeWindow(navigate)}}
  
  />  
 <OrderDetailsModal pageType={page?.type} pageId={page?._id}/>
 <ReviewModal/>
 <ButtonModal/> 
  </>;
}

export default Personal;



//c Допоміжні


function Service ({onChange,onCurrencyChange,name,price,currency,onDelete,status,statusHandler}) {


    return <>
    
    <div  className={styles.serviceContainer}>
  
    <Input
        id="name"
        onChange={(v,e)=>{onChange(v,e)}}
        value={name}
        placeholder="Назва послуги" 
    />
  
     <Input
        id="price"
        onChange={(v,e)=>{onChange(v,e)}}
        value={price}
        placeholder="Ціна" 
    />

<InputPicker
    value={currency}
    onChange={onCurrencyChange}
    cleanable={false} 
    data={currencyOptions} 
    style={{ display:'block' }} 
  />

      <span>
      Active: &nbsp; &nbsp; &nbsp;
      <Toggle size={'sm'} checked={status} onChange={statusHandler} />
      </span>

      <WarningRoundIcon onClick={onDelete} style={{color:'#FF8383'}}/>
  
    </div>
    </>
  }


export function Feedback({timestamp,name,phone,email,message,time,date}){
  return <>
  
  <div className={styles.feedback_container}>
<div>{timestamp}</div>
<div>{name}</div>
<div>{phone}</div>
<div>{email}</div>
<div>{message}</div>
<div>{time}</div>
<div>{date}</div>

  </div>
  
  </>
}


export function Review ({photo,name,position,company,message,onClick,onDelete,deleteIcon}) {

  return <>
  
  <div className={styles.review_container}>
    <Photo url={photo}/>
    <div className={'link'} onClick={onClick}>{name}</div>
    <div>{position}</div>
    <div>{company}</div>
    <div>{message}</div>
    <WarningRoundIcon
    style={{opacity:deleteIcon}} 
    onClick={onDelete}
    className={styles.delete_review} 
    color="red"/>

  </div>
  
  </>
}


function Day ({onChange,day,from,to}) {

  let weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  let ukrWeekdays = ["Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота", "Неділя"];
  let i =weekdays.indexOf(day)
  day =ukrWeekdays[i]


  return <>
  
  <div className={styles.day_container}>

  <div>{day}</div>

  <Select
  name="from"
  value={timeOptions.find(time=>time.value===from)} 
  onChange={onChange}
  options={timeOptions}
/>

<Select
  name="to"
  value={timeOptions.find(time=>time.value===to)} 
  onChange={onChange}
  options={timeOptions}
/>

{/* <div 
style={{display:'flex',alignItems:'center'}}
>Вихідний: <Checkbox onChange={(v,c)=>{}} />
</div> */}

  </div>
  
  
  </>
}
let times = [

  '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', 
  '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', 
  '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', 
  '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', 
  '22:00', '22:30', '23:00'
]

let timeOptions = times.map(time=>({value:time,label:time}))




export function UserButton ({type,photo,url,name,onClick,onDelete}) {



  if(type=='edit') return <>
  <div className={styles.button_container}>
  <Photo url={photo}/>
  <div onClick={onClick} className={styles.button_name}>{name}</div>
  <WarningRoundIcon onClick={onDelete}/>
  </div>
  
  </>


if(type=='view') return <>
  <a className={styles.button_container} href={url}>
  <Photo url={photo}/>
  <div onClick={onClick} className={styles.button_name}>{name}</div>
  </a>
  
  </>
}


