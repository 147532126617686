import { useEffect } from "react";
import styles from "./WifiP.module.css";
import { useWifiP } from "./useWifiP";
import QRCode from 'qrcode.react';
import { PublicPageContainer, PublicPageHeader } from "../../../../Components/BaseComponents/BaseComponents";
import {ModalWindow} from "../../../UserPages/Components/PageWindow/PageWindow"



function WifiP() {

let {qrstring,getPage,page}=useWifiP()

useEffect(()=>{

getPage()
},[])

  return <>
        <ModalWindow>
        <PublicPageHeader>
        <div>{page?.name}</div>
      <  QRCode value={`${qrstring};`}/>

        </PublicPageHeader>
        </ModalWindow>

  </>;
}

export default WifiP;
