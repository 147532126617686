import {create} from 'zustand'
import { api } from '../../../../helpers/api'



export let useLocationP = create((set,get)=>({


    getPage:async()=>{
    
        let login = getLoginFromUrl()
        
        if(!login){window.location.href='/login'}
        
        let data = await api.getPageByLogin(login)
        if(!data){window.location.href='/login';return}
        window.location.href=data?.url
        
    },




    
}))

function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
  };