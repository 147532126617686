import {create} from 'zustand'
import { frontDB } from '../../../../helpers/frontDB';
import { api } from '../../../../helpers/api';



export let useFolders = create((set,get)=>({

deleteFolder:async(folderId)=>{

    let user = frontDB.getState().user
    let userFolders = user.folders;
    
    userFolders=userFolders.filter(folder=>(folder.id != folderId))
    
    user.folders=userFolders

    let response = await api.user.updateProfile(user)

}




    
}))