import { ModalWindow } from "../../../../Pages/UserPages/Components/PageWindow/PageWindow";
import styles from "./FileUploaderModal.module.css";
import { Radio,Button,InputPicker, Input } from 'rsuite';
import { useFileUploaderModal } from "./useFileUploaderModal";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import axios from "axios";
import { useState } from "react";
import { api } from "../../../../helpers/api";
import { showToast } from "../../../Toast/useToast";


function FileUploaderModal({}) {
  
 let {
  display,
  section,
  closeFlileUploaderModal,
  setSection,
  addURL,
  urls,
  fileTypeHandler,
  fileUrlHandler,
  deleteURL,
  saveFiles,

 }=useFileUploaderModal()
  
const [selectedFiles, setSelectedFiles] = useState([]);
const [loading, setLoading] = useState(false);

const handleFileChange = (e) => {
  let files= e.target.files
  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
    'image/svg+xml',
    'image/heic',
    'image/heif',
  ];

  for(let file of files) {
    if(!allowedTypes.includes(file.type)){ 
      showToast('e','Only images allowed. Use url method to upload other types')
      return
    }
  }
  setSelectedFiles([...selectedFiles, ...files]);
};

const handleSubmit = async (e) => {
  e.preventDefault();

  if(selectedFiles.length==0) { showToast('e','Choose images');
  return
  }

  setLoading(true)



  const formData = new FormData();

  selectedFiles.forEach((file) => {
    formData.append('files', file);
  });

  let response = await api.user.uploadFiles(formData)

  if(response){
    useFileUploaderModal.setState({urls:[...urls,...response]})
    saveFiles()
    setLoading(false)

  }
}; 

 
  let methodsSection =<>



      <Radio onChange={()=>{setSection('uploadFromDevice')}}>
      Завантажити з пристрою
      </Radio>

      <Radio onChange={()=>{setSection('uploadFromURL')}} >
      Завантажити за посиланням
      </Radio>


    

<Button 
   onClick={closeFlileUploaderModal}
   color="blue" 
   appearance="ghost"
   style={{width:'100%'}}

   >Закрити</Button>

  
  </>
  
  let uploadFromDeviceSection= <>
  
  <form className={styles.form} onSubmit={handleSubmit}>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
      />
    <Button
    loading={loading}
    type="submit" 
    color="blue"
    appearance="primary"
    style={{width:'100%'}}

    >Зберегти</Button>  
</form>


  <Button 
   onClick={closeFlileUploaderModal}
   color="blue" 
   appearance="ghost"
   style={{width:'100%'}}

   >Закрити</Button>
  

  </>
  
  let uploadFromURLSection=<>
  

 {urls.map((url,index)=>(
  <UrlUploader
    type={url?.type}
    url={url?.url}
    onURLChange={(value)=>{fileUrlHandler(value,index)}}
    onTypeChange={(value)=>{fileTypeHandler(value,url?.id,index)}}
    onDelete={()=>{deleteURL(index)}}
  />
 ))}

<div onClick={addURL} className={'link'}>+ Додати</div>


   <Button
    onClick={saveFiles}  
    color="blue"
    appearance="primary"
    style={{width:'100%'}}

    >Зберегти</Button>


  <Button 
   onClick={closeFlileUploaderModal}
   color="blue" 
   appearance="ghost"
   style={{width:'100%'}}

   >Закрити</Button>
  </>


//! Render

  if(display) return <>
  
  <ModalWindow title={'Завантажити файл'}>
    
   {section=='methods'&& methodsSection}
   {section=='uploadFromDevice'&& uploadFromDeviceSection}
   {section=='uploadFromURL'&& uploadFromURLSection}


  </ModalWindow>
  </>;
}

export default FileUploaderModal;




//c Допоміжні

function UrlUploader ({url,onURLChange,onTypeChange,type,onDelete}) {


  return <>

  <div className={styles.url_uploader_container}>
    <InputPicker
    value={type}
    onChange={onTypeChange}
    cleanable={false} 
    data={data} 
    style={{ width: 150 }} 
    />
   <Input
       value={url}
       onChange={onURLChange}
   
   />

   <WarningRoundIcon onClick={onDelete} style={{ color:'red' }} />


  </div>
  

  </>
}

const data = [
  'Photo',
  'Video',
].map(item => ({ label: item, value: item }));
