import {create} from 'zustand'
import { api } from '../../../helpers/api'



export let useAdminUserPages = create((set,get)=>({

userPages:[],

getUserPages:async(userId)=>{

 let  userPages = await api.admin.getUserPages(userId)
 console.log(userPages);
 set({userPages})

}



    
}))