import {create} from 'zustand'
import { frontDB } from '../../helpers/frontDB'



export let useUserPages = create((set,get)=>({


    name:'',
    pages:[],


    searchHandler: (v,e)=>{
        set({name:v})
        get().filter()
    },
   
    filter:(pages)=>{

        if(!pages) return

        let {name}=get()

        let filteredPages = pages.filter((page) => {
            let pageName = page.name.toLowerCase();
            let searchText = name.toLowerCase();
            return pageName.includes(searchText)
        })

        return filteredPages
    
    }


    
}))