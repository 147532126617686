import styles from "./OrderDetailsModal.module.css";
import { Drawer, Toggle } from 'rsuite';
import { useOrderDetailsModal } from "./useOrderDetailsModal";
import { ItemContainer, PublicGridContainer, ServiceContainer } from "../../../../../Components/BaseComponents/BaseComponents";
import { calculateOrderTotal, calculateServiceTotal } from "../../../../../helperFunctions";

function OrderDetailsModal({pageType,pageId}) {


  let {
    display,
    closeModal,
    orderId,
    clientInfo,
    basket,
    total,
    paidStatusHandler,
    order,
  }=useOrderDetailsModal()

  return <>
  
    <Drawer open={display} onClose={closeModal}>
        <Drawer.Header>
          <Drawer.Title> Деталі замовлення: {orderId}</Drawer.Title>
           
          
        </Drawer.Header>
        <Drawer.Body>

        <PublicGridContainer>
    

        <div>Оплачено:</div>
        <Toggle onChange={(v)=>{paidStatusHandler(pageType,pageId,v)}} checked={order?.paid} size={'sm'}/> 


        <div>ПІБ:</div>
       <strong>{clientInfo?.name}</strong>

       <div>Телефон:</div>
       <strong>{clientInfo?.phone}</strong>

       <div>Емейл:</div>
       <strong>{clientInfo?.email}</strong>

       <div>Адреса:</div>
       <strong>{clientInfo?.adress}</strong>

       <div>Повідомлення:</div>
       <strong>{clientInfo?.message}</strong>

       <div>Промокод:</div>
       <strong>{clientInfo?.promocode}</strong>


        </PublicGridContainer>
  

        <h5 style={{marginTop:'50px',marginBottom:'30px'}}>Замовлення:</h5>

<div className={styles.items_container}>
        {basket.map(item=>(
          <ServiceContainer
                allowOrders={false}
                name={item?.itemInfo.name}
                price={item?.itemInfo.price}
                currency={item?.itemInfo.currency}
              />
        ))}

<span ><strong>До сплати:</strong> {total}</span>

</div>


        </Drawer.Body>
  </Drawer>

  
  </>;
}

export default OrderDetailsModal;
