import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";

import { Input,Button} from 'rsuite'
import { useLogin } from "./useLogin";
import { useEffect } from "react";
import { initializeGoogleSignIn } from "../../helpers/access";




function Login() {

let navigate =useNavigate()

let {inputHandler,form,login}=useLogin()




  return <>
  
<div className={styles.outer_container}>


  <div className={styles.container}>

    <div className={styles.header}> Pages</div>
    
    <Input
    id="email"
    onChange={inputHandler}
    value={form?.email}
    placeholder="Email" 
    />

    <Input 
    id="password"
    onChange={inputHandler}
    value={form?.password}
    placeholder="Пароль" 
    />

    <Button 
    appearance="primary"
    style={{width:'100%',height:'40px'}}
    onClick={login}
     
     >Вхід</Button>

    <GoogleButton/>

    <div 
    className={styles.link}
    onClick={()=>{navigate('/signup')}}
    
    >Реєстрація</div>

    
 </div>


</div>


  </>;
}

export default Login;




export function GoogleButton () {

  useEffect(()=>{
    initializeGoogleSignIn()
  },[])

  return <>

       <div className={styles.gbutton} id='signIn' ></div>

  </>
}



