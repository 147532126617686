import { Button,Placeholder,Input } from "rsuite";
import { InputsContainer, ItemContainer, PublicGridContainer, Selector } from "../../../../../../Components/BaseComponents/BaseComponents";
import { ModalWindow } from "../../../../../UserPages/Components/PageWindow/PageWindow";
import { useBusinessP } from "../../useBusinessP";
import styles from "./BasketModal.module.css";
import { useBasketModal } from "./useBasketModal";
import { calculateOrderTotal } from "../../../../../../helperFunctions";
import { timeOptionsForDelivery } from "../../../../helper";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalForPage from "../../../../../../Components/PayPalForPage/PayPalForPage";



function BasketModal({orderSettings}) {

  let {
    
    display,
    closeBasket,
    createOrder,
    inputHandler,
    clientInfo,
    section,
    setSection,
    orderId,
    basket,
    addToBasket,
    checkPromocode,
    total,
    disablePromocodeInput,
    disableCheckPromocodeButton,
    removeFromBasket,
    info,
    onPaymentSuccess,
    onPaymentError,
  
  }=useBasketModal()

const {page}=useBusinessP()



const initialOptions = {
  clientId: page?.paypalCredentials?.clientId,
  currency: "USD",
  intent: "capture",
};





  function getActiveDeliveryOptions (orderSettings) {
    if(!orderSettings) return []

    let options=[]
    if(orderSettings.deliveryType.takeaway) options.push('takeaway') 
    if(orderSettings.deliveryType.toTheAddress) options.push('toTheAddress') 
    return options

  }

  function getActivePaymentMethods (orderSettings) {
    if(!orderSettings) return []
 
     let options=[]
     if(orderSettings.paymentType.online)  options.push('online')
     if(orderSettings.paymentType.offline) options.push('offline') 
     return options
   }

const PayPalButton =<>

<PayPalForPage
    pageType={'business'}
    pageId={page?._id}
    basket={basket}
    onSuccess={onPaymentSuccess}
    onError={onPaymentError}
    
    
    />

</>

const CreateOrderButton =<>

<Button
            color="blue"
            appearance="primary"
            onClick={createOrder}
          >
            Замовити
          </Button>

</>

  let deliveryOptions =getActiveDeliveryOptions (orderSettings).map(
    item => ({ label: item, value: item })
  );

  let paymentTypesOptions =getActivePaymentMethods (orderSettings).map(
    item => ({ label: item, value: item })
  );

  const toTheAdress = <>
  На коли:
  <Selector
    id={'when'}
    value={info?.when}
    placeholder={'Оберіть'}
    onChange={inputHandler}
    data={timeOptionsForDelivery}
/>

  {info?.when=='На певний час' &&
      
      <Input
      type="time"
        id={'time'}
        value={info?.time}
        placeholder={'Оберіть час'}
        onChange={inputHandler}
      />
      
  }

Адреса:
<Input
  id={'address'}
  value={info?.address}
  placeholder={'Вкажіть адресу'}
  onChange={inputHandler}
/>
Імя:
  <Input
    id={'name'}
    value={info?.name}
    placeholder={'Ваше імя'}
    onChange={inputHandler}
  />
Телефон:
<Input
id={'phone'}
value={info?.phone}
onChange={inputHandler}
/>
</>
  const takeaway =   <> 

На коли:
  <Selector
    id={'when'}
    value={info?.when}
    placeholder={'Оберіть'}
    onChange={inputHandler}
    data={timeOptionsForDelivery}
/>

{info?.when=='На певний час' &&
      
      <Input
      type="time"
        id={'time'}
        value={info?.time}
        placeholder={'Оберіть час'}
        onChange={inputHandler}
      />
      
  }
Імя:
  <Input
      id={'name'}
      value={info?.name}
      placeholder={'Ваше імя'}
      onChange={inputHandler}
    />

Телефон:
  <Input
    id={'phone'}
    value={info?.phone}
    onChange={inputHandler}
  />

  </> 


  let itemsSection = <>


  <ModalWindow title={'Корзина'} style={{width:'500px'}}>
   
   {basket.length==0 && <Placeholder rows={8}/>}

   {basket.map(item=>(

    <ItemContainer
    photo={item?.photo}
    name={item?.name}
    price={item?.price}
    currency={item?.currency}
    about={item?.about}
    allowOrders={true}
    defaultType={'quantity'}
    quantity={item?.quantity}

    onIncrease={()=>{addToBasket(item.id)}}
    onDecrease={()=>{removeFromBasket(item.id)}}

  />
   ))}

<span ><strong>До сплати:</strong> {total}</span>

    <Input
        disabled={disablePromocodeInput}
        id="promocode"
        onChange={inputHandler}
        value={clientInfo?.promocode}
        placeholder="Промокод"
      />

       {basket.length!=0 && <>
        <Button
            disabled={disableCheckPromocodeButton}
            color="orange"
            appearance="primary"
            onClick={checkPromocode}
          >
            Застосувати промокод
          </Button>
       
          <Selector
              id={'paymentType'}
              value={info?.paymentType}
              placeholder={'Оберіть варіант оплати'}
              onChange={inputHandler}
              data={paymentTypesOptions}
            />

         <Button
            color="blue"
            appearance="primary"
            onClick={()=>{setSection('опції доставки')}}
          >
            Оформити замовлення
          </Button>

          </>
        }
          <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>
        


  </ModalWindow>

  </>

  const choosePlaceSection =<>


<PayPalScriptProvider options={initialOptions}>

   <ModalWindow title={'Куди доставити'} style={{width:'500px'}}>
  
  Опції:
    <Selector
      id={'delivery'}
      value={info?.delivery}
      placeholder={'Оберіть'}
      onChange={inputHandler}
      data={deliveryOptions}
    />

    {info?.delivery=='toTheAddress' && toTheAdress}

    {info?.delivery=='takeaway' && takeaway}


    
    
    <>
       
    { 
    info?.delivery && info?.paymentType=='online' && page?.enablePayPal && page?.orderSettings?.paymentType?.online
    ? PayPalButton 
    : CreateOrderButton
    }
 
       
        

          </>
        
          <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>


  </ModalWindow>

  </PayPalScriptProvider>

  </>


//   let confirmOrderSection =<>

//       <ModalWindow title={'Оформити замовлення'} >
// <div className={styles.inputs_container}>
//       <InputsContainer>

//       <Input
//         id="name"
//         onChange={inputHandler}
//         value={clientInfo?.name}
//         placeholder="ПІБ"
//       />

//       <Input
//         id="adress"
//         onChange={inputHandler}
//         value={clientInfo?.adress}
//         placeholder="Адреса"
//       />

//       <Input
//         id="phone"
//         onChange={inputHandler}
//         value={clientInfo?.phone}
//         placeholder="Номер телефону"
//       />

//       <Input
//         id="email"
//         onChange={inputHandler}
//         value={clientInfo?.email}
//         placeholder="Ваш емейл"
//       />

//       <Input
//         as='textarea'
//         rows={3}
//         id="message"
//         onChange={inputHandler}
//         value={clientInfo?.message}
//         placeholder="Повідомлення" 
//         />



//       </InputsContainer>
// </div>

//           <Button
//             color="blue"
//             appearance="primary"
//             onClick={createOrder}
//           >
//             Замовити
//           </Button>




//           <Button onClick={closeBasket} color="blue" appearance="ghost">
//             Закрити
//           </Button>




//       </ModalWindow>

//   </>

  let successOrderSection =<>
   
   <ModalWindow title={'Замовлення успішне'} >

<PublicGridContainer>
  <div>Ваш номер замовлення: &nbsp;&nbsp;</div>
  <strong>{orderId}</strong>

</PublicGridContainer>

   <Button onClick={closeBasket} color="blue" appearance="ghost">
            Закрити
          </Button>


   </ModalWindow>




  </>



  if  (display) return <>

  {section=='товари' && itemsSection}
  {/* {section=='оформити замовлення' && confirmOrderSection} */}
  {section=='замовлення успішне' && successOrderSection}
  {section=='опції доставки' && choosePlaceSection}


  </>;
}

export default BasketModal;
