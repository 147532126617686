import { ModalWindow } from "../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./ChangePassword.module.css";
import { useChangePassword } from "./useChangePassword";
import { Input,Button } from "rsuite";


function ChangePassword() {

let{
display,
hideChangePasswordModal,
changePassword,
inputHandler,
password,


}=useChangePassword()

  if(display) return <>
  <ModalWindow title={'Змінити пароль'}>

  <Input
    id="password"
    type="password"
    onChange={inputHandler}
    value={password?.password}
    placeholder="Новий пароль" 
  />

<Input
    id="repeatedPassword"
    type="password"
    onChange={inputHandler}
    value={password?.repeatedPassword}
    placeholder="Повторіть пароль" 
  />


<Button
    onClick={changePassword}  
    color="blue"
    appearance="primary"

    >Змінити пароль</Button>


<Button 
   onClick={hideChangePasswordModal}
   color="blue" 
   appearance="ghost"
>Закрити</Button>
  </ModalWindow>
  </>;
}

export default ChangePassword;
