import {create} from 'zustand'
import { frontDB } from '../../../helpers/frontDB'
import { api } from '../../../helpers/api'
import { showToast } from '../../../Components/Toast/useToast'



export let useEvent = create((set,get)=>({

section:'Про подію',

setSection:(e)=>{set({section:e.target.id})
},

showEventPageEditData:(pageId)=>{


    let page = frontDB.getState().getPageById(pageId)
    set({page})
},

closeWindow:(navigate)=>{

      let {backgroundPhoto,_id}=get().page


    get().clear()
    navigate(-1)
},


page:{

    folderId:'Без папки',

    organizer:'',
    name:'',
    about:'',
    city:'',
    street:'',
    date:Date.now(),
    time:'08:00',

    phone:'',
    email:'',
    website:'https://www.',

},

inputHandler:(v,e)=>{

    set({page:{...get().page,[e.target.id]:v}})

},

dateTimeInputHandler:(e)=>{
    set({page:{...get().page,[e.target.id]:e.target.value}})

},

folderInputHandler:(e)=>{
    set({page:{...get().page,[e.target.id]:e.target.value}})

},

changePhoto:async({type,url})=>{

    if(type=='backgroundPhoto'){set({page:{...get().page,backgroundPhoto:url}})}
    let response = await api.user.updatePage(get().page)
  
},



addPage: async (navigate)=>{

    let {page} = get()
    if(page.name==''){showToast('e','Введіть назву події');return}

    page.type='event'

    let response = await api.user.addPage(page)
    if(response===true){

        get().clear()
        navigate('/pages')
    }


},

updatePage:async(navigate)=>{

    let {page} = get()

        
    let response = await api.user.updatePage(get().page)
    if(response){

        get().clear()
        navigate('/pages')

    }
        
},


clear:()=>{
    set({
        section:'Про подію',

        page:{

            folderId:'Без папки',
        
            organizer:'',
            name:'',
            about:'',
            city:'',
            street:'',
            date:Date.now(),
            time:'08:00',
        
            phone:'',
            email:'',
            website:'https://www.',
        
        }

    })
}
    
}))