import { useEffect } from "react";
import styles from "./ActionP.module.css";
import { useActionP } from "./useActionP";
import QRCode from 'qrcode.react';

function ActionP() {

  let  {page,getPage}=useActionP()


  useEffect(()=>{
    async function af () {
  
    getPage()

    }
    af()
    },[])



let getQR =()=>{

if(page?.actionType=='call') return <QRCode id="qr"  value={`tel:${page?.phone}`}  />
if(page?.actionType=='sms') return <QRCode id="qr"  value={`smsto:${page?.phone}`}  />
if(page?.actionType=='whatsapp') return <QRCode id="qr"  value={`https://wa.me/${page?.phone}?text=`}  />
if(page?.actionType=='email') return <QRCode id="qr"  value={`mailto:${page?.email}`}  />

}

  return <>
  
      {getQR()}
 
  </>;
}

export default ActionP;
