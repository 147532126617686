import { useNavigate, useParams } from "react-router-dom";
import { InputsContainer, PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import Header from "../../../Components/Header/Header";
import styles from "./Wifi.module.css";
import { Input,Checkbox } from "rsuite";
import { useWifi } from "./useWifi";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import Switcher from "../../../Components/Switcher/Switcher";
import { frontDB } from "../../../helpers/frontDB";



function Wifi() {

  let navigate =useNavigate()
  let {mode}=useParams()
  let{user}=frontDB()

  let {
    section,
    setSection,
    page,
    inputHandler,
    folderInputHandler,

    encrtptionInputHandler,
    checkboxHandler,

    addPage,updatePage,closeWindow,

  } =useWifi()


let encryptionTypes =['WEP','WPA','WPA2-EAP','WPA2','WPA3','WPA/WPA2','WPA2-PSK','WPA2-EAP']


let generalSection =<>

    <Input
      id="name"
      onChange={inputHandler}
      value={page?.name}
      placeholder="Назва мережі" 
    />

    <Input
      id="password"
      onChange={inputHandler}
      value={page?.password}
      placeholder="Пароль" 
    />


    <select id='encryption' value={page?.encryption} onChange={encrtptionInputHandler}>
    {encryptionTypes.map(type=>(<option value={type}>{type}</option>))}
    </select>

    <Checkbox checked={page?.hidden} onChange={checkboxHandler} > Прихована мережа</Checkbox>


</>

let othersSection = <>


<select
value={page?.folderId} 
id="folderId"  
placeholder={'Оберіть папку'}
onChange={folderInputHandler}>
{[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>

</>

  return <>
  
  <Header/>

  <PageContainer>

    <PageTitle title ={mode=='add'?'Нова сторінка (Wi-Fi)':'Редагувати сторінку (Wi-Fi)'}/>

    <Switcher
        links={['Основне','Інше']}
        section={section} 
        setSection={setSection}
    />


    <InputsContainer>

      {section == 'Основне' && generalSection}
      {section == 'Інше' && othersSection}


    </InputsContainer>


    <BottomButtons
      mode={mode}
      onCreate={()=>{addPage(navigate)}}
      onUpdate={()=>{updatePage(navigate)}}
      onCancel={()=>{closeWindow(navigate)}}
    />


  </PageContainer>
  
  
  
  
  </>;
}

export default Wifi;
