import {create} from 'zustand'
import { api } from '../../../helpers/api'
import { frontDB } from '../../../helpers/frontDB'
import { useResumeUploader } from './Components/ResumeUploader/useResumeUploader'
import { showToast } from '../../../Components/Toast/useToast'
import shortid from 'shortid'

export let usePersonal = create((set,get)=>({

mode:undefined,

section:'Про мене',


page:{
    active:true,
    name:'',
    phone:'',
    email:'',
    country:'',
    city:'',
    website:'https://www.',
    company:'',
    proffesion:'',
    about:'',
    folderId:'Без папки',
    workExperience:'',
    education:'',
    resume:undefined,
    feedbackType:'Форма',
    feedbacks:[],
    reviews:[],
    gallery:[],
    location:{lat:undefined,lng:undefined},
    appointmentBlock:false,
    appointments:[],
    messengers:undefined,
    buttons:[],
    allowOrders:false,
    orders:[],
    promocodes:[],
    paypalCredentials:{clientId:undefined,secretKey:undefined},  
    enablePayPal:false,


},
 

setSection:async (e)=>{
    
    set({section:e.target.id})

    let{page}=get()

    if(e.target.id=='Зворотній звязок'&& page?._id){
        let updatedPage = await api.user.getPageById('personal',page._id)
        set({page:updatedPage,
            services:page?.services,
        })

    }

    if(e.target.id=='Бронювання'&& page?._id){
        let updatedPage = await api.user.getPageById('personal',page._id)
        set({page:updatedPage,
            services:page?.services,
        })

    }

    if(e.target.id=='Замовлення'&& page?._id){
        let updatedPage = await api.user.getPageById('personal',page._id)
        set({page:{...get().page,orders:updatedPage.orders}})

    }


},

showPersonalPageEditData:(pageId)=>{


    let page = frontDB.getState().getPageById(pageId)
    
    set({
    
        page:page,
        services:page?.services,
        schedule:page?.schedule,
        isAppointment:page?.feedbackType?.category=='appointment'?true:false,
    
    })
},

closeWindow:(navigate)=>{

        
    
    get().clear()
    navigate(-1)
},




inputHandler:(v,e)=>{

    set({page:{...get().page,[e.target.id]:v}})

},

feedbackTypeHandler:(option,action)=>{

    let value =option.value
    let {page} =get()

    set({page:{...page,['feedbackType']:value}})
},

whatsappHandler:(v)=>{
    set({page:{...get().page,messengers:{...get().page.messengers,
    
    whatsapp:v    
    
    }}})
},



folderInputHandler:(e)=>{
    set({page:{...get().page,[e.target.id]:e.target.value}})

},

pageStatusHandler:(v,checked)=>{
    set({page:{...get().page,['active']:checked}})
},

locationHandler:(v,e)=>{
    
  let {page}=get()
  set({page:{...page,location:{...page.location,[e.target.id]:v}}})
  
},

appointmentBlockHandler:(v,c)=>{
    set({page:{...get().page,appointmentBlock:c}});
},



onMessangersChange:(v)=>{
    set({page:{...get().page,messengers:v}})
},

onSocialNetworksChange:(v)=>{
    set({page:{...get().page,socialNetworks:v}})
},

ordersHandler:(v)=>{
   
    set({page:{...get().page,allowOrders:v}})
},
//Послуги

services:[],

serviceStatusHandler:(value,index)=>{
    let {services}=get()
    services[index].active=value
    set({services})

},

serviceInputHandler:(v,e,i)=>{
    let updatedServices = [...get().services];
    updatedServices[i][e.target.id] = v;
    set({ services: updatedServices });
},

currencyHandler:(v,i)=>{
    let {services}=get()
    services[i].currency=v
    set({services})
},

addService:()=>{
    let {currency}=frontDB.getState().user
    set({services:[...get().services,{id:shortid.generate(), name:'',price:'',currency,active:true}]})
},

deleteService:(i)=>{

    let services =get().services
    services.splice(i,1)
    set({services})

},

//Фото
changePhoto:async ({type,url})=>{
    
    if(type=='photo'){set({page:{...get().page,photo:url}})}
    if(type=='backgroundPhoto'){set({page:{...get().page,backgroundPhoto:url}})}


},


//Графік

schedule:{
    monday: {from:'08:00',to:'17:00',weekend:false},
    tuesday: {from:'08:00',to:'17:00',weekend:false},
    wednesday: {from:'08:00',to:'17:00',weekend:false},
    thursday: {from:'08:00',to:'17:00',weekend:false},
    friday: {from:'08:00',to:'17:00',weekend:false},
    saturday: {from:'08:00',to:'17:00',weekend:false},
    sunday: {from:'08:00',to:'17:00',weekend:false},
  },

scheduleInputHandler:(value,day,event)=>{

    let time =value.value
    let type = event.name

    let updatedSchedule = get().schedule;
    updatedSchedule[day][type] = time;
    set({ schedule: updatedSchedule });
},




addPage: async (navigate)=>{

    let {page} = get()

    if(page.feedbackType.type=='Whatsapp' && get().socialNetworks.whatsapp=='380'){
        showToast('e','Вкажіть Ваш номер Whatsapp для зворотнього звязку')
        return
    }


    let resume = await useResumeUploader.getState().uploadFile()
    if(resume){page.resume = resume}

    page.type='personal'
    page.services=get().services
    page.schedule=get().schedule


    
    let response = await api.user.addPage(page)
    if(response===true){

        get().clear()
        navigate('/pages')
    }


},

updatePage:async(navigate)=>{

    let {page} = get()

    let resume = await useResumeUploader.getState().uploadFile()
    if(resume){page.resume = resume}
 

    page.services=get().services
    page.schedule=get().schedule

 
    let response = await api.user.updatePage(page)
    if(response){

        get().clear()
        navigate('/pages')

    }
        
},

onResumeDelete:()=>{
    set({page:{...get().page,['resume']:{fileName:undefined,url:undefined}}})
},



clear:()=>{
 set({
 page:{
    active:true,
    name:'',
    phone:'',
    email:'',
    country:'',
    city:'',
    website:'https://www.',
    company:'',
    proffesion:'',
    about:'',
    folderId:'Без папки',
    workExperience:'',
    education:'',
    resume:undefined,
    feedbackType:'Форма',
    feedbacks:[],
    reviews:[],
    gallery:[],
    location:{lat:undefined,lng:undefined},
    appointments:[],
    appointmentBlock:false,
    messengers:undefined,
    socialNetworks:undefined,
    buttons:[],
    allowOrders:false,
    orders:[],
    promocodes:[],
    paypalCredentials:{clientId:undefined,secretKey:undefined},  
    enablePayPal:false,





},
section:'Про мене',

services:[],
feedbackType:'Повідомлення',
isAppointment:false,
schedule:{
    monday: {from:'08:00',to:'17:00',weekend:false},
    tuesday: {from:'08:00',to:'17:00',weekend:false},
    wednesday: {from:'08:00',to:'17:00',weekend:false},
    thursday: {from:'08:00',to:'17:00',weekend:false},
    friday: {from:'08:00',to:'17:00',weekend:false},
    saturday: {from:'08:00',to:'17:00',weekend:false},
    sunday: {from:'08:00',to:'17:00',weekend:false},
  },

})
}
    
}))