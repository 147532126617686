import {create} from 'zustand'
import { frontDB } from '../../../helpers/frontDB'
import { immer } from 'zustand/middleware/immer'
import { api } from '../../../helpers/api'
import { showToast } from '../../../Components/Toast/useToast'


export let useLinks = create((set,get)=>({

    section:'Основне',

    setSection:(e)=>{set({section:e.target.id})
    },

    showLinksPageEditData:(pageId)=>{

        let page = frontDB.getState().getPageById(pageId)
        set({page})
     
    },

    closeWindow:(navigate)=>{ 

        get().clear()
        navigate(-1)
    },

    page:{
        type:'links',
        name:'',
        photo:'',
        backgroundPhoto:'',
        links:[],

        folderId:'Без папки',

    },

    inputHandler:(v,e)=>{

        set({page:{...get().page,[e.target.id]:v}})
    
    },

    folderInputHandler:(e)=>{
        set({page:{...get().page,[e.target.id]:e.target.value}})
    
    },


    changePhoto:async({type,url})=>{

        if(type=='photo'){set({page:{...get().page,photo:url}})}
        if(type=='backgroundPhoto'){set({page:{...get().page,backgroundPhoto:url}})}
      
    },



    addPage: async (navigate)=>{

        let {page} = get()
        if(page.name==''){showToast('e','Введіть назву сторінки'); return}

        let response = await api.user.addPage(page)
        if(response===true){
    
            get().clear()
            navigate('/pages')
        }
    
    
    },

    updatePage:async(navigate)=>{

        let {page} = get()
    
            
        let response = await api.user.updatePage(page)
        if(response){
    
            get().clear()
            navigate('/pages')
    
        }
            
    },


    clear:()=>{
        set({

            page:{
                type:'links',
                name:'',
                photo:'',
                backgroundPhoto:'Без папки',
                links:[],
        
                folderId:'Без папки',
            },
           
        })
    }



    
}))