import { ModalWindow } from "../../../../Pages/UserPages/Components/PageWindow/PageWindow";
import styles from "./PayPalCredsModal.module.css";
import { usePayPalCredsModal } from "./usePayPalCredsModal";
import { Button,Input } from "rsuite";



function PayPalCredsModal({pageType}) {


  let {
    display,
    closeModal,
    inputHandler,
    updatePayPalCreds,
    credentials,
  } = usePayPalCredsModal();

  if (display)

    return (
      <>
      
        <ModalWindow title={"PayPal Credentials"}>
          
          
          <Input
            id={'clientId'}
            onChange={inputHandler}
            value={credentials?.clientId}
            placeholder="Client ID"
          />

          <Input
            id={'secretKey'}
            onChange={inputHandler}
            value={credentials?.secretKey}
            placeholder="Secret Key"
          />
          
          
          
          
          <Button
            onClick={()=>{updatePayPalCreds(pageType)}}
            color="blue"
            appearance="primary"
          >
            Зберегти
          </Button>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
      </>
    );
}

export default PayPalCredsModal;
