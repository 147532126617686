import shortid from 'shortid'
import {create} from 'zustand'
import { frontDB } from '../../../../helpers/frontDB'
import { api } from '../../../../helpers/api'
import { usePersonal } from '../../../../Pages/PagesTypes/Personal/usePersonal'



export let useFileUploaderModal = create((set,get)=>({

display:undefined,
section:'methods',
urls:[],

setSection:(section)=>{
    set({section})
},

showFlileUploaderModal: ()=>{
set({display:true})
},

closeFlileUploaderModal: ()=>{
    set({display:false})
    get().clear()

},

urls:[],

addURL:()=>{
    set({urls:[...get().urls,{id:shortid.generate(),type:'Photo',url:''}]})
},

fileTypeHandler:(value,urlType,index)=>{
 let {urls}=get()
 urls[index].type=value   
 set({urls})
},

fileUrlHandler:(value,index)=>{
    let {urls}=get()
    urls[index].url=value   
    set({urls})
    console.log(get().urls);
},

deleteURL:(index)=>{

    let {urls}=get()
    urls.splice(index,1)
    set({urls})   
},

deleteFromGallery:(fileId)=>{
    
    let page = usePersonal.getState().page
    let newGallery = page?.gallery.filter(file=>(file.id!=fileId))
    page.gallery=newGallery
    usePersonal.setState({page})

},


saveFiles:async ()=>{
    let {urls}=get()
    let page = usePersonal.getState().page
    page.gallery=[...urls,...page.gallery]
    usePersonal.setState({page})
    get().closeFlileUploaderModal()
},


clear:()=>{
set({
    display:undefined,
    section:'methods',
    urls:[],
    })
}
    
}))