import { Toggle } from "rsuite";
import NotificationSettingsModalModal from "./Components/NotificationSettingsModal/NotificationSettingsModalModal";
import { useNotificationSettingsModalModal } from "./Components/NotificationSettingsModal/useNotificationSettingsModalModal";
import styles from "./UsersNotifications.module.css";
import WarningRoundIcon from "@rsuite/icons/WarningRound";
import { frontDB } from "../../../../helpers/frontDB";

function UsersNotifications() {

  let {user}=frontDB()

let {
  showAddNotificationSettingsModal,
  statusHandler,
  deleteNotificationsSettings,
  showEditNotificationSettingsModal,
}=useNotificationSettingsModalModal()

  return <>

  <div onClick={showAddNotificationSettingsModal} className={'link'}>+ Додати</div>
  
  {user.usersNotificationsSettings && 
  user.usersNotificationsSettings.map(notificationSetting=>

  <NotificationSetting
    active={notificationSetting.active}
    type={notificationSetting.type}
    provider={notificationSetting.provider}
    onEdit={()=>{showEditNotificationSettingsModal(notificationSetting)}}
    onDelete={()=>{deleteNotificationsSettings(notificationSetting.id)}}
    statusHandler={(v)=>{statusHandler(v,notificationSetting.id)}}
  />


    )}
  
  <NotificationSettingsModalModal/>
  </>;
}

export default UsersNotifications;


function NotificationSetting ({type,provider,active,statusHandler,onDelete,onEdit}) {
  return <>
  
  <div className={styles.notificationSetting_container}>

    <div onClick={onEdit}> Тип звязку:{type}</div>
    <div onClick={onEdit}> Провайдер:{provider}</div>
    <div > Статус: &nbsp;
    <Toggle checked={active} onChange={statusHandler} />

    </div>
      <WarningRoundIcon onClick={onDelete}/>

  </div>
  
  </>
}