import { useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import Header from "../../../Components/Header/Header";
import styles from "./AdminUserPages.module.css";
import { useEffect } from "react";
import { useAdminUserPages } from "./useAdminUserPages";

function AdminUserPages() {

  let {userId,userEmail}=useParams()
  let {userPages,getUserPages}=useAdminUserPages()


  useEffect(()=>{

   getUserPages(userId)


  },[])


  return <>
  <Header role='admin'/>
  <PageContainer>
  <PageTitle title={`Сторінки користувача:${userEmail}`}/>


  {userPages.map(page=>(
    <Page
     login={page?.login}
     scans={page?.scans.length}
    />
  ))}



  </PageContainer>

  </>;
}

export default AdminUserPages;



let Page = ({login,scans})=>{

  return <>
  <div className={styles.page_container}>

  <div 
  onClick={()=>{window.location.href=`${process.env.REACT_APP_API}/${login}`}} 
  className={'link'}>

  {process.env.REACT_APP_API}/{login}
  </div>
  <div >К-сть переходів: <strong>{scans}</strong></div>

  </div>
  
  
  </>
}