import ConfirmationModal from "../../../../Components/ConfirmationModal/ConfirmationModal";
import styles from "./DeleteAccountModal.module.css";
import { useDeleteAccountModal } from "./useDeleteAccountModal";




function DeleteAccountModal() {

let {
  display,
  hideDeleteAccountModal,
  deleteAccount,

}=useDeleteAccountModal()



 if(display) return <>

  <ConfirmationModal

  text='Бажаєте видалити аккаунт?'
  onConfirm={deleteAccount}
  onCancel={hideDeleteAccountModal}

  />
  
  </>;
}

export default DeleteAccountModal;



