import { Selector } from "../../../../../../Components/BaseComponents/BaseComponents";
import PayPal from "../../../../../../Components/PayPal/PayPal";
import { planOptions } from "../../../../../../helperFunctions";
import { ModalWindow } from "../../../PageWindow/PageWindow";
import styles from "./BuyPlanModal.module.css";
import { useBuyPlanModal } from "./useBuyPlanModal";
import { Button } from "rsuite";
import { PayPalScriptProvider } from "@paypal/react-paypal-js"

function BuyPlanModal() {
  let { 
    display, 
    closeModal, 
    inputHandler, 
    section,
    months, 
    setSection,
  } =useBuyPlanModal();


const initialOptions = {
  clientId: "AY4pXO_MRz63cwxcEAKvJ_MCSGCSvaIXa3lYqH_FZ3z6UYZdQX_vDvzYaiABnPG0YvAVZj1JRUl5ETNQ",
  currency: "USD",
  intent: "capture",
};
  
  


const choosePlanSection = <>


<PayPalScriptProvider options={initialOptions}>


<ModalWindow title={'Оберіть тариф'}>



          <Selector 
          placeholder={'Оберіть к-сть місяців'}
          value={months}
          data={planOptions} 
          onChange={inputHandler} 
          />



<div>Total:{months ? months*10 :0}$</div>
          {months && <PayPal 
          onError={()=>{setSection('errorPayment')}} 
          onSuccess={()=>{setSection('successPayment')}} 
          months={months}/>}

          {/* <Button
            onClick={pay}
            color="blue"
            appearance="primary"
          >
            Оплатити
          </Button> */}

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>

        </PayPalScriptProvider>
</>

const successPaymentSection = <>

<ModalWindow title={'Оберіть тариф'}>

    <div>Оплата успішна</div>

          <Button onClick={()=>{window.location.reload()}} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>

</>


const errorPaymentSection = <>

<ModalWindow title={'Оберіть тариф'}>

    <div>Помилка оплати</div>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>

</>


if(display) return <>

{section=='choosePlan'     && choosePlanSection}
{section=='successPayment' && successPaymentSection}
{section=='errorPayment'   && errorPaymentSection}

</>


}

export default BuyPlanModal;
