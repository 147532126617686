import styles from "./Folders.module.css";
import MoreIcon from '@rsuite/icons/More'
import { Dropdown } from 'rsuite'
import EditIcon from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { frontDB } from "../../../../helpers/frontDB";
import { useFolders } from "./useFolders";
import { useFolderModal } from "../FolderModal/useFolderModal";
import { useNavigate } from "react-router-dom";

function Folders() {

  let navigate = useNavigate()
 
  let {user}=frontDB()
  let {deleteFolder}=useFolders()
  let {showEditFolderModal}=useFolderModal()



  return <>
  
  <div className={styles.container}>

  {user.folders.map(folder=>(
  <Folder 
  key={folder?.id}
  name={folder?.name}
  onClick={()=>{navigate(`/folder/${folder?.id}`)}}
  onEdit={()=>{showEditFolderModal(folder?.id)}}
  onDelete={()=>{deleteFolder(folder?.id)}}

  />))}

  </div>
  
  </>;
}

export default Folders;




function Folder ({name,onClick,onEdit,onDelete}) {

  return <>
  <div  className={styles.folder_container}>
    <div onClick={onClick} className={styles.folder_name}>{name}</div>


    <Dropdown renderToggle={renderEdit} >

    <Dropdown.Item onClick={onEdit} icon={<EditIcon/>}>Змінити ім'я папки</Dropdown.Item>
    <Dropdown.Item onClick={onDelete} icon={<TrashIcon/>}>Видалити папку</Dropdown.Item>

  </Dropdown>
  </div>
  </>
}


const renderEdit = (props, ref) => {
  return (
    <MoreIcon {...props} ref={ref} style={{fontSize:'24px'}}/>
  );
};

