import PhotoUploader from "../../../../../Components/PhotoUploader/PhotoUploader";
import { ModalWindow } from "../../../../UserPages/Components/PageWindow/PageWindow";
import styles from "./ButtonModal.module.css";
import  {Button,Input,InputPicker} from "rsuite"
import { useButtonModal } from "./useButtonModal";


function ButtonModal() {

let {
  display,
  mode,
  changePhoto,
  inputHandler,
  closeModal,
  addButton,
  updateButton,
  button,

}=useButtonModal()


  if(display){return <>


  <ModalWindow title={mode=='add'?'Нова кнопка' :'Редагувати кнопку'}>

  <div className={styles.photo_container}>
  <PhotoUploader
    type='photo'
    url={button?.photo} 
    onUploaded={(url)=>{changePhoto({type:'photo',url})}}
  />
  </div>


  <Input
        id="name"
        onChange={inputHandler}
        value={button?.name}
        placeholder="Назва кнопки" 
    />

  <Input
        id="url"
        onChange={inputHandler}
        value={button?.url}
        placeholder="URL" 
    />

  <Button  
  color="blue"
  appearance="primary"
  onClick={mode=='add'?addButton:updateButton}
  >Зберегти</Button>

  <Button 
  onClick={closeModal} 
  color="blue" 
  appearance="ghost"
  >Закрити</Button>


  </ModalWindow>
  
  
  
  </>};
}

export default ButtonModal;
