import {create} from 'zustand'
import { api } from '../../../../helpers/api'
import Cookies from 'js-cookie';
import { frontDB } from "../../../../helpers/frontDB";



export let useDeleteAccountModal = create((set,get)=>({

display:false,

showDeleteAccountModal:()=>{set({display:true})},
hideDeleteAccountModal:()=>{set({display:false})},

deleteAccount:async ()=>{
    
    let response = await api.user.deleteAccount()
    if(response===true){exit()}

}

    
}))


let exit=()=>{

    Cookies.remove('uToken', { domain: process.env.REACT_COOKIE_DOMAIN })
    frontDB.getState().deleteDB()
    window.location.href = '/login'
  
  }