import { useNavigate, useParams } from "react-router-dom";
import { InputsContainer, PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import Header from "../../../Components/Header/Header";
import { frontDB } from "../../../helpers/frontDB";
import styles from "./Links.module.css";
import { useLinks } from "./useLinks";
import Switcher from "../../../Components/Switcher/Switcher";
import { Input } from "rsuite";
import PhotoUploader from "../../../Components/PhotoUploader/PhotoUploader";
import { useLinksModal } from "./Components/LinksModal/useLinksModal";
import LinksModal from "./Components/LinksModal/LinksModal";
import { Photo } from "../../../Components/PhotoUploader/PhotoUploader";
import WarningRoundIcon from '@rsuite/icons/WarningRound'



function Links() {

  let{user}=frontDB()

  let navigate =useNavigate()
  let {mode}=useParams()




let {

  addPage,
  updatePage,
  closeWindow,
  section,
  setSection,
  page,
  inputHandler,
  folderInputHandler,
  changePhoto,

}=useLinks()


let {

  showAddLinkModal,
  showEditLinkModal,
  deleteLink,

}=useLinksModal()


let main_section=<>

<Input
  id="name"
  onChange={inputHandler}
  value={page?.name}
  placeholder="Назва " 
/>

<select
  value={page?.folderId} 
  id="folderId"  
  placeholder={'Оберіть папку'}
  onChange={folderInputHandler}>
  {[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>   

</>

let photo_section=<>

<PhotoUploader
  type={'photo'}
  onUploaded={(url)=>{changePhoto({type:'photo',url})}}
  url={page?.photo} 
/>


<PhotoUploader
  type={'backgroundPhoto'}
  onUploaded={(url)=>{changePhoto({type:'backgroundPhoto',url})}}
  url={page?.backgroundPhoto} 
/>


</>

let links_section = (
  <>
    <div onClick={showAddLinkModal} className={"link"}>
      + Додати посилання
    </div>

    {page?.links.map((link) => (
      <Link
        photo={link?.photo}
        name={link?.name}
        description={link?.description}
        url={link?.url}
        onClick={() => showEditLinkModal(link?.id)}
        onDelete={() => {
          deleteLink(link?.id);
        }}
      />
    ))}
  </>
);

  return <>
  
<Header/>
<PageContainer>
<PageTitle title ={mode=='add'?'Нова сторінка (Links)':'Редагувати сторінку (Links)'}/>

<Switcher
  links={['Основне','Фото','Посилання']}
  section={section} 
  setSection={setSection}
/> 


<InputsContainer>

{section=='Основне' && main_section}
{section=='Фото' && photo_section}
{section=='Посилання' && links_section}

</InputsContainer>


<BottomButtons
  mode={mode}
  onCreate={()=>{addPage(navigate)}}
  onUpdate={()=>{updatePage(navigate)}}
  onCancel={()=>{closeWindow(navigate)}}
/>


</PageContainer>
  
  
<LinksModal/>

  </>;
}

export default Links;



//c Допоміжні

export function Link ({photo,name,description,url,onClick,onDelete,deleteIcon}) {

  return <>
  
  <div className={styles.link_container}>
    <Photo url={photo}/>
    <div className={'link'} onClick={onClick}>{name}</div>
    <div>{description}</div>
    <a href={url}>{url}</a>
    <WarningRoundIcon
    style={{opacity:deleteIcon}} 
    onClick={onDelete}
    className={styles.delete_link} 
    color="red"/>

  </div>
  
  </>
}
