import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../Components/Header/Header";
import styles from "./MenuCategory.module.css";
import BottomButtons from "../../../../../Components/BottomButtons/BottomButtons";
import { Input,Toggle } from 'rsuite';
import { useMenuCategory } from "./useMenuCategory";
import { useDishWindow } from "./Components/DishWindow/useDishWindow";
import DishWindow from "./Components/DishWindow/DishWindow";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import { Photo } from "../../../../../Components/PhotoUploader/PhotoUploader";


function MenuCategory() {

  let navigate=useNavigate()

  let {mode}=useParams()

  let {
    
    category,
    dishes,
    inputHandler,
    closeWindow,
    deleteDish,
    addCategory,
    updateCategory,
    dishStatusHandler,
    dishTopHandler,
    
  
  }=useMenuCategory()

  let {showAddDishWindow,showEditDishWindow}=useDishWindow()

  return <>

  <Header/>

  <div className={styles.title}>  
  {mode=='add'?'Нова категорія ':'Редагувати категорію '}
  </div>

  <div className={styles.container}>

    <Input
        id="name"
        onChange={(v,e)=>{inputHandler(v,e)}}
        value={category?.name}
        placeholder="Назва категорії" 
    />
      
      <Input
        as="textarea" rows={3}
        id="about"
        onChange={(v,e)=>{inputHandler(v,e)}}
        value={category?.about}
        placeholder="Опис" 
    />

   <div className={styles.dishes_title}> Страви </div>

{dishes.map(dish=>(
  
  <Dish
    photo={dish?.photo} 
    name={dish.name} 
    price={dish.price}
    currency={dish.currency}
    onEdit={()=>{showEditDishWindow(dish.id)}}
    onDelete={()=>{deleteDish(dish.id)}}
    status={dish?.active}
    statusHandler={(v)=>{dishStatusHandler(dish.id,v)}}
    top={dish?.top}
    topHandler={(v)=>{dishTopHandler(dish.id,v)}}

  />
))}


  <div onClick={showAddDishWindow} className={'link'}> + Додати страву</div>



  </div>


  <BottomButtons
  mode={mode}
  saveButtonName={'Зберегти категорію'}
  onCreate={()=>{addCategory(navigate)}}
  onUpdate={()=>{updateCategory(navigate)}} 
  onCancel={()=>{closeWindow(navigate)}}
  />


  <DishWindow/>

  </>;
}

export default MenuCategory;


//! Допоміжні


function Dish ({photo,name,price,currency,onDelete,onEdit,status,statusHandler,top,topHandler}) {

  return <>
  
  <div className={styles.dish_container}>

<Photo url={photo}/>
<div onClick={onEdit} className={styles.dish_name}>{name}</div>
<div className={styles.price}>{price} {currency}</div>
<span>
        Show: &nbsp;<Toggle size={'sm'} checked={status} onChange={statusHandler} />
        </span>

        <span>
        Top: &nbsp;<Toggle size={'sm'} checked={top} onChange={topHandler} />
        </span>

<WarningRoundIcon onClick={onDelete} style={{color:'red'}}/>

  </div>
  
  </>
}