import {create} from 'zustand'
import { useBusiness } from '../../Pages/PagesTypes/Business/useBusiness'
import { showToast } from '../Toast/useToast'
import { api } from '../../helpers/api'
import { usePersonal } from '../../Pages/PagesTypes/Personal/usePersonal'
import { useRestaurant } from '../../Pages/PagesTypes/Restaurant/useRestaurant'
import { getPageStore } from '../../Pages/PublicPages/helper'



export let usePaymentsSection = create((set,get)=>({

    transactions:[],


    getTransactions:async(pageType,pageId)=>{


        if(!pageType || !pageId) return 


        const transactions = await api.getPageTransactions(pageType,pageId)
        if(transactions.length) set({transactions})
    },

    paypalHandler:(pageType,v)=>{


    const pageStore = getPageStore(pageType)

    console.log(pageStore);
    let page = pageStore.getState().page

    const paypalCredentials=page.paypalCredentials
    if(!paypalCredentials?.clientId || !paypalCredentials?.secretKey) return showToast('e','Налаштуйте PayPal')
    pageStore.setState({page:{...page,enablePayPal:v}})


    }




    
}))