import { Input } from "rsuite";
import styles from "./SocialNetworks.module.css";
import { useState } from "react";


SocialNetworks.defaultProps={
  dafaultValue:{
    facebook : 'https://facebook.com/',
    instagram: 'https://www.instagram.com/',
    youtube  : 'https://www.youtube.com/',
  }
}


function SocialNetworks({dafaultValue,onChange}) {

let [socialNetworks,setSocialNetworks]=useState(dafaultValue)

let inputHandler =(v,e)=>{

  let value =v
  let id=e.target.id

  setSocialNetworks({...socialNetworks,[id]:value})
  onChange({...socialNetworks,[id]:value})

}


  return <>

       <Input
        id="facebook"
        onChange={inputHandler}
        value={socialNetworks?.facebook}
        placeholder="Facebook" 
        />

        <Input
        id="instagram"
        onChange={inputHandler}
        value={socialNetworks?.instagram}
        placeholder="Instagram" 
        />

        <Input
        id="youtube"
        onChange={inputHandler}
        value={socialNetworks?.youtube}
        placeholder="Youtube" 
        />

  
  </>;

}

export default SocialNetworks;
