import styles from "./Header.module.css";
import ExitIcon from '@rsuite/icons/Exit';
import Cookies from 'js-cookie';
import { frontDB } from "../../helpers/frontDB";
import { Link } from 'react-router-dom'
import moment, { fn } from "moment/moment";
import { getDaysToPlanExpire } from "../../helperFunctions";
import { useBuyPlanModal } from "../../Pages/UserPages/Components/TimeToPay/Components/BuyPlan/useBuyPlanModal";
import BuyPlanModal from "../../Pages/UserPages/Components/TimeToPay/Components/BuyPlan/BuyPlanModal";

function Header({role}) {

let {user}=frontDB()
const {showBuyPlan}=useBuyPlanModal()


const userPlan =user?.plan?.type
const planExpiresIn =user?.plan?.expiresIn

const daysToPlanExpire=getDaysToPlanExpire(planExpiresIn)



const updatePlan = <>

{(userPlan =='trial'&& daysToPlanExpire) && <>

<div>Залишилось: {daysToPlanExpire} днів  <span onClick={showBuyPlan} className={styles.updatePlanLink}>Оновити</span></div>


</>}



</>


let exit=()=>{

  Cookies.remove('uToken', { domain: process.env.REACT_COOKIE_DOMAIN })
  frontDB.getState().deleteDB()
  window.location.href = '/login'

} 


if(role=='admin') return <>

<div style={{backgroundColor:'black'}} className={styles.container}>

<div className={styles.title}>Pages</div>

<div className={styles.menu}>


</div>

</div>


</>

if(role=='orderManager') return <>

<div style={{backgroundColor:'orange'}} className={styles.container}>

<div className={styles.title}>Orders</div>

<div className={styles.menu}>

<div style={{color:'#0061A7'}}>{user?.email}</div>
<ExitIcon onClick={exit} style={{fontSize:'20px',fontWeight:'bold'}}/>

</div>

</div>


</>

return <>
  
  <div className={styles.container}>

   <div className={styles.title}>Pages</div>

   <div className={styles.menu}>
    <Link style={{color:'white'}} to='/pages'>Мої сторінки</Link>
    <Link style={{color:'white'}} to='/stats'>Статистика</Link>
    <Link style={{color:'white'}} to='/account'>Акаунт</Link>

    <div style={{color:'#0061A7'}}>{user?.email}</div>

    {updatePlan}

    <ExitIcon onClick={exit} style={{fontSize:'20px',fontWeight:'bold'}}/>


   </div>

  </div>
  <BuyPlanModal/>
  </>;
}








export default Header;
