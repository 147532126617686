import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import Cookies from 'js-cookie'
import { api } from './api'

const serialize = (state) => JSON.stringify(state);
const deserialize = (serializedState) => JSON.parse(serializedState);


//! FrontDB

export let frontDB =create(persist((set,get)=>({

user:{folders:[]},
pages:[],
accesses:[],

updatedTime:undefined,


getPageById:(pageId)=>{

   let pages = get().pages.filter(page=>(page._id==pageId))
   return pages[0]

},

getFolderById:async (folderId)=>{

   let {user} =  get()
   let folders = user.folders.filter(folder=>(folderId==folder.id))
   return folders[0]

},

getAccessById:(accessId)=>{

   let access = get().accesses.filter(access=>(access._id==accessId))
   return access[0]

},


updateDB:async(data)=>{



let start = Date.now()

if(data=='user'){

   let user = await  api.user.getProfile()
   set({user})
   if(user){console.log('Данні користувача оновлені')}

}

if(data=='pages'){

   let pages =await  api.user.getPages()
   set({pages})
   if(pages){console.log('Cторінки користувача оновлені');}

}


if(data=='accesses'){

   let accesses = await  api.user.access.getAllByUserId()
   if(!accesses) return set({accesses:[]})
   set({accesses})
   if(accesses){console.log('Доступи користувача оновлені');}

}


if(data=='*'){

let [user,pages]= await Promise.all([

   api.user.getProfile(),
   api.user.getPages()
   
])

set({pages,user})

}





set({updatedTime:Date.now()})

   let end = Date.now()
   console.log(`Оновлення бази завершено. Час ${end-start} мс`);
return true
},

//Видалення бази
deleteDB:()=>{set({user:{folders:[]},pages:[]})},





}),
{
   name:'frontendDB',
   storage:localStorage,
   serialize,
   deserialize,
}

))