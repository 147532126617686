import {create} from 'zustand'
import { useRestaurant } from '../../useRestaurant'
import shortid from 'shortid'
import { frontDB } from '../../../../../helpers/frontDB'
import { showToast } from '../../../../../Components/Toast/useToast'
import { api } from '../../../../../helpers/api'
import Papa from 'papaparse';



export let useMenuCategory = create((set,get)=>({


    category:{name:'',about:''},
    
    showAddCategoryWindow:(navigate)=>{
        get().clear()
        navigate(`/pages/restaurant/menu/category/add`)
    },

    showCategoryEditWindow:(navigate,categoryId)=>{
    
        
        let {menu}=useRestaurant.getState()
        let category = menu.find(category=>(categoryId==category.id))
        let {id,name,about,dishes}=category

        set({
            category:{...get.category,id,name,about},
            dishes
        })

        navigate(`/pages/restaurant/menu/category/edit`)
    },

    closeWindow:async(navigate)=>{
        
        get().clear()
        navigate(-1)
    },


    inputHandler:(v,e)=>{
        set({category:{...get().category,[e.target.id]:v}})
    },

    

    addCategory:(navigate)=>{
        
        let {category,dishes}=get() 

        if(category.name==''){showToast('e','Введіть назву категорії');return}

        category.id=shortid.generate()  
        category.dishes = dishes 
        let {menu}=useRestaurant.getState()    
        useRestaurant.setState({menu:[...menu,category]})
        get().clear()
        navigate(-1)
    },

    updateCategory:(navigate)=>{

        let {category,dishes}=get()
        let {id,name,about}=category
        let {menu} = useRestaurant.getState()
        let index = menu.findIndex(item=>(category.id==item.id))
        menu[index]={id,name,about,dishes}
        useRestaurant.setState({menu})
        navigate(-1)

    },

    handleFileUpload : (event) => {


        let {menu} = useRestaurant.getState()
    
        const file = event.target.files[0];
    
        Papa.parse(file, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
          let dishes = result.data.map(item=>({...item,id:shortid.generate(),active:true}))
    
         

          for(let i=0;i<dishes.length;i++){

            let checkCSVRestaurant = (item)=>{
                try {

                let {category,name,about,price,photo}=item

                if(!category){
                    throw new Error(`Missing category in row ${i+1}`)
                }
                if(!name){
                    throw new Error(`Missing item name in row ${i+1}`)
                }
                if(!price){
                    throw new Error(`Missing item price in row ${i+1}`)
                }

                }catch(err){
                    showToast('e',err.message)
                    return true
                }
            }

            let errors = checkCSVRestaurant(dishes[i])
            if(errors) return
            
            dishes[i].price = +dishes[i].price

            let categoryName = dishes[i].category
            
            let indexOfCatalog = menu.findIndex(category=>category.name==categoryName)
           
            if(indexOfCatalog == -1){
                menu.push({id:shortid.generate(), name:categoryName,about:'',dishes:[dishes[i]]})
            }else {
                menu[indexOfCatalog].dishes.push(dishes[i])
            }
            
          }
          useRestaurant.setState({menu})

    
          showToast('s','Завантаження даних закінчено')
          },
          error: (error) => {
            console.error('CSV parsing error:', error.message);
            showToast('e','Помилка завантаження')
          },
        });


      
    },

    
    //Страви
    dishes:[],
    
    deleteDish:(dishId)=>{

       let {dishes}=get() 
       let index = dishes.findIndex(dish=>(dishId==dish.id))
       dishes.splice(index,1)
       set({dishes})
    },

    dishStatusHandler:(dishId,value)=>{
        let {dishes}=get()
        let index = dishes.findIndex(dish=>(dishId==dish.id))
        dishes[index].active=value
        set({dishes})

    },

    dishTopHandler:(dishId,value)=>{
        let {dishes}=get()
        let index = dishes.findIndex(dish=>(dishId==dish.id))
        dishes[index].top=value
        set({dishes})

    },

     clear:()=>{set({category:{name:'',about:''},dishes:[]})}
    
}))