import Cookies from "js-cookie"
import jwt_decode from "jwt-decode"
import { api } from "./api"






export let getUserId = () => {
 
    if(Cookies.get('uToken')){

        let payload = jwt_decode(Cookies.get('uToken')) 
        let userId = payload.data.userId

       
        return userId
        
    } else{
        return 'Не авторизований користувач'}


}

export let getUserRole = () => {
 
  if(Cookies.get('uToken')){

      let payload = jwt_decode(Cookies.get('uToken')) 
      let {role} = payload.data

     
      return role
      
  } else{
      return undefined
  }


}

export async function initializeGoogleSignIn (callback) {
   
  if (typeof window.google == 'undefined'){return}
    await window.google.accounts.id.initialize({
      client_id:process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback:handleCallbackResponse
  })

  await window.google.accounts.id.renderButton(
    document.getElementById('signIn'),
    {theme:'outline',size:'large'}
  )
}


let handleCallbackResponse =async(response)=>{
    
  let data = await api.user.googleAuth(response.credential)
  
    }