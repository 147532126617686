import { useNavigate, useParams } from "react-router-dom";
import styles from "./Web.module.css";
import Header from "../../../Components/Header/Header";
import { InputsContainer, PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import { useWeb } from "./useWeb";
import { Input } from "rsuite";
import { frontDB } from "../../../helpers/frontDB";

function Web() {

  let{user}=frontDB()

  let navigate =useNavigate()
  let {mode}=useParams()


let {
  inputHandler,
  page,
  addPage,
  updatePage,
  closeWindow,
  folderInputHandler

}=useWeb()



  return <>
  <Header/>
  <PageContainer>
  <PageTitle title ={mode=='add'?'Нова сторінка (Web page)':'Редагувати сторінку (Web page)'}/>

  <InputsContainer>

  <Input
      id="name"
      onChange={inputHandler}
      value={page?.name}
      placeholder="Назва сторінки" 
    />

    <Input
      id="url"
      onChange={inputHandler}
      value={page?.url}
      placeholder="URL" 
    />

    <select
    value={page?.folderId} 
    id="folderId"  
    placeholder={'Оберіть папку'}
    onChange={folderInputHandler}>
    {[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
    </select>

  </InputsContainer>


   <BottomButtons
      mode={mode}
      onCreate={()=>{addPage(navigate)}}
      onUpdate={()=>{updatePage(navigate)}}
      onCancel={()=>{closeWindow(navigate)}}
    />

  </PageContainer>
  </>;
}

export default Web;
