import {create} from 'zustand'
import { api } from '../../../../../../../helpers/api'
import { useMenuCategory } from '../../useMenuCategory'
import shortid from 'shortid'
import { showToast } from '../../../../../../../Components/Toast/useToast'
import { frontDB } from '../../../../../../../helpers/frontDB'
import Papa from 'papaparse';



export let useDishWindow = create((set,get)=>({

display:false,
mode:undefined,


showAddDishWindow:()=>{
    let {currency}=frontDB.getState().user

    set({dish:{...get().dish,currency}})

    set({display:true,mode:'add'})
},

showEditDishWindow:(dishId)=>{

    set({display:true, mode:'edit'})
    let {dishes} = useMenuCategory.getState()
    let dish = dishes.find(dish=>(dishId==dish.id))
    set({dish})
},

closeWindow:()=>{

    get().clear()
},





dish:{name:'',about:'',price:'',currency:'',active:true,top:false},

inputHandler:(v,e)=>{
    set({dish:{...get().dish,[e.target.id]:v}})
},

statusHandler:(v)=>{
    set({dish:{...get().dish,active:v}})
},

topHandler:(v)=>{
    set({dish:{...get().dish,top:v}})
},

currencyHandler:(v)=>{
    set({dish:{...get().dish,currency:v}})
},


changePhoto:({type,url})=>{
    
    if(type=='photo'){set({dish:{...get().dish,photo:url}})}
    
},




addDish:()=>{
    
    let {dish}=get()

    if(dish.name==''){showToast('e','Введіть назву страви');return}

    dish.id = shortid.generate()

    let dishes = useMenuCategory.getState().dishes
    
    useMenuCategory.setState({dishes:[...dishes,dish]})
    get().closeWindow()
},

updateDish:()=>{
    
    let {dish}=get()

    if(dish.name==''){showToast('e','Введіть назву страви');return}

    let dishes = useMenuCategory.getState().dishes
    let index =dishes.findIndex(item=>(dish.id==item.id))
    dishes[index]=dish
    useMenuCategory.setState({dishes})

    get().closeWindow()


},


clear:()=>{
    let {currency}=frontDB.getState().user

    set({
        display:false,
        mode:undefined,
        dish:{name:'',about:'',price:'',currency,active:true,top:false}
    })
}
    
}))