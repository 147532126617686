import { useNavigate, useParams } from "react-router-dom";
import { InputsContainer, PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import BottomButtons from "../../../Components/BottomButtons/BottomButtons";
import styles from "./Action.module.css";
import { useAction } from "./useAction";
import { frontDB } from "../../../helpers/frontDB";
import Header from "../../../Components/Header/Header";
import { Input,InputPicker } from "rsuite";



function Action() {

let{user}=frontDB()

let navigate =useNavigate()
let {mode}=useParams()





let {

addPage,
updatePage,
closeWindow,
inputHandler,
page,
folderInputHandler,
onActionTypeChange,

}=useAction()




let getActionTypeInput =()=>{

if(page.actionType =='call'|| page.actionType=='sms'|| page.actionType=='whatsapp'){
  return <Input
  id="phone"
  onChange={inputHandler}
  value={page?.phone}
  placeholder="Номер телефону" 
/>
}

if(page.actionType=='email'){

  return <Input

    id="email"
    onChange={inputHandler}
    value={page?.email}
    placeholder="Ваш email" 
  />

}



}


  return <>
<Header/>
<PageContainer>
<PageTitle title ={mode=='add'?'Нова сторінка (Action)':'Редагувати сторінку (Action)'}/>


<InputsContainer>

<Input
  id="name"
  onChange={inputHandler}
  value={page?.name}
  placeholder="Назва " 
/>

<div>Тип дії:</div>
<InputPicker
    value={page?.actionType}
    onChange={onActionTypeChange}
    cleanable={false} 
    data={actionTypes} 
    style={{ display:'block' }} 
  />

 {getActionTypeInput()}



<hr />

<select
  value={page?.folderId} 
  id="folderId"  
  placeholder={'Оберіть папку'}
  onChange={folderInputHandler}>
  {[ <option value={1}>Без папки</option>,...user?.folders?.map(folder => (<option value={folder.id}>{folder.name}</option>))]}
</select>   




</InputsContainer>


<BottomButtons
  mode={mode}
  onCreate={()=>{addPage(navigate)}}
  onUpdate={()=>{updatePage(navigate)}}
  onCancel={()=>{closeWindow(navigate)}}
/>



</PageContainer>
</>;
}

export default Action;



//c Допоміжні


let actionTypes =['call','sms','whatsapp','email']
.map(item => ({ label: item, value: item }));

