import {create} from 'zustand'
import { frontDB } from '../../helpers/frontDB'



export let useFolder = create((set,get)=>({

folderName:undefined,
pagesFromFolder:[],

getCurrentFolderName:(folderId)=>{

    let {folders}=frontDB.getState().user
    let folder =folders.find(folder=>(folderId==folder.id))

    set({folderName:folder?.name})

},

getPagesFromFolder:async(folderId)=>{

    let pages =frontDB.getState().pages
    let pagesFromFolder = pages.filter(page=>(folderId==page.folderId))
    set({pagesFromFolder})

}


    
}))