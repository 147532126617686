import {create} from 'zustand'
import { api } from '../../helpers/api'



export let useOrdersManagement = create((set,get)=>({


section:'Замовлення',

setSection:async (e)=>{
    
    set({section:e.target.id})

},




pagesWithOrdersAndRequests:[],

getPagesWithOrdersAndRequests: async()=>{
    let pagesWithOrdersAndRequests = await api.user.getPagesWithOrdersAndRequests()
   
    set({pagesWithOrdersAndRequests})
}




    
}))