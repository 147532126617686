import {create} from 'zustand'
import { frontDB } from '../../../helpers/frontDB'
import { api } from '../../../helpers/api'
import { showToast } from '../../../Components/Toast/useToast'



export let useWifi = create((set,get)=>({


    section:'Основне',

    setSection:(e)=>{set({section:e.target.id})
    },



    showWifiPageEditData:(pageId)=>{

        let page = frontDB.getState().getPageById(pageId)
        set({page})
    },

    closeWindow:(navigate)=>{
        get().clear()
        navigate(-1)
    },



    page:{

        folderId:'Без папки',
    
        name:'',
        password:'',
        encryption:'WEP',
        hidden:false,
        
    },

    inputHandler:(v,e)=>{

        set({page:{...get().page,[e.target.id]:v}})
    
    },

    folderInputHandler:(e)=>{
        set({page:{...get().page,[e.target.id]:e.target.value}})
    
    },

    encrtptionInputHandler:(e)=>{
        
        set({page:{...get().page,['encryption']:e.target.value}})

    },

    checkboxHandler:(v,checked)=>{
        set({page:{...get().page,['hidden']:checked}})
    },



    addPage: async (navigate)=>{


        let {page} = get()
        if(page.name==''){showToast('e','Введіть назву мережі');return}
    
        page.type='wifi'
    
        let response = await api.user.addPage(page)
        if(response===true){
    
            get().clear()
            navigate('/pages')
        }
    
    
    },

    updatePage:async(navigate)=>{

        let {page} = get()
    
            
        let response = await api.user.updatePage(page)
        if(response){
    
            get().clear()
            navigate('/pages')
    
        }
            
    },



    clear:()=>{
        set({

            page:{

                folderId:'Без папки',
            
                name:'',
                password:'',
                ecryption:'WEP',
                hidden:false,
                
            },
            section:'Основне'




        })
    }
    
}))