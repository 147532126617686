import { useEffect, useState } from "react";
import styles from "./RestaurantP.module.css";
import { api } from "../../../../helpers/api";
import {  ItemContainer, PublicGridContainer, PublicPageContainer, PublicPageHeader, PublicPageMessangers, PublicPageSocialNetworks, PublicPageTitle } from "../../../../Components/BaseComponents/BaseComponents";
import QRCode from 'qrcode.react';
import { Button } from "rsuite";
import { Photo } from "../../../../Components/PhotoUploader/PhotoUploader";
import { isVible } from "../../helper";
import GoogleMaps from "../../../../Components/GoogleMaps/GoogleMaps";
import {  FeedbackSection } from "../PersonalP/PersonalP";
import { useRestaurantP } from "./useRestaurantP";
import { useLocation, useParams } from "react-router-dom";
import { useBasketModal } from "./Components/BasketModal/useBasketModal";
import BasketModal from "./Components/BasketModal/BasketModal";
import AppointmentSection from "../../../../Components/AppointmentSection/AppointmentSection";
import OrdersModal from "./Components/OrdersModal/OrdersModal";
import { useOrdersModal } from "./Components/OrdersModal/useOrdersModal";


function RestaurantP() {
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tableNumber = +searchParams.get('tableNumber') 




  let  {
    page,
    getPage,
    feedbackInputHandler,
    feedback,
    sendFeedback,

    appointment,
    appointmentHandler,
    dateHandler,
    timeHandler,
    createAppointment,
    


  }=useRestaurantP ()

  let {
    basket,
    showBasket,
    addToBasket,
    removeFromBasket,
  }=useBasketModal()


  const {showOrdersModal}=useOrdersModal()

  let  [vcard,setVcard]=useState ()
 
 
  async function getVCard () {
   let response = await api.downloadVCard('restaurant',page?._id)
  }
 
 
 
  useEffect(()=>{
   async function af () {
 
     let data =await getPage()
     let value= await api.getVCard('restaurant',data?._id)
     setVcard(value)
   }
   af()
   },[])
 


  return <>

<PublicPageContainer
  // allowOrders=  {tableNumber ? page?.allowOrders:null} 
  // itemsInBasket={tableNumber ? basket.length:null}
  // onBasketClick={tableNumber ? showBasket:null}
  allowOrders={page?.allowOrders}
  itemsInBasket={basket.length}
  onBasketClick={()=>{showBasket(tableNumber)}}
  showOrders
  onOrdersClick={()=>{showOrdersModal(page._id)}}
>
  <PublicPageHeader>

    <Photo type={'backgroundPhoto' } url={page?.backgroundPhoto}/>
    <Photo  url={page?.photo}/>

    <QRCode value={vcard}/>
    <div className={styles.name}>{page?.name}</div>
        
    <Button  
    color="blue"
    appearance="primary"
    onClick={getVCard}
    >Додати в контакти</Button>

  </PublicPageHeader>

      <PublicPageTitle title ='Контакти'/>

      <PublicGridContainer>

                <div style={isVible(page?.phone)}>Телефон:</div>
                <div style={isVible(page?.phone)} className={styles.value}>{page?.phone}</div>
                
                <div style={isVible(page?.email)} >Email:</div>
                <div style={isVible(page?.email)} className={styles.value}>{page?.email}</div>
               
                <div style={page?.website=='https://www.'?{display:'none'}:{}}>Сайт:</div>
                <a style={page?.website=='https://www.'?{display:'none'}:{}}  className={styles.value} href={page?.website=='https://www.'?'':page?.website}>{page?.website=='https://www.'?'':page?.website}</a>
                
                <div style={isVible(page?.city)}>Місто</div>
                <div style={isVible(page?.city)} className={styles.value}>{page?.city}</div>
               
                <div style={isVible(page?.street)}>Адреса:</div>
                <div style={isVible(page?.street)} className={styles.value}>{page?.street}</div>



            </PublicGridContainer>

      <PublicPageTitle title ='Про заклад'/>
      <div >{page?.about}</div>


     

      <PublicPageMessangers messangers={page?.messengers}/>
      <PublicPageSocialNetworks socialNetworks={page?.socialNetworks}/>


     

      <PublicPageTitle title ='Графік роботи'/>
      <PublicGridContainer>
      {weekdaysUkrainian.map((day, index) => (<>
                  <div key={index}>{day}</div>
                    <div className={styles.value}>
                      {page?.schedule?.[weekdays[index]]?.from}-{page?.schedule?.[weekdays[index]]?.to}
                    </div>
                    </>
                ))}
      </PublicGridContainer>

      <PublicPageTitle title ='Топ страви:'/>

      {page?.menu.map(category=>{

      return <>

      {category.dishes.map(dish=>{
        
      if(dish.top && dish.active) 
      return <>  
      <div className={styles.dish_container}>
      <Photo url ={dish?.photo}/>
      <div className={styles.value}>{dish?.name}</div>
      <div>Вартість:{dish?.price} {dish?.currency}</div>
      <div style={{fontSize:'14px'}}>{dish?.about}</div>
      </div>
      </>

      })}

      </>
      })}

      <PublicPageTitle title ='Меню'/>

      {page?.menu.map(category=>{

      return <>
      <div style={{color:'red'}}>{category.name}</div>
      <div style={{color:'orange'}}>{category.about}</div>

      {category.dishes.map(dish=>{
        
        if(!dish.active) return 
  
      return <>  
      
      <ItemContainer
          photo={dish?.photo}
          name={dish?.name}
          price={dish?.price}
          currency={dish?.currency}
          about={dish?.about}
          allowOrders
          // allowOrders={tableNumber ? page?.allowOrders:null}
          quantity={dish.quantity} 
          onIncrease={()=>{addToBasket(dish.id)}}
          onDecrease={()=>{removeFromBasket(dish.id)}}

        />
      </>


   


      })}

      </>
      })}

<PublicPageTitle title ='WiFi'/>
<div style={isVible(page?.ssid)}>Network:{page?.ssid} Password:{page?.ssidPassword}</div>


        {page?.appointmentBlock && 
          <>
          <PublicPageTitle title='Бронювання'/>
          
          <AppointmentSection 
            pageType='restaurant'
            pageId={page?._id}
            who={page?.name}
 
         />
          </>
          } 

<PublicPageTitle title='Місце розташування'/>

<GoogleMaps lat={+page?.location?.lat} lng={+page?.location?.lng}/>

<PublicPageTitle title='Зворотній звязок'/>

    <FeedbackSection
      page={page}
      feedbackInputHandler={feedbackInputHandler}
      feedback={feedback}
      sendFeedback={sendFeedback}
  />

</PublicPageContainer>
<BasketModal orderSettings={page?.orderSettings}/>
<OrdersModal pageId={page?._id} tableNumber={tableNumber}/>
  </>;
}

export default RestaurantP;


let weekdaysUkrainian = ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'Пятниця', 'Субота', 'Неділя'];

let weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];


//c Допоміжні

