import {create} from 'zustand'
import { OrdersService } from '../../../../../../Services/Orders.services'
import { api } from '../../../../../../helpers/api'
import { showToast } from '../../../../../../Components/Toast/useToast'


export let useOrdersModal = create((set,get)=>({

    display:false,
    clientOrders:[],


    showOrdersModal:(pageId)=>{
        get().getOrders(pageId)
        set({display:true})
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    getOrders:async (pageId)=>{

        const orders = OrdersService.getBusinessOrders()
        if(!orders) return
        
        const clientOrders = await api.getClientOrdersForBusinessPage(pageId,orders) 
       
        if(clientOrders){
            set({clientOrders})
        }
    },



    clear:()=>{
        set({
        })
        

    }
    
}))