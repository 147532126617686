import {create} from 'zustand'
import { calculateOrderTotal } from '../../../../helperFunctions'
import { api } from '../../../../helpers/api'
import { useOrdersManagement } from '../../useOrdersManagement'


export let useOrderDetailsModal = create((set,get)=>({

display:false,
orderId:undefined,
clientInfo:undefined,
basket:[],
total:0,
order:undefined,


showOrderDetails:(oId,page)=>{

    let {orders,promocodes} =page
    let order =orders.find(order=>order.id==oId)
    let {id,clientInfo,basket}=order

    let total =calculateOrderTotal(basket)
    
    if (clientInfo?.promocode){
        let promocode =promocodes.find(promocode=>promocode.name==clientInfo.promocode)
        if(promocode) total = total-total * promocode.discount/100
    }

    set({
        orderId:id,
        clientInfo,
        basket,
        total,
        display:true,
        order,
    })

},

closeModal:()=>{
    set({display:false})
},

statusHandler:async (pageType,pageId,v)=>{


    let {orderId} =get()
    let status =v

    let response = await api.user.changeOrderStatus(orderId,pageType,pageId,status)
    
    if  (response) {
        useOrdersManagement.getState().getPagesWithOrdersAndRequests()
        set({order:{...get().order,status:v}})
    }
},


paidStatusHandler:async(pageType,pageId,v)=>{


    let {orderId} =get()
    let status =v

    let response = await api.user.changeOrderPaymentStatus(orderId,pageType,pageId,status)
    
    if  (response) {
        useOrdersManagement.getState().getPagesWithOrdersAndRequests()
        set({order:{...get().order,paid:v}})
    }



},


clear:()=>{
    set({
        orderId:undefined,
        clientInfo:undefined,
        basket:[],
        order:undefined,

    })
}
    
}))