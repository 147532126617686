import moment from "moment";
import styles from "./Transaction.module.css";

function Transaction({createdAt,status,months,sum}) {
  return <>
  
  <div className={styles.container}>

      <div>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</div>
      <div>{status}</div>
      <div>Months:{months}</div>
      <div>Total:{sum}</div>

  </div>
  
  </>;
}

export default Transaction;
