import {create} from 'zustand'
import { api } from '../../helpers/api'


export let usePublicPages = create((set,get)=>({

pageType:undefined,

getPageType:()=>{
    let login = getLoginFromUrl()
    if(!login){window.location.href='/login'}
    set({pageType:login.substring(0, 3)})
},







    
}))



//! Допоміжні
function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
};