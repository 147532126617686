import { fn } from "moment/moment";
import styles from "./BaseComponents.module.css";
import { isVible } from "../../Pages/PublicPages/helper";
import { Input, InputPicker } from "rsuite";
import { Photo } from "../PhotoUploader/PhotoUploader";
import { useState } from "react";
import TmallIcon from '@rsuite/icons/Tmall'
import{Badge,DatePicker,Button}from 'rsuite'




export const Selector = (
  {
  id,  
  value,
  onChange,
  placeholder,
  data,
  style,
  })=>{



return <>
<InputPicker
      value={value}
      onChange={(v,e)=>{
        e.target.id=id
        onChange(v,e)
      }}
      cleanable={false}
      placeholder={placeholder}
      data={data}
      style={style}
  />

</>
}





export function PageTitle ({title}) {

  return <>
  
  <div className={styles.page_title}>{title}</div>

  </>
}


export function PageContainer ({children}) {

  return <>
  
  <div className={styles.page_container}>
    {children}
  </div>

  </>
}


export function InputsContainer ({children}) {

  return <>
  
  <div className={styles.inputs_container}>
    {children}
  </div>

  </>
}


export function PublicPageContainer ({children,allowOrders,itemsInBasket,onBasketClick,showOrders,onOrdersClick}){


  const orders = <>
  <div className={styles.myorders_button} onClick={onOrdersClick}>Мої замовлення</div>
  </>

let basketIcon = <>
  <div  className={styles.basket_icon_container}>
  {showOrders && orders}
  <Badge onClick={onBasketClick} content={itemsInBasket}>
      <TmallIcon />
  </Badge>
</div>
</>



return <>
       {allowOrders && basketIcon}
       
       
       <div className={styles.public_page_outer_container}>
        <div className={styles.public_page_container}>
        {children}
        </div>
      </div>

</>



}


export function PublicPageHeader ({children}){

  return <>
  
  <div className={styles.public_page_header}>

  {children}       

  </div>
  
  </>
}


export function PublicPageTitle ({title}){

return <>
            <div className={styles.public_page_title}>{title}</div>

         </>
}


export function PublicGridContainer({children}){

return  <div className={styles.public_page_grid_container}>

{children}

</div>



}



export function PublicPageSocialNetworks ({socialNetworks}){

if (!socialNetworks) {
  return 
}


let {facebook,instagram,youtube}=socialNetworks
 
return <>

    <PublicPageTitle title='Соц мережі'/>

    <div 
    style={facebook=='https://facebook.com/'?{display:'none'}:{}}>FaceBook</div>
    <a style={facebook=='https://facebook.com/'?{display:'none'}:{}}  className={styles.value} href={facebook}>{facebook}</a>

    <div style={instagram=='https://www.instagram.com/'?{display:'none'}:{}}>Instagram</div>
    <a style={instagram=='https://www.instagram.com/'?{display:'none'}:{}}  className={styles.value} href={instagram}>{instagram}</a>

    <div style={youtube=='https://www.youtube.com/'?{display:'none'}:{}}>YouTube</div>
    <a style={youtube=='https://www.youtube.com/'?{display:'none'}:{}} className={styles.value} href={youtube}>{youtube}</a>


</>


}

export function PublicPageMessangers ({messangers}){

  if (!messangers) {
    return 
  }
  
  
  let {whatsapp,skype,viber,telegram}=messangers
   
  return <>
  
      <PublicPageTitle title='Месенджери'/>
  
      <div 
      style={whatsapp==''||whatsapp=='+380'?{display:'none'}:{}}>WhatsApp: {whatsapp}</div>
  
      <div style={skype==''?{display:'none'}:{}}>Skype: {skype}</div>
  
      <div style={viber==''||viber=='+380'?{display:'none'}:{}}>Viber: {viber}</div>
  
      <div style={telegram==''?{display:'none'}:{}}>Telegram: {telegram}</div>

  
  </>
  
  
  }

export function SocialNetworksInputs ({socialNetworksInputHandler,socialNetworks}){

return <>
<Input
        id="facebook"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.facebook}
        placeholder="Facebook" 
        />
    
    <Input
        id="whatsapp"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.whatsapp}
        placeholder="WhatsApp" 
        />
    
    <Input
        id="instagram"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.instagram}
        placeholder="Instagram" 
    />
    
    

    <Input
        id="youtube"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.youtube}
        placeholder="YouTube" 
    />



</>


}


export function PriceContainer ({children}) {
  return <>
         <div className={styles.price_container}>
{children}
</div>

  </>
}


export function ItemContainer ({photo,name,price,currency,about,defaultType,quantity,allowOrders,onIncrease,onDecrease,basketView}) {

  return <>
        <div className={styles.item_container}>
        <Photo url ={photo}/>
        <div className={styles.value}>{name}</div>
        <div>Вартість:{price} {currency}</div>
        {basketView &&
        <div>{quantity}</div>
        }
        {allowOrders && 

        <ChangeQuantity
          quantity={quantity}
          defaultType={defaultType}
          defaultQuantity={quantity} 
          onIncrease={onIncrease}
          onDecrease={onDecrease}
        />}


        <div style={{fontSize:'14px'}}>{about}</div>
        </div>

  </>
}

ServiceContainer.defaultProps={
  defaultType:'+'
}

export function ServiceContainer ({allowOrders,defaultType,name,price,currency,onAddService,onRemoveService}) {

 let [type,setType]=useState(defaultType)

function addServiceToBasket () {
  setType('Х')
  onAddService()
}

function removeServiceFromBasket () {
  setType('+')
  onRemoveService()


}

let actionButtons =<>
{type=='+'?
    <span onClick={()=>{addServiceToBasket()}} style={{fontSize:'20px',fontWeight:'bold',color:'var(--primary)',cursor:'pointer'}}>+</span>:
    <span onClick={()=>{removeServiceFromBasket()}} style={{fontSize:'15px',fontWeight:'bold',color:'red',cursor:'pointer'}}>Х</span>
    }
</>

  return <>

  <div className={styles.service_container}>

    <div className={styles.servivce_name}>{name} {price} {currency} </div>

    <div > {price} {currency} </div>


    {allowOrders && actionButtons}

</div>
  
  </>
}





//c Допоміжні



ChangeQuantity.defaultProps={
  defaultType:'add',
  defaultQuantity:0
}

function ChangeQuantity ({defaultType,quantity,onIncrease,onDecrease}) {

let [type,setType]=useState(defaultType)
  

let increase =()=>{
  onIncrease()

}
let decrease =()=>{
  if(quantity==0) return
  onDecrease()
}

 if(type=='add') return <>
  <div  onClick={()=>{setType('quantity');increase()}} style={{cursor:'pointer',fontSize:'18px',color:'var(--primary)'}}>
    +
  </div>
  </>

if(type=='quantity') return <>

<div className={styles.quantity_container}>
  <div onClick={decrease}>-</div>
  <div>{quantity}</div>
  <div onClick={increase}>+</div>
</div>
</>

}