import {create} from 'zustand'
import { usePersonal } from '../../../../Pages/PagesTypes/Personal/usePersonal'
import { useRestaurant } from '../../../../Pages/PagesTypes/Restaurant/useRestaurant'
import { useBusiness } from '../../../../Pages/PagesTypes/Business/useBusiness'
import shortid from 'shortid'
import { showToast } from '../../../Toast/useToast'



export let usePromocodeModal = create((set,get)=>({

    display:false,

    mode:undefined,

    pageStore:undefined,

    setPageStore:(pageType)=>{
        if(pageType=='personal')   set({pageStore:usePersonal})
        if(pageType=='business')   set({pageStore:useBusiness})
        if(pageType=='restaurant') set({pageStore:useRestaurant})

    },


    showAddPromocode:()=>{
        set({mode:'add',display:true})
    },

    showEditPromocode:(promocodeId)=>{
        let {pageStore}=get()

        let {promocodes}=pageStore.getState().page
        let promocode=promocodes.find(promocode=>(promocodeId==promocode.id))
        set({promocode,display:true,mode:'edit'})
    
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    inputHandler:(v,e)=>{
        set({promocode:{...get().promocode,[e.target.id]:v}})
    },

    promocode:{name:'',discount:undefined,status:'active'},

    addPromocode:async()=>{

        let {promocode,closeModal,pageStore}=get()
        let {promocodes}=pageStore.getState().page

        if(!promocode.name){showToast('e','Введіть назву промокоду'); return}
        promocode.id=shortid.generate()
        promocodes=[...promocodes,promocode]

        pageStore.setState(state=>(
            state.page.promocodes=promocodes       
        ))
    
        closeModal()
    


    },

    updatePromocode:async()=>{

        let {promocode,closeModal,pageStore}=get()
        let {promocodes}=pageStore.getState().page
        let promocodeId=promocode.id
    
        if(!promocode.name){showToast('e','Введіть назву промокоду'); return}
    
    
        let promocodeIndex = promocodes.findIndex(promocode=>(promocodeId==promocode.id))
        promocodes[promocodeIndex]=promocode
        pageStore.setState(state=>(
            state.page.promocodes=promocodes       
        ))
    
        closeModal()
    
    },

    deletePromocode:async(promocodeId)=>{
        let {pageStore}=get()
        let {promocodes}=pageStore.getState().page
    
        let buttonIndex = promocodes.findIndex(promocode=>(promocodeId==promocode.id))
        promocodes.splice(buttonIndex,1)
        pageStore.setState(state=>(
            state.page.promocodes=promocodes       
        ))
    
    },

    clear:()=>{
        set({
            mode:undefined,
            promocode:{name:'',discount:undefined,status:'active'},

        })
        

    }
    
}))