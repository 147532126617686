import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Routes,Route } from 'react-router-dom';

import 'normalize.css'
import './assets/global.css'
import 'rsuite/dist/rsuite.min.css';



import Login, { GoogleButton } from './Pages/Login/Login';
import SignUp from './Pages/SignUp/SignUp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import SandBox from './Pages/SandBox/SandBox';
import UserPages from './Pages/UserPages/UserPages';
import Folder from './Pages/Folder/Folder';
import Personal from './Pages/PagesTypes/Personal/Personal';
import Restaurant from './Pages/PagesTypes/Restaurant/Restaurant';
import MenuCategory from './Pages/PagesTypes/Restaurant/Components/MenuCategory/MenuCategory';
import Event from './Pages/PagesTypes/Event/Event';
import Review from './Pages/PagesTypes/Review/Review';
import Wifi from './Pages/PagesTypes/Wifi/Wifi';
import PublicPages from './Pages/PublicPages/PublicPages';
import Error from './Pages/Error/Error';
import Stats from './Pages/Stats/Stats';
import AdminDashboard from './Pages/Admin/AdminDashboard/AdminDashboard';
import AdminUserPages from './Pages/Admin/AdminUserPages/AdminUserPages';
import AccountSettings from './Pages/AccountSettings/AccountSettings';
import DisabledPage from './Pages/DisabledPage/DisabledPage';
import Web from './Pages/PagesTypes/Web/Web';
import Location from './Pages/PagesTypes/Location/Location';
import Links from './Pages/PagesTypes/Links/Links';
import Action from './Pages/PagesTypes/Action/Action';
import PageStats from './Pages/Stats/Pages/PageStats/PageStats';
import Business from './Pages/PagesTypes/Business/Business';
import GoodsCategory from './Pages/PagesTypes/Business/Components/GoodsCategory/GoodsCategory';
import Unavaliable from './Pages/Unavaliable/Unavaliable';
import OrdersManagement from './Pages/OrdersManagement/OrdersManagement';
import { PayPalScriptProvider } from "@paypal/react-paypal-js"



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
 

 
function App(){

    
return <>
<ToastContainer/>


<BrowserRouter>
    <Routes>


    <Route path='/error/:status' element={<Error/>}/>
    <Route path='/login' element={<Login googleButton={<GoogleButton/>}/>}/>
    <Route path='/signup' element={<SignUp googleButton={<GoogleButton/>}/>}/>    
    <Route path='/folder/:folderId' element={<Folder/>}/>
    <Route path='/pages' element={<UserPages/>}/>
    <Route path='/pages/personal/:mode' element={<Personal/>}/>
    <Route path='/pages/restaurant/:mode' element={<Restaurant/>}/>
    <Route path='/pages/restaurant/menu/category/:mode' element={<MenuCategory/>}/>
    <Route path='/pages/event/:mode' element={<Event/>}/>
    <Route path='/pages/review/:mode' element={<Review/>}/>
    <Route path='/pages/web/:mode' element={<Web/>}/>
    <Route path='/pages/location/:mode' element={<Location/>}/>
    <Route path='/pages/links/:mode' element={<Links/>}/>
    <Route path='/pages/action/:mode' element={<Action/>}/>

    <Route path='/pages/business/:mode' element={<Business/>}/>
    <Route path='/pages/business/catalog/category/:mode' element={<GoodsCategory/>}/>


    <Route path='/disabled-page' element={<DisabledPage/>}/>


    
    <Route path='/pages/wifi/:mode' element={<Wifi/>}/>
    <Route path='/stats' element={<Stats/>}/>
    <Route path='/stats/page/:pageId' element={<PageStats/>}/>



    <Route path='/account' element={<AccountSettings/>}/>


    <Route path='/admin/dashboard' element={<AdminDashboard/>}/>
    <Route path='/admin/user/:userId/:userEmail/pages' element={<AdminUserPages/>}/>

    <Route path='/orders-management' element={<OrdersManagement/>}/>    



    <Route path='/unavaliable' element={<Unavaliable/>}/>    


    <Route path='/sandbox' element={<SandBox/>}/>    
    
    <Route path='/*' element={<PublicPages/>}/>



    </Routes>
</BrowserRouter>

</>


}