import {create} from 'zustand'
import { api } from '../../../../helpers/api'
import moment from 'moment'
import { generateCalendarLink,formatDateToCustomFormat } from '../../helper'


export let useEventP = create((set,get)=>({

    page:undefined,

    getPage:async()=>{
    
        let login = getLoginFromUrl()
        
        if(!login){window.location.href='/login'}
        
        let data = await api.getPageByLogin(login)
        if(!data){window.location.href='/login'}
        set({page:data})
        
    },

    share:()=>{

let {page} = get()

const shareButton = document.getElementById('shareButton');

if (navigator.share) {

  shareButton.addEventListener('click', () => {

    // Invoke the share dialog
    navigator.share({
      title: page?.name,
      text:  page?.about,
      url:   `${process.env.REACT_APP_API}/${page.login}`,
    })
      .then(() => console.log('Successfully shared'))
      .catch((error) => console.log('Error sharing:', error));
  });
} else {
  // If the Web Share API is not supported, provide a fallback behavior
  shareButton.addEventListener('click', () => {
    // Fallback behavior (e.g., open a share dialog or show instructions)
    alert('Sharing is not supported on this browser.');
  });
}
    },

    addEventToGoogleCalendar:async()=>{
      let page =get().page  
      let date = formatDateToCustomFormat(page.date,page.time)
      let url = generateCalendarLink(page.name,date,page.about,`${page.city} ${page.street}`)
      
      window.open(url,'_blank')
  },
     



    
}))


function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
};



