import {create} from 'zustand'
import { usePageStats } from '../../usePageStats'
import { api } from '../../../../../../helpers/api'
import { frontDB } from '../../../../../../helpers/frontDB'



export let useScanStats = create((set,get)=>({

display:false,
scan:undefined,
isBlocked:undefined,


showScanStats:async (scanId)=>{
    if(!scanId) return

    let {scans} = await usePageStats.getState().page
    let scan = await scans.find(scan=>scan.id==scanId)
    set({scan,display:true})

    let {blockedUsers}=frontDB.getState().user
    if(blockedUsers.includes(scan.query)){
       set({isBlocked:true})
    }else{
        set({isBlocked:false})
    }
    

},

closeScanStats:()=>{
    set({display:false})
    get().clear()

},




blockUser:async(userIp)=>{
  

let response= await api.blockUser(userIp)
if(response){set({isBlocked:true})}

},

unblockUser:async(userIp)=>{
    let response= await api.unblockUser(userIp)
    if(response){set({isBlocked:false})}
    
},


clear:()=>{
    set(
        {scan:undefined,isBlocked:undefined}
    )
}

    
}))