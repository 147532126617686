import moment from "moment";

export let feedbackOptions = ['Форма','Whatsapp'].map(channel=>({value:channel,label:channel}))

export let currencyOptions =['USD','UAH']
.map(item => ({ label: item, value: item }));

export let planOptions =[1,2,3,4,5,6,7,8,9,10,11,12]
.map(item => ({ label: item, value: item }));

export function calculateOrderTotal(orderItems) {

    let total = 0;
    
    for (const item of orderItems) {
        const quantity = item.quantity;
        const price = parseFloat(item.price);
        
        total += quantity * price;
    }
    


    return total
}

export function calculateTotalByClientOrders(clientOrders){
    if(!clientOrders.length) return 0

    let total=0;

    for(let order of clientOrders){
        const basket = order.basket
        const orderTotal = calculateOrderTotal(basket)
        total+=orderTotal
    }
    return total
};

export function calculateTotalByClientOrdersPersonal(clientOrders){
    if(!clientOrders.length) return 0

    let total=0;

    for(let order of clientOrders){
        const basket = order.basket
        const orderTotal = calculateServiceTotal(basket)
        total+=orderTotal
    }
    return total
};


export function calculateServiceTotal(orderItems) {
 
    let total = 0;
    
    for (const item of orderItems) {
        const price = parseFloat(item.itemInfo.price);
        
        total +=  price;
    }
    
    return total;
}

export function sortReviews(sortSettings,reviews) {



    if (sortSettings.byDate === -1) {
        console.log(-1);
     reviews= reviews.sort((a, b) => b.timeStamp - a.timeStamp); // Sorting in descending order
    
    } else if (sortSettings.byDate === 1) {
        console.log(1);
     reviews= reviews.sort((a, b) => a.timeStamp - b.timeStamp); // Sorting in ascending order
    }
    
    if (sortSettings.byRate === -1) {
     reviews= reviews.sort((a, b) => b.avarageRate - a.avarageRate); // Sorting in descending order
    
    } else if (sortSettings.byRate === 1) {
        reviews= reviews.sort((a, b) => a.avarageRate - b.avarageRate); // Sorting in ascending order
    }
return reviews


}

export function generateRandomPassword(length) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?";
    let password = "";
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
  
    return password;
}

export let arrayMethods = {

    updateWholeElementById(array,itemId,newElement){
        const index = array.findIndex(item=>item.id==itemId)
        array[index]=newElement
        return array
    },


    updateElementPropertyById(array,itemId,property,newValue){
        const index = array.findIndex(item=>item.id==itemId)
        array[index][property]=newValue
        return array
    },


    deleteElementById(array,itemId){
        const index = array.findIndex(item=>item.id==itemId)
        array.splice(index,1)
        return array
    },


}

export function getDaysToPlanExpire (planExpiresIn) {

    if(!planExpiresIn) return undefined
  
  
    const date1 = moment(planExpiresIn);
    const date2 = moment();
    const daysToExpire = date1.diff(date2,'days')
    if(daysToExpire<0) return 0
    return daysToExpire
  }
  