import { useEffect } from "react";
import styles from "./EventP.module.css";
import { useEventP } from "./useEventP";
import { PublicGridContainer, PublicPageContainer, PublicPageHeader, PublicPageTitle } from "../../../../Components/BaseComponents/BaseComponents";
import { Photo } from "../../../../Components/PhotoUploader/PhotoUploader";
import moment from "moment";
import { Button } from "rsuite";
import { useGoogleLogin  } from '@react-oauth/google'
import { isVible } from "../../helper";

function EventP() {

let {

  page,
  getPage,
  share,
  addEventToGoogleCalendar,

}=useEventP()


useEffect(()=>{

getPage()


},[])



const login = useGoogleLogin({
  onSuccess: codeResponse => {
    console.log(codeResponse);
    addEventToGoogleCalendar(codeResponse.code)
  },
  flow: 'auth-code',
})


  return <>
  <PublicPageContainer>
<PublicPageHeader>
<Photo type='backgroundPhoto' url={page?.backgroundPhoto}/>
<strong>{page?.name}</strong>
<div>{page?.about}</div>
<div>Організатор: &nbsp;{page?.organizer}</div>




<Button
    onClick={addEventToGoogleCalendar}  
    color="blue"
    appearance="primary"
    style={{width:'100%'}}

    >Додати в Google календар</Button>

<Button
   id='shareButton'
   onClick={share}
   color="blue" 
   appearance="ghost"
   style={{width:'100%'}}

   >Поділитись</Button>




</PublicPageHeader>







<PublicPageTitle title='Коли:'/>


<PublicGridContainer>
            <div style={isVible(page?.date)}>Дата:</div>
            <div style={isVible(page?.date)} className={styles.value}>{moment(page?.date).format('DD.MM.YYYY')}</div>
            
            <div style={isVible(page?.time)}>Час:</div>
            <div style={isVible(page?.time)} className={styles.value}>{page?.time}</div>

</PublicGridContainer>

<PublicPageTitle title='Контакти:'/>


<PublicGridContainer>
             <div style={isVible(page?.phone)}>Телефон:</div>
            <div style={isVible(page?.phone)} className={styles.value}>{page?.phone}</div>
            
            <div style={isVible(page?.email)}>Email:</div>
            <div style={isVible(page?.email)} className={styles.value}>{page?.email}</div>
            
            <div style={page?.website=='https://www.'?{display:'none'}:{}}>Сайт:</div>
            <a style={page?.website=='https://www.'?{display:'none'}:{}} className={styles.value} href={page?.website=='https://www.'?'':page?.website}>{page?.website=='https://www.'?'':page?.website}</a>

</PublicGridContainer>


  </PublicPageContainer>
  
  </>
}

export default EventP;
