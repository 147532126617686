import {create} from 'zustand'
import { api } from '../../../../helpers/api'
import moment from 'moment'



export let usePersonalP = create((set,get)=>({


    page:undefined,
    currency:'',

    getPage:async()=>{
    
        let login = getLoginFromUrl()
        
        if(!login){window.location.href='/login'}
        
        let data = await api.getPageByLogin(login)
        if(!data.active){window.location.href='/disabled-page'}
        let pageSettings = await api.getPageSettings(data.userId)
        set({page:data,currency:pageSettings.currency})
        
        return data
    
    },
    
    

//Feedback

  feedback:{name:'',phone:'',email:'',message:''},



  feedbackInputHandler:(v,e)=>{
    set({feedback:{...get().feedback,[e.target.id]:v}})
  },


  sendFeedback:async()=>{
     let {page,feedback}=get()

     let response = await api.addFeedbackOnPage('personal',page._id,feedback)
     if(response){get().clear()}
     
  },


clear:()=>{

    set({
      feedback:{name:'',phone:'',email:'', date: Date.now(),time:undefined, message:''},

    })
}

    
}))


function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
};