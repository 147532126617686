import {create} from 'zustand'
import { useBusiness } from '../../../../Pages/PagesTypes/Business/useBusiness'
import { usePersonal } from '../../../../Pages/PagesTypes/Personal/usePersonal';
import { useRestaurant } from '../../../../Pages/PagesTypes/Restaurant/useRestaurant';
import { getPageStore } from '../../../../Pages/PublicPages/helper';



export let usePayPalCredsModal = create((set,get)=>({

    display:false,
    pageType:undefined,



    showEditPayPalCreds:(pageType)=>{

        const pageStore = getPageStore(pageType)


        const credentials = pageStore.getState().page.paypalCredentials


        set({pageType,credentials,mode:'edit',display:true})
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },

    credentials:{clientId:undefined,secretKey:undefined},

    inputHandler:(v,e)=>{
        const key =e.target.id
        set({credentials:{...get().credentials,[key]:v}})
    },



    updatePayPalCreds:async()=>{

        const {pageType,credentials,closeModal}=get()
        
        const pageStore = getPageStore(pageType)

       
            let page = pageStore.getState().page
            pageStore.setState({page:{...page,paypalCredentials:credentials}})
      

        closeModal()
    },


    clear:()=>{
        set({
            credentials:{clientId:undefined,secretKey:undefined},
            pageType:undefined,

        })
        

    }
    
}))