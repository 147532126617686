import {create} from 'zustand'
import { usePersonalP } from '../../usePersonalP'
import { showToast } from '../../../../../../Components/Toast/useToast'
import { api } from '../../../../../../helpers/api'
import { calculateServiceTotal } from '../../../../../../helperFunctions'
import { OrdersService } from '../../../../../../Services/Orders.services'



export let useBasketModal = create((set,get)=>({

    display:false,

    section:'послуги',

    showBasket:()=>{
        set({section:'послуги',display:true})
    },

    setSection:(section)=>{
        set({section})
    },

    closeBasket:()=>{
        
        if(get().section=='замовлення успішне'){
            window.location.reload()
            return
        }
    
        set({display:false})
        get().clear()
    },

    clientInfo:{name:undefined,adress:undefined,phone:undefined,email:undefined,message:undefined,promocode:undefined},

    orderId:undefined,

    inputHandler:(v,e)=>{

        let property = e.target.id  

        set({clientInfo:{...get().clientInfo,[property]:v}})
    },

    total:0,
    basket:[],
    
    addToBasket:(itemInfo)=>{
       
        let itemId=itemInfo.id
        
        let basket = [...get().basket,{itemId,itemInfo}]
        let total=calculateServiceTotal(basket)
        set({basket,total}) 
       

    },

    removeFromBasket:(itemId)=>{

        let {basket,discount}=get()
    
        let serviceIndex = basket.findIndex(item=>(itemId==item.id))
        basket.splice(serviceIndex,1)

        let total=calculateServiceTotal(basket)
        total=total-total*discount/100
        set({basket,total})
    

    },

    
    discount:0,

    disablePromocodeInput:false,
    disableCheckPromocodeButton:false,

    checkPromocode:async()=>{

        let {promocode}=get().clientInfo

        let {page} =usePersonalP.getState()
        let response = await api.checkPromocode('personal',page._id,promocode)
        
        if(response){
            let {total}=get()
            let discount = response

            set({
                total:total-(discount/100*total),
                disablePromocodeInput:true,
                disableCheckPromocodeButton:true,
                discount,
            })
        }

        if(!response){
            set({clientInfo:{...get().clientInfo,promocode:''}})

        }
    },

    paid:false,

    createOrder:async ()=>{

        let {clientInfo,basket,paid}=get()
        let {page}=usePersonalP.getState()

        // if(!clientInfo.name){showToast('e','Введіть ваше імя');return}
        // if(!clientInfo.phone){showToast('e','Введіть ваш номер телефону');return}


        let response = await api.createOrderOnPage('personal',page._id,{clientInfo,basket,paid})
            if(response){
                set({orderId:response})
                get().setSection('замовлення успішне')
                OrdersService.addPersonalOrder(response)

            }

    },



    onPaymentSuccess:async(response)=>{
        const {createOrder}=get()
        set({paid:true})
        createOrder()
        
    },

    onPaymentError:async(response)=>{
        const {closeBasket}=get()
        showToast('e','Помилка оплати')
        closeBasket()
    },




    clear:()=>{
        set({
            section:'послуги',
            order:{name:undefined,adress:undefined,phone:undefined,email:undefined,message:undefined,promocode:undefined},
            orderId:undefined,

            discount:0,
            disablePromocodeInput:false,
            disableCheckPromocodeButton:false,
            paid:false,

        })
    }


    
}))


//c Допоміжні

