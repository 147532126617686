import { useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import { Input,Button} from 'rsuite'
import { useSignUp } from "./useSignUp";
import { useEffect } from "react";
import { initializeGoogleSignIn } from "../../helpers/access";
import { GoogleButton } from "../Login/Login";



function SignUp() {

let navigate =useNavigate()  


let {inputHandler,form,signUp}=useSignUp()







  return <>
  
  <div className={styles.outer_container}>


<div className={styles.container}>

  <div className={styles.header}> Pages</div>

  <Input 
  id={"email"} 
  placeholder="Email"
  onChange={inputHandler}
  value = {form?.email}
  />

  <Input 
  id={"password"} 
  placeholder="Пароль"
  onChange={inputHandler}
  value = {form?.password}

  />


  <Input
  id={"repeatPassword"}  
  placeholder="Повторіть пароль"
  onChange={inputHandler}
  value = {form?.repeatPassword}

  />
  <Button 
  appearance="primary" 
  style={{width:'100%'}}
  onClick ={signUp}
  >Реєстрація</Button>

  <GoogleButton/>


  <div 
  className={styles.link}
  onClick={()=>{navigate('/login')}}
  >Вхід</div>

  
</div>


</div>

  
  
  </>;
}

export default SignUp;
