import { fn } from "moment/moment";
import styles from "./GalleryUploader.module.css";
import FileUploaderModal from "./Components/FileUploaderModal/FileUploaderModal";
import { useFileUploaderModal } from "./Components/FileUploaderModal/useFileUploaderModal";
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import ReactPlayer from 'react-player'


function GalleryUploader({files}) {

let {deleteFromGallery}=useFileUploaderModal()


  return <>
  
  <div className={styles.container}>

  <File type={'add'}/>

  {files.map(file=>(
  <File 
    type={file?.type=='Video'?'Video': 'photoPreview'}
    url={file?.url}
    onDelete={()=>{deleteFromGallery(file?.id)}}
  />
))}
  </div>
  <FileUploaderModal/>

  </>;
}

export default GalleryUploader;


//c Допоміжні
export function File ({type,url,onDelete,place}) {

  let {
    showFlileUploaderModal

  }=useFileUploaderModal()


if(type=='add') return <>

  <div onClick={showFlileUploaderModal} className={styles.photo_container}>
   + Додати
  </div>
 
</>

if(type=='Video')return <>
 <div  className={styles.video_container_preview}>

<ReactPlayer 
width={'300px'}
height={'auto'}
url={url}
/>
<WarningRoundIcon
style={{display:place=='public'?'none':'block'}} 
onClick={onDelete} 
className={styles.delete}/>
</div>

</>

if(type=='photoPreview') return <>

 <div  className={styles.photo_container_preview}>
 <img src={url} width={'150px'}  alt="" />
 <WarningRoundIcon 
 style={{display:place=='public'?'none':'block'}} 
 onClick={onDelete} 
 className={styles.delete}/>
 </div>

</>  


}
