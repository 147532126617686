import { ModalWindow } from "../PageWindow/PageWindow";
import styles from "./QR.module.css";
import { Button } from 'rsuite';
import QRCode from 'qrcode.react';
import {useQR} from './useQR'
import { useEffect, useRef, useState } from "react";
import FileDownloadIcon from '@rsuite/icons/FileDownload';

function QR({login}) {

  let {url,display,hideQR}=useQR()

  const handleDownload = () => {
    const canvas = document.getElementById('qr');
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'qr-code.png';
    link.click();
  };



if(display){
  return <>
  <ModalWindow title ='QR код на сторінку'>

    <div className={styles.container} >
      <QRCode id="qr"  value={url}  />
      
      <div onClick={handleDownload} className={'link'}>
        Download     
        &nbsp;<FileDownloadIcon  style={{color:'var(--primary)',cursor:'pointer'}}/>
      </div>
     
    </div>

    <Button onClick={hideQR} color="blue" appearance="ghost">Закрити</Button>

  </ModalWindow>
  
  
  </>;
  }
}

export default QR;
