import styles from "./ScanStats.module.css";
import { Drawer,Button } from 'rsuite';
import { useScanStats } from "./useScanStats";
import { useEffect } from "react";


function ScanStats() {


let{
  display,
  closeScanStats,
  scan,
  blockUser,
  isBlocked,
  unblockUser,
}=useScanStats()






  return <>
  
  <Drawer open={display} onClose={closeScanStats}>
        <Drawer.Header>
          <Drawer.Title>Статистика сканування: {scan?.query}</Drawer.Title>
           
          
        </Drawer.Header>
        <Drawer.Body>
          
        <div className={styles.stats_container}>
          {scan && Object.entries(scan).map(([key, value]) => (
           <>
              <div className={styles.stats_key}>{key}</div>
              <div className={styles.stats_value}>{value}</div>
            </>
           ))}
        </div>

      <div style={{height:'20px'}}></div>


        <Button
        onClick={()=>{isBlocked? unblockUser(scan.query) :blockUser(scan.query)}}
        color={isBlocked? '':'red'}
        appearance="primary"
        > {isBlocked?'Розблокувати користувача':'Заблокувати користувача'}   
        </Button>

        </Drawer.Body>


        





  </Drawer>
  
  
  </>;
}

export default ScanStats;
