import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import styles from "./Folder.module.css";
import ArowBackIcon from '@rsuite/icons/ArowBack';
import { useFolder } from "./useFolder";
import Page from "../UserPages/Components/Page/Page";
import { usePageManager } from "../../helpers/pageManager";

function Folder() {

let navigate=useNavigate()
let {folderId}=useParams()
let{showEditWindow,deletePage}=usePageManager()

let{
  folderName,
  pagesFromFolder,
  getCurrentFolderName,
  getPagesFromFolder,
  

}=useFolder()


useEffect(()=>{
  getCurrentFolderName(folderId)
  getPagesFromFolder(folderId)
},[])



  return <>
  <Header/>
    <div className={styles.header}>

    <div className={styles.title}>
    <ArowBackIcon onClick={()=>{navigate(-1)}} style={{fontSize:'20px',color:'#25B3F5'}}/>&nbsp;&nbsp;
    {folderName}
   </div>

    </div>
    <div className={styles.pages_container}>
    {pagesFromFolder.map(page=>(<Page 
      key={page?.id}
      onClick={()=>{deletePage(page?.type,page._id)}}
      onEdit={()=>{showEditWindow(page?.type,page._id,navigate)}}
      name={page?.name}
      type={page?.type}
      login={page?.login}

/>
))}

</div>


  </>;
}

export default Folder;
