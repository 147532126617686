import { formatDate } from "../../Pages/PublicPages/helper";
import styles from "./NotificationSection.module.css";

function NotificationSection({notifications}) {

  return <>
  
  {notifications.map(notification=><>
  
  <Notification createdAt={notification?.createdAt} text={notification?.text}/>
  
  </>)}

  </>;
}

export default NotificationSection;



const Notification =({createdAt,text})=>{


  return <>
  <div className={styles.notification_container}>
  <div>{formatDate(createdAt)}</div>
  <div>{text}</div>

  </div>
  
  </>


}
