import {create} from 'zustand'
import { api } from '../../../../helpers/api'

//basket/basketModal

export let useBusinessP = create((set,get)=>({

    page:undefined,

    getPage:async()=>{
        let login = getLoginFromUrl()
        
        if(!login){window.location.href='/login'}
        
        let data = await api.getPageByLogin(login)
        if(!data){window.location.href='/login'}
        set({page:data})

        return data
    
    },

//Feedback

feedback:{name:'',phone:'',email:'',message:''},



feedbackInputHandler:(v,e)=>{
  set({feedback:{...get().feedback,[e.target.id]:v}})
},


sendFeedback:async()=>{
   let {page,feedback}=get()
   let response = await api.addFeedbackOnPage('business',page._id,feedback)
   if(response){get().clear()}
   
},






clear:()=>{

    set({
      feedback:{name:'',phone:'',email:'', date: Date.now(),time:undefined, message:''},
      appointment:{name:'',phone:'', date: Date.now(),time:undefined, message:''},
      basket:[]
    })
}
    
}))

//c Допоміжні
function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
};

