import { useEffect } from "react";
import styles from "./ReviewP.module.css";
import { useReviewP } from "./useReviewP";
import { ModalWindow } from "../../../UserPages/Components/PageWindow/PageWindow";
import { PublicPageHeader } from "../../../../Components/BaseComponents/BaseComponents";
import { Photo } from "../../../../Components/PhotoUploader/PhotoUploader";
import { Button,Input } from 'rsuite';
import { Rating } from 'react-simple-star-rating'


function ReviewP() {

  let {
    page,
    getPage,
    section,
    rateHandler,
    inputHandler,
    review,
    sendReview,
    goBack,
  
  
  }=useReviewP()


  useEffect(()=>{
    getPage()
  },[])


  let mainSection = <>

    <PublicPageHeader>
      <div className={styles.name}>{page?.name}</div>
      <Photo url={page?.photo}/>
      <div>{page?.about}</div>
      <div className={styles.title}>{page?.title}</div>
 



    </PublicPageHeader>
    
    {page?.categories.map(category=>(
    <Category
    onChange={(v)=>{rateHandler(v,category?.id)}}
    id={category?.id} 
    name ={category?.name}
  
  />))}


<Input
        id="author"
        onChange={inputHandler}
        value={review?.author}
        placeholder="Ваше ім'я" 
        />

   <Input
        as="textarea" rows={3}
        id="message"
        onChange={inputHandler}
        value={page?.message}
        placeholder="Ваш відгук" 
        />

<Button
    onClick={sendReview}  
    color="blue"
    appearance="primary"
    style={{width:'100%'}}

    >Надіслати відгук</Button>

  </>

 

  let thankYouSection = <>
   
  <div className={styles.title}>Дякуємо за відгук</div>

   {/* <Button 
   onClick={goBack}
   color="blue" 
   appearance="ghost"
   style={{width:'100%'}}

   >Закрити</Button> */}
   </>


  let leaveGoogleReviewSection = <>

     <div className={styles.title}>Дякуємо за відгук.</div>
     <div className={styles.title}>Залиште відгук в GoogleMaps.Це нам допомагає.</div>


   <Button
    onClick={()=>{window.location.href=page?.googleLink}}  
    color="blue"
    appearance="primary"
    style={{width:'100%'}}

    >Залишити відгук в GoogleMaps</Button>


   <Button 
   onClick={goBack}
   color="blue" 
   appearance="ghost"
   style={{width:'100%'}}

   >Закрити</Button>
  </>




  return <>
  <ModalWindow style={{width:'600px'}}>

  {section=='main'&& mainSection}
  {section=='thankYou'&& thankYouSection}
  {section=='leaveGoogleReview'&& leaveGoogleReviewSection}

  </ModalWindow>
  </>;
}

export default ReviewP;



function Category ({id,name,onClick,onChange}){
  return <>
  
  <div id={id} onClick={onClick} className={styles.category_container}>
    <div className={'link'}><strong>{name}</strong> </div>
    <Rating
        onClick={onChange}    
      />

  </div>
  </>
}
