import styles from "./PageWindow.module.css";
import { Radio, RadioGroup,Button,Input } from 'rsuite';
import { usePageWindow } from "./usePageWindow";
import ImageIcon from '@rsuite/icons/Image'
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import PhotoUploader from "../../../../Components/PhotoUploader/PhotoUploader";
import {shallow} from 'zustand/shallow'




function PageWindow() {

let {
  
  setPageType,
  next,
  display,
  state,
  hide,
  inputHandler,
  page,
  pageCategory,
  services,
  addService,
  serviceInputHandler,
  socialNetworksInputHandler,
  addPage,
  socialNetworks,
  mode,
  updatePage,

  schedule,
  scheduleInputHandler,
  dish,
  dishInputHandler,
  addDish,
  menu,
  showAddDish,
  deleteDish,
  showEditDish,
  dishWindowState,
  updateDish,
  backToMenu,
  deleteService,

}=usePageWindow((state) => (state),(n,p)=>{})



let personalPageButtons =<>
  <Button  
  color="blue"
  appearance="primary"
  onClick={mode=='add'?addPage:updatePage}
  >Зберегти</Button>

  <Button onClick={hide} color="blue" appearance="ghost">Закрити</Button>

</>

let restorantPageButtons =<>
  <Button  
  color="blue"
  appearance="primary"
  onClick={mode=='add'?addPage:updatePage}
  >Зберегти</Button>

  <Button onClick={hide} color="blue" appearance="ghost">Закрити</Button>

</>



  if(!display){return}

  if(state=='serviceType'){

    return <>
    
      <ModalWindow title='Оберіть тип послуги'>

       <RadioGroup name="radioList">
        
        <Radio onClick={()=>{setPageType('Візитка')}}   value="Візитка">Особиста сторінка</Radio>
        <Radio onClick={()=>{setPageType('Ресторан')}} value="Ресторан">Ресторан</Radio>
    
       </RadioGroup>

      <Button onClick={next} color="blue" appearance="primary">Далі</Button>
      <Button onClick={hide} color="blue" appearance="ghost">Закрити</Button>

      </ModalWindow>

    
    
    </>;
  }

  if(state =='Візитка'){

  if(pageCategory=='Про мене'){return <>

    <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
    
    <Switcher/>
    
    <Input
        id="name"
        onChange={inputHandler}
        value={page?.name}
        placeholder="ПІБ" 
        />
    
    <Input
        id="phone"
        onChange={inputHandler}
        value={page?.phone}
        placeholder="Телефон" 
        />
    
    <Input
        id="email"
        onChange={inputHandler}
        value={page?.email}
        placeholder="Email" 
    />
    
    
    <div style ={{display:'flex',columnGap:'20px'}}>
    <Input
        id="country"
        onChange={inputHandler}
        value={page?.country}
        placeholder="Країна" 
    />
    <Input
        id="city"
        onChange={inputHandler}
        value={page?.city}
        placeholder="Місто" 
    />
    </div>
    
    <Input
        id="website"
        onChange={inputHandler}
        value={page?.website}
        placeholder="Веб сайт" 
    />
    
    <Input
        id="company"
        onChange={inputHandler}
        value={page?.company}
        placeholder="Компанія" 
    />
    <Input
        id="proffesion"
        onChange={inputHandler}
        value={page?.proffesion}
        placeholder="Професія" 
    />
    
    <Input
        as="textarea" rows={3}
        id="about"
        onChange={inputHandler}
        value={page?.about}
        placeholder="Опис" 
    />
    
    {personalPageButtons}
    </ModalWindow></>}


  if(pageCategory=='Фото'){return <>

  <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
    
    <Switcher/>
    
    

  <div className={styles.photo_container}>

  <PhotoUploader pageId={page._id}/>

  </div>


  {personalPageButtons}
    
    </ModalWindow></>}


  if(pageCategory=='Соц мережі'){return <>

  <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
    
    <Switcher/>
    
    <Input
        id="facebook"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.facebook}
        placeholder="Facebook" 
        />
    
    <Input
        id="whatsapp"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.whatsapp}
        placeholder="WhatsApp" 
        />
    
    <Input
        id="instagram"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.instagram}
        placeholder="Instagram" 
    />
    
    

    <Input
        id="youtube"
        onChange={socialNetworksInputHandler}
        value={socialNetworks?.youtube}
        placeholder="YouTube" 
    />
    
    {personalPageButtons}
    
    </ModalWindow></>}



  if(pageCategory=='Послуги'){return <>

  <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
    
    <Switcher/>
  {services.map((service,i)=>(
    <Service 
    onChange={(v,e)=>{serviceInputHandler(v,e,i)}} 
    onDelete={()=>{deleteService(i)}}
    name={service?.name} 
    price={service?.price}
  />))} 
  <div className={styles.link} onClick={addService}>+ Додати послугу</div>
  <div style={{height:'50px'}}></div>

  {personalPageButtons}
    
    </ModalWindow></>}

  } 

  if(state=='Ресторан'){

  if(pageCategory=='Про заклад'){

    return <>
    <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
    <Switcher2/>
    
    <Input
        id="name"
        onChange={inputHandler}
        value={page?.name}
        placeholder="Назва закладу" 
        />
    
    <Input
        id="phone"
        onChange={inputHandler}
        value={page?.phone}
        placeholder="Телефон" 
        />
    
    <Input
        id="email"
        onChange={inputHandler}
        value={page?.email}
        placeholder="Email" 
    />

  <Input
        id="website"
        onChange={inputHandler}
        value={page?.website}
        placeholder="Веб сайт" 
    />
  <Input
        id="city"
        onChange={inputHandler}
        value={page?.city}
        placeholder="Місто" 
    />

  <Input
        id="street"
        onChange={inputHandler}
        value={page?.street}
        placeholder="Адреса" 
    />

  <Input
        as="textarea" rows={3}
        id="about"
        onChange={inputHandler}
        value={page?.about}
        placeholder="Опис" 
    />

  {restorantPageButtons}
    </ModalWindow>
    
    </>
  }
  
  if(pageCategory=='Фото'){return <>

    <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
      
      <Switcher2/>
      
      
    
    <div className={styles.photo_container}>

    <PhotoUploader pageId={page._id}/>

    
    </div>
    
    
    {restorantPageButtons}
      
      </ModalWindow></>}

  if(pageCategory=='Соц мережі'){return <>

    <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
      
      <Switcher2/>
      
      <Input
          id="facebook"
          onChange={socialNetworksInputHandler}
          value={socialNetworks?.facebook}
          placeholder="Facebook" 
          />
      
      <Input
          id="whatsapp"
          onChange={socialNetworksInputHandler}
          value={socialNetworks?.whatsapp}
          placeholder="WhatsApp" 
          />
      
      <Input
          id="instagram"
          onChange={socialNetworksInputHandler}
          value={socialNetworks?.instagram}
          placeholder="Instagram" 
      />
      
      
    
      <Input
          id="youtube"
          onChange={socialNetworksInputHandler}
          value={socialNetworks?.youtube}
          placeholder="YouTube" 
      />
      
      {restorantPageButtons}
      
      </ModalWindow></>}

  if(pageCategory=='Розклад'){return <>

    <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
      
      <Switcher2/>
      
    { Object.keys(schedule).map((day) => {
      let { from, to } = schedule[day];

      return <Day onChange={(v,e)=>{scheduleInputHandler(v,e,day)}} day={day} from={from} to={to}/>
    
      })} 
  
    
    
    
    {restorantPageButtons}
      
      </ModalWindow></>}

  if(pageCategory=='Меню'){return <>

    <ModalWindow title={mode=='add'?'Нова сторінка':'Редагувати сторінку'}>
      
      <Switcher2/>
      
      {menu.map((dish,i)=>(<Dish 
      onEdit={()=>{showEditDish(dish.id)}}
      onDelete={()=>{deleteDish(dish.id)}} 
      name={dish?.name}
      about={dish?.name} 
      price={dish?.price}/>))}
      
    
    <div id='Додати страву' className={styles.link} onClick={showAddDish}>+ Додати страву</div>
    
    
      {restorantPageButtons}
      
      </ModalWindow></>}

  if(pageCategory=='Додати страву'){return <>

    <ModalWindow title={dishWindowState=='add'?'Нова страва':'Редагувати страву'}>
      
    <Input
        id="name"
        onChange={(v,e)=>{dishInputHandler(v,e)}}
        value={dish?.name}
        placeholder="Назва страви" 
    />
      
      <Input
        as="textarea" rows={3}
        id="about"
        onChange={(v,e)=>{dishInputHandler(v,e)}}
        value={dish?.about}
        placeholder="Опис" 
    />

  <Input
        id="price"
        onChange={(v,e)=>{dishInputHandler(v,e)}}
        value={dish?.price}
        placeholder="Ціна" 
  />
    
    <Button  
    color="blue"
    appearance="primary"
    onClick={dishWindowState=='add'?addDish:updateDish}
    >Зберегти</Button>

    <Button onClick={backToMenu} color="blue" appearance="ghost">Назад</Button>

    
      
      </ModalWindow></>}



  }


}

export default PageWindow;




export let ModalWindow =({title,style,children})=>{
  return <>
  
  <div className={styles.background} >

   <div  className={styles.container} style ={style}>

   <div className={styles.header}>{title}</div>


    {children}


   </div>

  </div>

  
  
  </>;

}


function Switcher() {

let {pageCategory,setPageCategory}=usePageWindow()  

  let fontStyles = {
    nonActive: {
      fontSize: "14px",
      color: "#979797",
      cursor: "pointer",
    },

    active: {
      fontSize: "14px",
      color: "#25B3F5",
      cursor: "pointer",
    },
  };

  return (
    <>

      <div className={styles.category_selector_container}>
        <span
          id="Про мене"
          onClick={setPageCategory}
          style={fontStyles[pageCategory == "Про мене" ? "active" : "nonActive"]}
        >
         Про мене
        </span>

        <span
          id="Фото"
          onClick={setPageCategory}
          style={
            fontStyles[pageCategory == "Фото" ? "active" : "nonActive"]
          }
        >
          Фото
        </span>

        <span
          id="Соц мережі"
          onClick={setPageCategory}
          style={
            fontStyles[pageCategory == "Соц мережі" ? "active" : "nonActive"]
          }
        >
          Соц мережі
        </span>

        <span
          id="Послуги"
          onClick={setPageCategory}
          style={
            fontStyles[pageCategory == "Послуги" ? "active" : "nonActive"]
          }
        >
          Послуги
        </span>


      </div>
    </>
  );
}

function Switcher2() {

  let {pageCategory,setPageCategory}=usePageWindow()  
  
    let fontStyles = {
      nonActive: {
        fontSize: "14px",
        color: "#979797",
        cursor: "pointer",
      },
  
      active: {
        fontSize: "14px",
        color: "#25B3F5",
        cursor: "pointer",
      },
    };
  
    return (
      <>
  
        <div className={styles.category_selector_container}>
          <span
            id="Про заклад"
            onClick={setPageCategory}
            style={fontStyles[pageCategory == "Про заклад" ? "active" : "nonActive"]}
          >
           Про заклад
          </span>
  
          <span
            id="Фото"
            onClick={setPageCategory}
            style={
              fontStyles[pageCategory == "Фото" ? "active" : "nonActive"]
            }
          >
            Фото
          </span>
  
          <span
            id="Соц мережі"
            onClick={setPageCategory}
            style={
              fontStyles[pageCategory == "Соц мережі" ? "active" : "nonActive"]
            }
          >
            Соц мережі
          </span>
  
          <span
            id="Розклад"
            onClick={setPageCategory}
            style={
              fontStyles[pageCategory == "Розклад" ? "active" : "nonActive"]
            }
          >
            Розклад
          </span>

          <span
            id="Меню"
            onClick={setPageCategory}
            style={
              fontStyles[pageCategory == "Меню" ? "active" : "nonActive"]
            }
          >
            Меню
          </span>
  
  
        </div>
      </>
    );
  }

function Service ({onChange,name,price,onDelete}) {


  return <>
  
  <div  className={styles.serviceContainer}>

  <Input
      id="name"
      onChange={(v,e)=>{onChange(v,e)}}
      value={name}
      placeholder="Назва послуги" 
  />

   <Input
      id="price"
      onChange={(v,e)=>{onChange(v,e)}}
      value={price}
      placeholder="$" 
  />
    <WarningRoundIcon onClick={onDelete} style={{color:'#FF8383'}}/>

  </div>
  </>
}

function Day ({onChange,day,from,to}) {

  let weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  let ukrWeekdays = ["Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота", "Неділя"];
  let i =weekdays.indexOf(day)
  day =ukrWeekdays[i]


  return <>
  
  <div className={styles.day_container}>

  <div>{day}</div>

  <Input
      id="from"
      onChange={(v,e)=>{onChange(v,e)}}
      value={from}
      placeholder="з" 
  />

 <Input
      id="to"
      onChange={(v,e)=>{onChange(v,e)}}
      value={to}
      placeholder="по" 
  />


  </div>
  
  
  </>
}


function Dish ({name,about,price,onDelete,onEdit}) {
  return <>
  <div className={styles.dish_container}>
    <div onClick={onEdit} className={styles.link}>{name}</div>
    <div className={styles.dish_name}>{price}</div>
    <WarningRoundIcon onClick={onDelete} style={{color:'#FF8383'}}/>
  </div>
  </>
}