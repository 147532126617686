import { useEffect } from "react";
import styles from "./PromocodeModal.module.css";
import { usePromocodeModal } from "./usePromocodeModal";
import { Button,Input } from "rsuite";
import { ModalWindow } from "../../../../Pages/UserPages/Components/PageWindow/PageWindow";

function PromocodeModal({pageType}) {
  let {
    display,
    mode,
    closeModal,
    inputHandler,
    addPromocode,
    updatePromocode,
    promocode,
    setPageStore,
    
  } = usePromocodeModal();


  useEffect(()=>{

    if (pageType)setPageStore(pageType);

   
  },[pageType])


  if (display)
    return (
      <>
        <ModalWindow title={mode == "add" ? "Створити промокод" : "Редагувати промокод"}>
          
          <Input
            id={'name'}
            onChange={inputHandler}
            value={promocode.name}
            placeholder="Назва промокоду"
          />

          <Input
            type='number'
            id={'discount'}
            onChange={inputHandler}
            value={promocode.discount}
            placeholder="Розмір знижки"
            />
          
          
          
          <Button
            onClick={mode == "add" ? addPromocode : updatePromocode}
            color="blue"
            appearance="primary"
          >
            Зберегти
          </Button>

          <Button onClick={closeModal} color="blue" appearance="ghost">
            Закрити
          </Button>
        </ModalWindow>
      </>
    );
}

export default PromocodeModal;
