import styles from "./AppointmentSection.module.css";
import { Input,Button,DatePicker } from "rsuite";
import { useAppointmentSection } from "./useAppointmentSection";
import { PublicPageTitle } from "../BaseComponents/BaseComponents";


 

export function AppointmentSection ({pageType,pageId,who}) {

  let {

    appointment,
    appointmentHandler,
    dateHandler,
    timeHandler,
    createAppointment,
    section,
    addToGoogleCalendar,
    setSection,

  }=useAppointmentSection()


let formSection = <>

<Input
  id="name"
  onChange={appointmentHandler}
  value={appointment?.name}
  placeholder="Ім'я" 
  />

<Input
  id="phone"
  onChange={appointmentHandler}
  value={appointment?.phone}
  placeholder="Телефон" 
/>


<DatePicker
  oneTap
  value={appointment?.date} 
  onChange={dateHandler} 
/>

<DatePicker
  
  value={appointment?.time} 
  onChange={timeHandler} 
  format="HH:mm"

/>

<Input
  as='textarea'
  rows={3}
  id="message"
  onChange={appointmentHandler}
  value={appointment?.message}
  placeholder="Повідомлення" 
  />

<Button
  onClick={()=>{createAppointment(pageType,pageId)}}  
  color="blue"
  appearance="primary"

  >Створити запис</Button>


</>

let successSection =<>

<div className={styles.success_section_container}>

<div style={{color:'blue'}}>Запис суспішно створено.</div>
<Button
  onClick={()=>{addToGoogleCalendar(who)}} 
  color="blue"
  appearance="primary"
  >Додати в Google Календар</Button>

<div onClick={()=>{setSection('форма')}} className={'link'}>Створити інший запис</div>
</div>
</>


return <>

<PublicPageTitle title='Бронювання'/>

{section=='форма' && formSection}
{section=='успішний запис' && successSection}

</>

  
  
}

export default AppointmentSection;
