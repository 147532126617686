import {create} from 'zustand'
import { api } from '../../../../../../helpers/api'
import { frontDB } from '../../../../../../helpers/frontDB'
import { generateRandomPassword } from '../../../../../../helperFunctions'
import { showToast } from '../../../../../../Components/Toast/useToast'


export let useAccessModal = create((set,get)=>({

    display:false,

    mode:undefined,


    showAddAccess:()=>{
        set({mode:'add',display:true})
    },

    showEditAccess:(accessId)=>{
        let access = frontDB.getState().getAccessById(accessId)
        set({mode:'edit',access,display:true})
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    access:{name:'',email:'',password:'',role:'',pages:[],active:false},

    inputHandler:(v,e)=>{
        set({access:{...get().access,[e.target.id]:v}})
    },
    
    roleHandler:(v,e)=>{

        set({access:{...get().access,role:v}})

    },
    
    pagesHandler:(v,e)=>{

        let accessRole = get().access.role
        let deniedPages = v.filter(page=> !['business','restaurant'].includes(page.type))
        if(accessRole=="orderManager" && deniedPages.length) return showToast('e','Only business or restaurant page allowed for orders')
        
        set({access:{...get().access,pages:v}})

    },

    generatePassword:()=>{
    let password = generateRandomPassword(6)

    set({access:{...get().access,password}})

    },


    addAccess:async()=>{
            let {access}=get()
            let response = await api.user.access.create(access)

            if(response) get().closeModal()
    },

    updateAccess:async()=>{
        let {access}=get()
        let accessId=access._id
        let response = await api.user.access.updateById(accessId,access)

        if(response) get().closeModal()

    },

    deleteAccess:async(accessId)=>{
        let response = await api.user.access.deleteById(accessId)

        if(response) get().closeModal()

    },


    managerStatusHandler:async (v,accessId)=>{

        let response = await api.user.access.changeOrderManagerStatus(accessId,{avaliableForOrders:v})
        
        if(response) {
            get().closeModal()
        }

    
    },

    changeAccessStatus:async(v,accessId)=>{
        let response = await api.user.access.changeAccessStatus(accessId,{active:v})
        if(response==true) {
            let {access}=get()
            set({access:{...access,active:v}})
        }
    },

    clear:()=>{
        set({
            mode:undefined,
            access:{name:'',email:'',password:'',role:'admin',pages:[],active:false},

        })
        

    }
    
}))