import {create} from 'zustand'
import { usePersonal } from '../../usePersonal'
import { api } from '../../../../../helpers/api'


export let useOrderDetailsModal = create((set,get)=>({

display:false,
orderId:undefined,
clientInfo:undefined,
basket:[],
total:0,
order:undefined,




showOrderDetails:(oId)=>{

    let {orders,promocodes} =usePersonal.getState().page
    let order =orders.find(order=>order.id==oId)
    let {id,clientInfo,basket}=order


    let total = calculateServiceTotal(basket)
    
    if (clientInfo?.promocode){
        let promocode =promocodes.find(promocode=>promocode.name==clientInfo.promocode)
        if(promocode) total = total-total * promocode.discount/100
    }



    set({orderId:id,
        clientInfo,
        basket,
        display:true,
        total,
        order,
    })

},


closeModal:()=>{
    set({display:false})
},


paidStatusHandler:async(pageType,pageId,v)=>{


    let {orderId} =get()
    let status =v

    let response = await api.user.changeOrderPaymentStatus(orderId,pageType,pageId,status)
    
    if  (response) {
        let {page}=usePersonal.getState()

        let updatedPage = await api.user.getPageById('personal',pageId)
        if(!updatedPage?.orders) return

        usePersonal.setState({page:{...page,orders:updatedPage.orders}})

        set({order:{...get().order,paid:v}})
    }



},


clear:()=>{
    set({
        orderId:undefined,
        clientInfo:undefined,
        basket:[],
        order:undefined,

    })
}
    
}))

//c Доп

function calculateServiceTotal(orderItems) {
 
    let total = 0;
    
    for (const item of orderItems) {
        const price = parseFloat(item.itemInfo.price);
        
        total +=  price;
    }
    
    return total;
}