import { LocalStorage } from "./LocalStorage.service";






export const OrdersService ={}


OrdersService.addOrder= (orderId)=>{

    let orders = LocalStorage.getItem('orders')

    if(!orders) {

        LocalStorage.setItem('orders',[orderId])
        return
    } 

    orders.push(orderId)
    LocalStorage.setItem('orders',orders)

}

OrdersService.deleteOrder =(orderId)=>{
 
    let orders = LocalStorage.getItem('orders')
    orders = orders.filter(item=>orderId!=item)
    LocalStorage.setItem('orders',orders)

}

OrdersService.getOrders =()=>{
 
    let orders = LocalStorage.getItem('orders')
    return orders


}


OrdersService.addBusinessOrder= (orderId)=>{

    let orders = LocalStorage.getItem('businessOrders')

    if(!orders) {

        LocalStorage.setItem('businessOrders',[orderId])
        return
    } 

    orders.push(orderId)
    LocalStorage.setItem('businessOrders',orders)

}

OrdersService.getBusinessOrders =()=>{
 
    let orders = LocalStorage.getItem('businessOrders')
    return orders


}


OrdersService.addPersonalOrder= (orderId)=>{

    let orders = LocalStorage.getItem('personalOrders')

    if(!orders) {

        LocalStorage.setItem('personalOrders',[orderId])
        return
    } 

    orders.push(orderId)
    LocalStorage.setItem('personalOrders',orders)

}

OrdersService.getPersonalOrders =()=>{
 
    let orders = LocalStorage.getItem('personalOrders')
    return orders


}
