import    styles from "./StatsBlock.module.css";
import { DateRangePicker } from 'rsuite';
import { useStatsBlock } from "./useStatsBlock";
import { useEffect } from "react";

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths'


function StatsBlock({pageType,orders}) {

let {
  getStats,
  top5items,
  totalOrders,
  income,
  dateRangeHandler,
  period,

}=useStatsBlock()

useEffect(()=>{
  getStats(pageType,orders)
},[period])

  return <>
  <div className={styles.container}>
  

<div className={styles.period_selector}>

<DateRangePicker
cleanable={false}
isoWeek
format={'dd/MM/yyyy'}
value={[period.from,period.to]}
onChange={dateRangeHandler}
ranges={predefinedBottomRanges}
/>
</div>



<div className={styles.numbers_container}>

<StatTable
property='К-сть замовлень'
value={totalOrders}
/>
<StatTable
property='Дохід'
value={income}
/>

</div>

<div className={styles.top_orders}>
<div>Топ 5 :</div>
<div className={styles.orders_container}>


{top5items.map(item=>(
  <Order
  name={item?.name}
  numbers={item?.numberOfOccurrence}
  sold={item?.totalQuantity}
  />
))}
</div>
</div>



  </div>
  
  </>;
}

export default StatsBlock;



function Order ({name,numbers,sold}) {
return <>
  <div className={styles.order_container}>

<div className={styles.order_container_grid_item}>{name}</div>
<div className={styles.order_container_grid_item}>Замовлень:{numbers}</div>
<div className={styles.order_container_grid_item}>Продано:{sold}</div>

</div>


</>
}


function StatTable ({property,value}) {
  return <>
  
  <div className={styles.stat_table_container}>
    <div >{property}</div>
    <div style={{fontSize:'34px'}}>{value}</div>
  </div>
  </>
}

const predefinedBottomRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()]
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)]
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())]
  },
 
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()]
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()]
  },
  {
    label: 'Last 3 months',
    value: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())]
  },
  {
    label: 'Last 6 months',
    value: [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())]
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
  }
];
