import { useEffect, useState } from "react";
import styles from "./BusinessP.module.css";
import { api } from "../../../../helpers/api";
import { ItemContainer, PublicGridContainer, PublicPageContainer, PublicPageHeader, PublicPageMessangers, PublicPageSocialNetworks, PublicPageTitle } from "../../../../Components/BaseComponents/BaseComponents";
import QRCode from 'qrcode.react';
import { Button } from "rsuite";
import { Photo } from "../../../../Components/PhotoUploader/PhotoUploader";
import { isVible } from "../../helper";
import GoogleMaps from "../../../../Components/GoogleMaps/GoogleMaps";
import {  FeedbackSection } from "../PersonalP/PersonalP";
import { useBusinessP } from "./useBusinessP";
import {Helmet} from "react-helmet";
import { UserButton } from "../../../PagesTypes/Personal/Personal";
import BasketModal from "./Components/BasketModal/BasketModal";
import { useBasketModal } from "./Components/BasketModal/useBasketModal";
import AppointmentSection from "../../../../Components/AppointmentSection/AppointmentSection";
import OrdersModal from "./Components/OrdersModal/OrdersModal";
import { useOrdersModal } from "./Components/OrdersModal/useOrdersModal";

function BusinessP() {
 

  


  let  {
    page,
    getPage,
    feedbackInputHandler,
    feedback,
    sendFeedback,



  }=useBusinessP ()





  let {
    showBasket,
    addToBasket,
    removeFromBasket,
    basket,
  }=useBasketModal()


const {showOrdersModal}=useOrdersModal()



  let  [vcard,setVcard]=useState ()
 
 
  async function getVCard () {
   let response = await api.downloadVCard('business',page?._id)
  }
 
 
 
  useEffect(()=>{
   async function af () {
 
     let data =await getPage()
     let value= await api.getVCard('business',data?._id)
     setVcard(value)
   }
   af()
   },[])
 


  return <>

{/* <Helmet>
<script async src={`https://www.googletagmanager.com/gtm.js?id=${page?.analyticsTracking?.googleTagManagerId}`}>

         

{page?.analyticsTracking?.facebookPixelId}
{page?.analyticsTracking?.googleAnalytics}
{page?.analyticsTracking?.googleAnalytics}


</script>
</Helmet> */}




  <PublicPageContainer 
    allowOrders={page?.allowOrders} 
    itemsInBasket={basket.length}
    onBasketClick={showBasket}
    showOrders
    onOrdersClick={()=>{showOrdersModal(page._id)}}
  
  >

  <PublicPageHeader>

    <Photo type={'backgroundPhoto' } url={page?.backgroundPhoto}/>
    <Photo  url={page?.photo}/>

    <QRCode value={vcard}/>
    <div className={styles.name}>{page?.name}</div>
        
    <Button  
    color="blue"
    appearance="primary"
    onClick={getVCard}
    >Додати в контакти</Button>

  </PublicPageHeader>
  
  <PublicPageTitle title='Кнопки'/>
          {page?.buttons.map(button=>(
            <UserButton
            type={'view'}
            photo={button?.photo}
            url={button?.url} 
            name={button?.name}
          />
          
          ))}

      <PublicPageTitle title ='Контакти'/>

      <PublicGridContainer>

                <div style={isVible(page?.phone)}>Телефон:</div>
                <div style={isVible(page?.phone)} className={styles.value}>{page?.phone}</div>
                
                <div style={isVible(page?.email)} >Email:</div>
                <div style={isVible(page?.email)} className={styles.value}>{page?.email}</div>
               
                <div style={page?.website=='https://www.'?{display:'none'}:{}}>Сайт:</div>
                <a style={page?.website=='https://www.'?{display:'none'}:{}}  className={styles.value} href={page?.website=='https://www.'?'':page?.website}>{page?.website=='https://www.'?'':page?.website}</a>
                
                <div style={isVible(page?.city)}>Місто</div>
                <div style={isVible(page?.city)} className={styles.value}>{page?.city}</div>
               
                <div style={isVible(page?.street)}>Адреса:</div>
                <div style={isVible(page?.street)} className={styles.value}>{page?.street}</div>



            </PublicGridContainer>

      <PublicPageTitle title ='Про бізнес'/>
      <div >{page?.about}</div>

    

  

      <PublicPageMessangers messangers={page?.messengers}/>
      <PublicPageSocialNetworks socialNetworks={page?.socialNetworks}/>


      

      <PublicPageTitle title ='Графік роботи'/>
      <PublicGridContainer>
      {weekdaysUkrainian.map((day, index) => (<>
                  <div key={index}>{day}</div>
                    <div className={styles.value}>
                      {page?.schedule?.[weekdays[index]]?.from}-{page?.schedule?.[weekdays[index]]?.to}
                    </div>
                    </>
                ))}
      </PublicGridContainer>

      <PublicPageTitle title ='Топ товарів'/>

      {page?.catalog.map(category=>{

      return <>

      {category.goods.map(good=>{
       
      if(good.top&&good.active) 
      return <>
         <ItemContainer
          photo={good?.photo}
          name={good?.name}
          price={good?.price}
          currency={good?.currency}
          about={good?.about}
        />
      </>

     })}

      </>
      })}

      <PublicPageTitle title ='Каталог'/>

      {page?.catalog.map(category=>{

      return <>
      <div style={{color:'red'}}>{category.name}</div>
      <div style={{color:'orange'}}>{category.about}</div>

      {category.goods.map(good=>{
       
      if(!good.active) return 

      return <>
      <ItemContainer
          photo={good?.photo}
          name={good?.name}
          price={good?.price}
          currency={good?.currency}
          about={good?.about}
          allowOrders={page?.allowOrders}
          quantity={good.quantity} 
          onIncrease={()=>{addToBasket(good.id)}}
          onDecrease={()=>{removeFromBasket(good.id)}}
        />
      </>

     })}

      </>
      })}

<PublicPageTitle title ='WiFi'/>
<div style={isVible(page?.ssid)}>Network:{page?.ssid} Password:{page?.ssidPassword}</div>


        {page?.appointmentBlock && 
          <>
        <AppointmentSection 
           
            pageType='business'
            pageId={page?._id}
            who={page?.name}
         />          </>
          } 

<PublicPageTitle title='Місце розташування'/>

<GoogleMaps lat={+page?.location?.lat} lng={+page?.location?.lng}/>

<PublicPageTitle title='Зворотній звязок'/>

    <FeedbackSection
      page={page}
      feedbackInputHandler={feedbackInputHandler}
      feedback={feedback}
      sendFeedback={sendFeedback}
  />

</PublicPageContainer>
<BasketModal orderSettings={page?.orderSettings}/>

<OrdersModal pageId={page?._id}/>
  </>;
}

export default BusinessP;


let weekdaysUkrainian = ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'Пятниця', 'Субота', 'Неділя'];

let weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
