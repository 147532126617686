import {create} from 'zustand'
import { api } from '../../helpers/api'
import { showToast } from '../../Components/Toast/useToast'



export let useAccountSettings = create((set,get)=>({


section:'Аккаунт',

transactions:[],


getTransactions:async()=>{
    const transactions = await api.user.getTransactions()
    console.log(transactions);
    if(transactions.length) set({transactions})
},


setSection:(e)=>{
    set({section:e.target.id})
},





form:{

name:'',
surname:'',
patronymic:'',


phone:'',
email:'',

language:'',
country:'',
currency:'',
timezone:''    

},

inputHandler:(v,e)=>{
    
    set({form:{...get().form,[e.target.id]:v}})  

},

selectorHandler:(option,action)=>{

    let property = action.name
    let value =option.value
    let form =get().form

    set({form:{...form,[property]:value}})
},

getAccountData:async()=>{

    let profile = await api.user.getProfile()

    if(profile._id){
        set({form:profile})
    }

},

updateProfile:async()=>{

    let user =get().form    
    let response = await api.user.updateProfile(user)
    if(response){showToast('s','Данні оновлено')}
},


    
}))