import {create} from 'zustand'
import { useRestaurant } from '../../useRestaurant'
import shortid from 'shortid'
import { showToast } from '../../../../../Components/Toast/useToast'



export let useTableModal = create((set,get)=>({

    display:false,

    mode:undefined,

    section:undefined,


    showAddTable:()=>{
        set({section:'оберіть функцію',mode:'add',display:true})
    },

    showEditTable:(tableId)=>{
        let {tables}=useRestaurant.getState().page
        let table=tables.find(table=>(tableId==table.id))
    
        set({table,mode:'edit',display:true})
    },

    closeModal:()=>{
        set({display:false})
        get().clear()
    },


    functionHandler:(v)=>{
        if(v=='один стіл'){set({section:'один стіл'});return}
        if(v=='декілька столів'){set({section:'декілька столів'});return}
    },



    table:{number:undefined,status:'avaliable',responsible:'undefined'},

    inputHandler:(v,e)=>{
        set({table:{...get().table,number:+v}})
    },



    addTable:async()=>{

        let {table,closeModal}=get()
        let {page}=useRestaurant.getState()

        if(!table.number){showToast('e','Введіть номер столика'); return}


        table.id=shortid.generate()
        page.tables = [...page.tables,table]
        useRestaurant.setState({page})

        closeModal()

    },

    updateTable:async ()=>{

        let {table,closeModal}=get()
        let {tables}=useRestaurant.getState().page
        let tableId=table.id
    
        if(!table.number){showToast('e','Введіть номер столика'); return}
    
    
        let tableIndex = tables.findIndex(table=>(tableId==table.id))
        tables[tableIndex]=table
        useRestaurant.setState(state=>(
            state.page.tables=tables       
        ))
    
        closeModal()
    
    },

    deleteTable:async(tableId)=>{
        
        let {tables}=useRestaurant.getState().page
    
        let tableIndex = tables.findIndex(table=>(tableId==table.id))
        tables.splice(tableIndex,1)
        useRestaurant.setState(state=>(
            state.page.tables=tables       
        ))
    
    },


    tableNumbers:undefined,

    tableNumbersHandler:(v)=>{
        set({tableNumbers:+v})
    },
    
    addManyTables:()=>{


        let {tableNumbers,closeModal}=get()
        let {page}=useRestaurant.getState()

        let existingTableNumbers = page.tables.map(table=>table.number)

        for(let i=1;i<=tableNumbers;i++){

            if(existingTableNumbers.includes(i)) continue

            page.tables.push({id:shortid.generate(),number:i,status:'avaliable',responsible:'undefined'})
        }

        page.tables.sort((a,b)=>a.number-b.number)
        useRestaurant.setState({page})
        closeModal()

    },



    clear:()=>{
        set({
            mode:undefined,
            table:{number:undefined,status:'avaliable',responsible:'undefined'},
            section:undefined,
            tableNumbers:undefined,
        
        })

    },
    
}))