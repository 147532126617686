import {create} from 'zustand'
import {api} from '../../../../helpers/api'
import moment from 'moment'

export let useReviewP = create((set,get)=>({

    page:undefined,

    getPage:async()=>{
    
        let login = getLoginFromUrl()
        
        if(!login){window.location.href='/login'}
        
        let data = await api.getPageByLogin(login)
        if(!data){window.location.href='/login'}
        set({page:data})

        let categories = data.categories.map(category=>({categoryId:category.id,categoryName:category.name,rate:undefined}))
        set({review:{...get().review,categories:[...categories]}})
        
    },

    section:'main',
    category:undefined,
    
    setSection:(section,categoryId)=>{
        
        
        if(section=='estimate'){

            let categories = get().page.categories
            let category = categories.find(category=>(categoryId==category.id))
            set({category})
        }
        set({section})

    },

    goBack:()=>{set({
        section:'main',
        // review:{rate:0, author:'',message:''},

    })},
 

    

   //Відгук 
   review:{categories:[], author:'',message:'',avarageRate:undefined},

   inputHandler:(v,e)=>{
   
    set({review:{...get().review,[e.target.id]:v}})
   },
    
    //Оцінка
 
    rateHandler:(rate,categoryId)=>{

        let {categories} =get().review


        let categoryIndex = categories.findIndex(category=>categoryId==category.categoryId)
        categories[categoryIndex].rate=rate

        let avarageRate =categories.reduce((accumulator,currentItem)=>
        
           {
            
            if(currentItem.rate) {
                return accumulator+currentItem.rate
            }else {
                return accumulator
            }
           
        },0) / categories.length

        avarageRate=avarageRate.toFixed(2)
        set({review:{...get().review,categories,avarageRate}})

    },



    sendReview:async()=>{

       

        let {page,review}=get()
       
        let response = await api.sendReview(page._id,review)

        if(response){
            if(review.avarageRate>=4){set({section:'leaveGoogleReview'})}
            if(review.avarageRate<4){set({section:'thankYou'})} 
        }
       
    },


    clear:()=>{
        set({
            category:undefined,
            review:{rate:0, author:'',message:''},
            rate:0,


        })
    }

    
}))


function getLoginFromUrl(){
    let pathname = new URL(window.location.href).pathname;
    let parts = pathname.split("/");
    let login = parts[1];
    return login
};