import { useEffect } from "react";
import { PageContainer, PageTitle } from "../../../Components/BaseComponents/BaseComponents";
import Header from "../../../Components/Header/Header";
import styles from "./AdminDashboard.module.css";
import { useAdminDashboard } from "./useAdminDashboard";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  let{accounts,getAccounts,statusHandler}=useAdminDashboard()

  useEffect(()=>{


    getAccounts()
    
    
    },[])

  return <>
    <Header role='admin'/>

    <PageContainer>
      <PageTitle title='Зареєстровані користувачі'/>

      {accounts.map(account=>(
      <Account
        active={account.active}
        statusHandler={()=>{statusHandler(account?._id,account?.active)}}
        key={account?._id}
        userId={account?._id}
        email={account?.email}
        created={moment(account?.createdAt).format('DD-MM-YYYY HH:mm')}
        totalPages={account?.totalPages}
      />
      
      ))}


    </PageContainer>
  
  </>;
}

export default AdminDashboard;


//! Допоміжні


let Account =({userId,email,created,totalPages,active,statusHandler})=>{

let navigate=useNavigate()

let statusButtons = active ?  
<div onClick={statusHandler} style={{color:'red',cursor:'pointer'}}>Заблокувати</div>
:
<div onClick={statusHandler}  style={{color:'var(--primary)',cursor:'pointer'}}>Розблокувати</div>

  return <>
  
  <div className={styles.user_container}>
        
        <div onClick={()=>{navigate(`/admin/user/${userId}/${email}/pages`)}} className="link">{email}</div>
        <div>{created}</div>
        <div>К-сть сторінок: <strong>{totalPages}</strong></div>
        {statusButtons}
      </div>
  
  </>
}
