import moment from "moment"
import { useBusiness } from "../PagesTypes/Business/useBusiness"
import { usePersonal } from "../PagesTypes/Personal/usePersonal"
import { useRestaurant } from "../PagesTypes/Restaurant/useRestaurant"

export let isVible=(data)=>{
    if(!data){return {display: 'none'}}
    }

export  function generateCalendarLink(title,startDate,about,where) {

return `http://www.google.com/calendar/render?
action=TEMPLATE
&text=${title}
&dates=${`${startDate}/${startDate}`}
&details=${about}
&location=${where}
`



}
        
        
        
export  function formatDateToCustomFormat(date,time) {
    date = moment(date).format(`YYYY-MM-DD ${time}`)
    return moment(date).format('YYYYMMDDTHHmmss');
}


export function calculateAvarageRateByCategories(categoryRates) {


    let avarageRatesByCategories = [];

    while (categoryRates.length > 0) {
      let categoryId = categoryRates[0].categoryId;
      let allRatesById = categoryRates.filter(categoryRate => categoryRate.categoryId == categoryId);
    
      let totalRate = allRatesById.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem.rate || 0);
      }, 0);
    
      let averageRate = (totalRate / allRatesById.length).toFixed(2);
    
      if(averageRate=='0.00') averageRate='Не оцінено'
      let categoryName = allRatesById[0].categoryName;
      let avarageRateByCategory = { categoryName, averageRate };
      avarageRatesByCategories.push(avarageRateByCategory);
    
      categoryRates = categoryRates.filter(categoryRate => categoryRate.categoryId != categoryId);
    }
    
    avarageRatesByCategories=avarageRatesByCategories.sort((a,b)=>a.averageRate-b.averageRate)
    

return avarageRatesByCategories

}

export const orderStatuses =['new', 'processing', 'completed','canceled'].map(
  item => ({ label: item, value: item })
);


export const timeOptionsForDelivery =['Зараз','На певний час'].map(
  item => ({ label: item, value: item })
);

export const  formatDate=(date)=>{
  return moment(date).format('DD.MM.YYYY HH:mm')
}


export const  getPageStore=(pageType)=>{

  switch (pageType) {
    case "business":
      return useBusiness;

    case "personal":

      return usePersonal;

    case "restaurant":
      return useRestaurant;

    default:
      console.log("No case found");
  }
}