import { useParams } from "react-router-dom";
import styles from "./Error.module.css";

function Error() {
  let {status}=useParams()
  return <>
  Помилка: {status}
  </>;
}

export default Error;
