import { useEffect } from "react";
import styles from "./PublicPages.module.css";
import { usePublicPages } from "./usePublicPages";
import PersonalP from "./Components/PersonalP/PersonalP";
import RestaurantP from "./Components/RestaurantP/RestaurantP";
import WifiP from "./Components/WifiP/WifiP";
import ReviewP from "./Components/ReviewP/ReviewP";
import EventP from "./Components/EventP/EventP";
import { GoogleOAuthProvider } from '@react-oauth/google'
import WebP from "./Components/WebP/WebP";
import LocationP from "./Components/LocationP/LocationP";
import LinksP from "./Components/LinksP/LinksP";
import ActionP from "./Components/ActionP/ActionP";
import BusinessP from "./Components/BusinessP/BusinessP";

function PublicPages() {

let {getPageType,pageType}=usePublicPages()


useEffect(()=>{

  getPageType()

},[])


if(pageType=='per') return <PersonalP/>

if(pageType=='res') return <RestaurantP/>

if(pageType=='wif') return <WifiP/>

if(pageType=='rev') return <ReviewP/>

if(pageType=='eve') return <> 
<GoogleOAuthProvider clientId="291285647171-mlssi2r6vjaanko78k1evdt5q5lpvkef.apps.googleusercontent.com"><EventP/></GoogleOAuthProvider>  </>  

if(pageType=='web') return <WebP/>

if(pageType=='loc') return <LocationP/>

if(pageType=='lin') return <LinksP/>

if(pageType=='act') return <ActionP/>

if(pageType=='bus') return <BusinessP/>

}


export default PublicPages;
